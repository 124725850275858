import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";

function SectionRecordsFilter({
  _config,
  rowPerPage,
  handleRecordsPerPageChange,
  setTableFilter,
  tableFilter,
  setCurrentPage,
  handleSearchClick,
  handleSearchKeyFunction,
  setPaymentCurrentPage,
  setSearchKey,
  searchKey
}) {
  const {  canActiveTab, role_type } = useSelector((state) => state.menu);

  const location = useLocation();
  const currentPathName = location.pathname;
  const recordsPerPageChange = (event) => {
    handleRecordsPerPageChange(event);
  };

  const handleSortingTable = (event) => {
    setTableFilter({
      ...tableFilter,
      sortType: event.target.value,
      sortDirection: event.target.value === "jobTitle" ? "asc" : "desc",
    });
    setCurrentPage(1);
  };

  const handlePaymentSortingChange = (event) =>{
    setTableFilter({
      ...tableFilter,
      sortType: event.target.value,
      sortDirection: tableFilter?.sortDirection,
    });
    setPaymentCurrentPage(1);
  }

  const sortDirectionChange = () => {
    if (tableFilter.sortDirection === "desc"){
      setTableFilter({
        ...tableFilter,
        sortDirection:"asc",
      });
    }else{
      setTableFilter({
        ...tableFilter,
        sortDirection:"desc",
      });
    }
  }

  const handleSearchKeyChange = (e) =>{
    if (e?.target?.value.trim() === "") {
      setPaymentCurrentPage(1);
      setSearchKey("")
      setTableFilter({
        ...tableFilter,
        searchKey: ''
      })
    }else{
      setSearchKey(e.target.value)
    }
  }

  const clearSearch = () => {
    setPaymentCurrentPage(1);
    setSearchKey("")
    setTableFilter({
      ...tableFilter,
      searchKey: "",
    });
  };

  return (
    <>
      <div className="product-filter-wrap d-flex justify-content-between align-items-center m-b30">
        <span className="woocommerce-result-count-left">
          {_config.prefix}
          {_config.showRange ? " 1-" + _config.showingUpto + " of " : " "}
          {_config.total + " " + _config.type}
        </span>
        <form className="woocommerce-ordering twm-filter-select sortbyfilterselect" method="get">
          {/* {_config?.type !== "Records" && ( */}
          {_config?.isSorting && (
            <>
              <span className="woocommerce-result-count">Sort By</span>
              <select
                className="wt-select-bar-2 selectpicker"
                data-live-search="true"
                data-bv-field="size"
                value={tableFilter?.sortType}
                onChange={handleSortingTable}
              >
                {!(currentPathName?.includes("/can-list") ||
                  currentPathName?.includes("/can-grid") ||
                  currentPathName?.includes("/users-list") ||
                  currentPathName?.includes("/users-grid") ||
                  currentPathName?.includes("/emp-list") ||
                  currentPathName?.includes("/emp-grid") ||
                  currentPathName?.includes("/candidate/my-teams")) && (
                    <>
                      <option value="recentJobs">Most Recent</option>
                    </>
                  )}
                {/* <option value="createdDate">Distance</option> */}
                {(currentPathName?.includes("/job-list") ||
                  currentPathName?.includes("/job-grid") ||
                  currentPathName?.includes("/search-job") ||
                  currentPathName?.includes("/applied-job") ||
                  currentPathName?.includes("/ongoing-job") ||
                  currentPathName?.includes("/schedule-job") ||
                  currentPathName?.includes("/emp-detail") ||
                  currentPathName?.includes("/history-job")) && (
                    <>
                      <option value="salary">Salary</option>
                      <option value="startDate">Recent Job Start</option>
                    </>
                  )}
                
                {(currentPathName?.includes("/can-list") ||
                  currentPathName?.includes("/can-grid") ||
                  currentPathName?.includes("/users-list") ||
                  currentPathName?.includes("/users-grid") ||
                  currentPathName?.includes("/emp-list") ||
                  currentPathName?.includes("/emp-grid")) && (
                    <>
                      <option value="recent">Recent</option>
                    </>
                  )}
                {(currentPathName?.includes("/emp-list") ||
                  currentPathName?.includes("/emp-grid")) && (
                    <>
                      <option value="rating">Rating</option>
                    </>
                )}
                {(currentPathName?.includes("/can-list") ||
                  currentPathName?.includes("/can-grid") ||
                  currentPathName?.includes("/users-list") ||
                  currentPathName?.includes("/users-grid") ||
                  currentPathName?.includes("/emp-list") ||
                  currentPathName?.includes("/emp-grid") ||
                  currentPathName?.includes("/candidate/my-teams")) && (
                    <>
                      <option value="name">Name</option>
                    </>
                  )}
                {(currentPathName?.includes("/emp-list") ||
                  currentPathName?.includes("/emp-grid") ||
                  currentPathName?.includes("/can-list") ||
                  currentPathName?.includes("/can-grid")) && (
                    <>
                      <option value="distance">Distance</option>
                    </>
                  )}
              </select>
              <span className="sort-icon" onClick={sortDirectionChange}>
                <i className={`fas ${tableFilter.sortDirection === 'asc' ? 'fa-sort-amount-up-alt' : 'fa-sort-amount-down'}`} style={{marginRight: "20px", marginLeft: "20px", fontSize: "24px", cursor: "pointer"}}/>
              </span>
            </>
          )}
          {console.log("canActiveTab----",canActiveTab)}
          {_config?.paymentFilter && (
            <>
            {/* <h4 className="section-head-small mb-4">Status</h4> */}
            <select
              className="wt-select-bar-large selectpicker"
              data-live-search="true"
              data-bv-field="size"
              value={tableFilter?.sortType || ""}
              onChange={handlePaymentSortingChange}
            >
              <option value="createdDate">Most Recent</option>
              <option value="jobTitle">Job Title</option>
              {
                role_type.includes('ROLE_OPENSTAFF_ADMIN') && 
                <option value="name">Company</option>
              }
             
              <option value="amount">Amount</option>
              <option value="serviceFee">Service Fee</option>
              <option value="jobStatus">Status</option>
              <option value="paymentMethod">Payment Method</option>
            </select>
            <span className="sort-icon" onClick={sortDirectionChange}>
                <i className={`fas ${tableFilter.sortDirection === 'asc' ? 'fa-sort-amount-up-alt' : 'fa-sort-amount-down'}`} style={{marginRight: "20px", marginLeft: "20px", fontSize: "24px"}}/>
              </span>
          </>
          )
          }
          {_config?.paymentFilter && (
          <div className="input-group">
                <input
                  type="text"
                  // className="form-control"
                  style={{marginRight: 10}}
                  placeholder="Search"
                  id='payment-searchkey'
                  value={searchKey}
                  onChange={(e) => handleSearchKeyChange(e)}
                  onKeyPress={handleSearchClick}
                />
                  {searchKey && (
                <button
                  type="button"
                  className="btn"
                  onClick={clearSearch}
                  style={{
                    position: 'absolute',
                    right: '40px',
                    top: 8,
                    zIndex: 2,
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <i className="fas fa-times" />
                </button>
              )}
              <button
                type="button"
                className="btn"
                style={{
                  position: 'absolute',
                  right: '10px',
                  top: 8,
                  zIndex: 2,
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                <i className="feather-search" onClick={handleSearchKeyFunction} />
              </button>
            </div>
          )}
          
          <select
            className="wt-select-bar-2 selectpicker"
            data-live-search="true"
            data-bv-field="size"
            value={rowPerPage}
            onChange={recordsPerPageChange}
          >
            <option value={10}>Show 10</option>
            <option value={20}>Show 20</option>
            <option value={50}>Show 50</option>
          </select>
        </form>
      </div>
    </>
  );
}

export default SectionRecordsFilter;
