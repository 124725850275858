import React, { useEffect, useState } from "react";
import TableLoader from "../../../../common/tableLoader";
import SectionPagination from "../../sections/common/section-pagination";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import apiCall from "../../../../../apicall";
import Toast from "../../../../common/toast";
import SessionExpired from "../../../../common/sessionExpired";
import Spinner from "../../../../common/spinner";
import { useSelector } from "react-redux";

const InvitationTable = ({
  invitationList,
  invitationLoader,
  invitationListRowPerPage,
  handleInvitationListRecordsPerPageChange,
  invitationListPaginationData,
  invitationCurrentPage,
  setInvitationCurrentPage,
  tableFilter,
  setTableFilter,
  jobDetailId,
}) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [clickedButtonId, setClickedButtonId] = useState(null);
  const [currentPage, setCurrentPage] = useState(invitationCurrentPage);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: false,
    total: invitationListPaginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setClickedButtonId(null);
  };

  const sendInvitation = async (details) => {
    console.log("send invitation----------",details)
    try {
      setClickedButtonId(details?.roleEntities[0]?.id);
      setIsLoading(true);
      const sendInvitationResponse = await apiCall(
        `jobNotifications/inviteApplicant/${jobDetailId}/${details?.roleEntities[0]?.id}`,
        "POST",
        details,
        TOKEN
      );
      console.log("sendInvitationResponse----", sendInvitationResponse);
      if (sendInvitationResponse?.status === 200) {
        setIsLoading(false);
        setClickedButtonId(null);
        setSessionExpired(false);
        setErrSuccessMsg({
          message: "Invitation has been sent",
          status: true,
        });
        // displayErrMsg("Invitation has been sent")
      } else if (sendInvitationResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(sendInvitationResponse?.response?.data?.message)
      }
    } catch (err) {
      setSessionExpired(false);
      console.log("setSessionExpired--", err);
      displayErrMsg("Something went wrong!");
    }
  };

  useEffect(() => {
    setInvitationCurrentPage(currentPage);
  }, [currentPage]);
  return (
    <div className="twm-right-section-panel candidate-save-job site-bg-gray">
      <SectionRecordsFilter
        _config={_filterConfig}
        rowPerPage={invitationListRowPerPage}
        handleRecordsPerPageChange={handleInvitationListRecordsPerPageChange}
        setTableFilter={setTableFilter}
        tableFilter={tableFilter}
        currentPage={currentPage}
      />
      <div className="twm-D_table table-responsive">
        <table
          // id="jobs_bookmark_table"
          className="table table-bordered twm-candidate-save-job-list-wrap"
        >
          <thead>
            <tr>
              <th>Applicant Name</th>
              {/* <th>Status</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!invitationLoader ? (
              invitationList?.length > 0 ? (
                invitationList?.map((val, index) => (
                  <tr key={index}>
                    <td>
                      <div className="twm-candidate-save-job-list">
                        <div className="twm-mid-content">
                          <span className="twm-job-title">
                            <h4>{`${val?.firstName} ${val?.lastName}`}</h4>
                          </span>
                        </div>
                      </div>
                    </td>
                    {/* <td>
                      <div className="twm-candidate-save-job-list">
                        <div className="twm-mid-content">
                          <span className="twm-job-title">
                            {val?.accountStatus}
                          </span>
                        </div>
                      </div>
                    </td> */}

                    <td>
                      <div>
                        <button
                          type="button"
                          className="site-button"
                          onClick={() => sendInvitation(val)}
                          disabled={clickedButtonId === val?.roleEntities[0]?.id} 
                        >
                          {clickedButtonId === val?.roleEntities[0]?.id ? (
                            <Spinner />
                          ) : (
                            "Send Invite"
                          )}
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="2">No results found.</td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan="5">
                  <TableLoader />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <SectionPagination
        currentPage={invitationCurrentPage}
        setCurrentPage={setCurrentPage}
        totalPages={invitationListPaginationData?.totalPages}
      />
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default InvitationTable;
