import React, { useState, useRef } from "react";
import TableLoader from "../../../../common/tableLoader";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import SectionPagination from "../../sections/common/section-pagination";
import GetProfileImage from "../../../../common/getProfileImage";
import { publicUser } from "../../../../../globals/route-names";
import { NavLink, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import JobHirePopUp from "../../../../pannels/../common/popups/popup-hire-seeker";
import ChatJobPopup from "./popup-chat";
import CoverLetter from "../../../../common/popups/popup-cover-letter";
const AppliedApplicantsTable = ({
  jobDetailId,
  appliedApplicantList,
  appliedApplicantLoader,
  appliedApplicantRowPerPage,
  handleAppliedApplicantRecordsPerPageChange,
  appliedApplicantListPaginationData,
  appliedApplicantCurrentPage,
  setappliedApplicantCurrentPage,
  tableFilter,
  setTableFilter,
  setAppliedApplicantSearchKey,
  handleAppliedChat,
  jobDetailsInfo
}) => {
  let updatedJobDetailInfo=jobDetailsInfo
  const modalRef = useRef(null);
  console.log("appliedApplicantList===========", updatedJobDetailInfo);
  // const applicantId = appliedApplicantList[0]?.applicantEntity?.id;
  const { role_type } = useSelector((state) => state.menu);
  const roleType = role_type;
  // const hiredState = appliedApplicantList[0]?.hired;
  const [searchedKey, setSearchedKey] = useState("");
  const [applicantId, setApplicantId] = useState('')
  const [selectedChat, setSelectedChat] = useState(null);
  const [coverLetter, setCoverLetter] = useState("");
  const [chatShow, setChatShow]=useState(false)
  const navigate = useNavigate();
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: false,
    total: appliedApplicantListPaginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const handleSearchClick = () => {
    setAppliedApplicantSearchKey(searchedKey);
    setappliedApplicantCurrentPage(1);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  const navigateToApplicantDetails = (item) => {
    navigate(`/can-detail/${item?.applicantEntity?.userEntityId}`, { state: item?.applicantEntity });
  };

  const StarRating = ({ rating }) => {
    const stars = [];
    const roundedRating = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;

    for (let i = 0; i < 5; i++) {
      if (i < roundedRating) {
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: "#128c5e" }}
          />
        );
      } else if (i === roundedRating && hasHalfStar) {
        stars.push(
          <i
            key={i}
            className="fas fa-star-half-alt"
            style={{ color: "#128c5e" }}
          />
        );
      } 
      else {
        stars.push(
          <i
            key={i}
            className="fas fa-star"
            style={{ color: "lightgrey" }}
          />
        );
      }
    }
    return stars;
  };

  const handleAppliedChatData = (applicant) => {
    setSelectedChat(applicant);
    setChatShow(true)
    updatedJobDetailInfo["hirePerson"]=applicant
    // const modalId = `popup-chat${applicant?.id}`;
    // const chatModal = new window.bootstrap.Modal(document.getElementById('chatModal'));
    // chatModal.show();
    // const modal = document.getElementById('chatModal');
    // if (modal) {
    //   // Open Bootstrap modal using jQuery
    //   window.$(modal).modal('show');
    // }
    handleAppliedChat(applicant)
  };

  const getApplicantId = (id) =>{
    setApplicantId(id)
  }
  const cleanHtmlContent = (html) => {
    return html
      .replace(/^"|"$/g, '')           // Remove leading and trailing quotes
      .replace(/\\&quot;/g, '"')       // Replace escaped quotes with normal quotes
      .replace(/\\"/g, '"')            // Replace escaped double quotes with normal quotes
      .replace(/\\'/g, "'");           // Replace escaped single quotes with normal quotes
  };
  return (
    <>      {appliedApplicantList?.length > 0 && (
<>

      <div className="form-group mb-4">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            placeholder="Search Keyword"
            value={searchedKey}
            onChange={(e) => setSearchedKey(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button className="btn" type="button">
            <i className="feather-search" onClick={handleSearchClick} />
          </button>
        </div>
      </div>
        <SectionRecordsFilter
          _config={_filterConfig}
          rowPerPage={appliedApplicantRowPerPage}
          handleRecordsPerPageChange={handleAppliedApplicantRecordsPerPageChange}
          setTableFilter={setTableFilter}
          tableFilter={tableFilter}
        />
        </>
      )}
      <div className="twm-candidates-list-wrap applicant-list">
        <ul>
          {!appliedApplicantLoader ? (
            appliedApplicantList?.length > 0 ? (
              appliedApplicantList?.map((item,ind) => {
                
                const modalId = `popup-chat${item.applicantEntity.id}`;
                return(
                <li key={item.applicantEntity.id}>
                  <div className="twm-candidates-list-style1 mb-5">
                    <div className="twm-media">
                      <div className="twm-media-pic">
                        <GetProfileImage
                          id={item?.applicantEntity?.userEntityId}
                          type={"userAvatar"}
                        />
                      </div>
                      <div className="twm-candidates-tag">
                        <span>{item?.applicantEntity?.applicationStatus}</span>
                      </div>
                    </div>
                    <div className="twm-mid-content"
                    data-bs-toggle="modal"
                              href={"#cover_letter"}
                              onClick={() => setCoverLetter(cleanHtmlContent(item.applicantEntity.coverLetter))}
                              role="button">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h4>{`${item?.applicantEntity?.firstName} ${item?.applicantEntity?.lastName}`}</h4>
                        
                      </div>
                      {/* <p>
                        Reviews:
                        {item?.applicantEntity?.numberOfReview
                          ? item.applicantEntity.numberOfReview
                          : 0}
                      </p> */}
                      <div>
                        <StarRating
                          rating={item?.applicantEntity?.averageNumStars?.toFixed(1)}
                        />
                        <span style={{ fontSize: 12 }}>
                          {item?.applicantEntity?.numberOfReview
                            ? ` (${item.applicantEntity.numberOfReview} Reviews)`
                            : `(0 Review)`}
                          
                        </span>
                      </div>
                      <p className="twm-candidate-address">
                        Jobs Done :&nbsp;
                        {item?.applicantEntity?.numberOfJobsDone
                          ? item?.applicantEntity?.numberOfJobsDone
                          : "No Job Done"}
                      </p>
                      <p className="twm-candidate-address">
                        Bank Verified :
                        {item?.applicantEntity?.validBankAccount
                          ? " Yes"
                          : " No"}
                      </p>
                      {
                         <p className="twm-candidate-address">
                         Hired :
                         {item?.hired
                           ? " Yes"
                           : " No"}
                       </p>
                      }
                      {/* {roleType == "ROLE_INSTITUTION_ADMIN" && (
                        <div className="twm-fot-content">
                          <div className="twm-right-btn">
                              <a
                                className="site-button twm-jobs-browse site-text-primary job-details"
                                data-bs-toggle="modal"
                                href={"#cover_letter"}
                                role="button"
                              >
                                View Cover Letter
                              </a>
                          </div>
                        </div>
                      )} */}
                        
                    </div>
                    <div className="twm-fot-content">
                          <div className="twm-left-info">
                            
                          </div>
                          <div className="twm-right-btn">
                            <div className="twm-jobs-category green">
                              
                            </div>
                            {roleType == "ROLE_INSTITUTION_ADMIN" && (
                          <div className="twm-fot-content">
                            <div className="twm-right-btn">
                              {!item?.hired && (
                                <a
                                  className="site-button"
                                  data-bs-toggle="modal"
                                  href={"#hire_popup"}
                                  role="button"
                                  onClick={() => {
                                    getApplicantId(item.applicantEntity.id);
                                    setCoverLetter(item.applicantEntity.id)
                                  }}
                                >
                                  Hire
                                </a>
                              )}
                              {item?.hired && (
                                <h4 key={item.id} style={{ color: "green" }} >
                                  HIRED 
                                </h4>
                              )}
                            </div>
                            <div className="twm-right-btn ms-3">
                              {(item?.hired || !item?.hired) && (
                                <a
                                key={item.id}
                                  className="site-button"
                                  data-bs-toggle="modal"
                                  role="button"
                                  href={`#chatModal`}
                                  onClick={()=> handleAppliedChatData(item.applicantEntity)}
                                >
                               <i className="far fa-comment fa-lg"/>
                                </a>
                              )}
                            </div>
                          </div>
                          
                        )}
                          {roleType == "ROLE_OPENSTAFF_ADMIN" && (
                            <div className="twm-fot-content">
                              <div className="twm-right-btn">
                                <span
                                  className="twm-view-prifile site-text-primary"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => navigateToApplicantDetails(item)}
                                >
                                  View Details
                                </span>
                              </div>
                            </div>
                          )}
                          </div>
                        </div>
                  </div>

      </li>
              )})
            ) : (
              <div className="tw-faq-section mt-5">
                <div className="accordion tw-faq" id="sf-faq-accordion-2">
                  <h3 style={{ color: "red" }}>
                    No applied applicant list available!
                  </h3>
                </div>
              </div>
            )
          ) : (
            <TableLoader />
          )}
        </ul>
        {appliedApplicantList?.length > 0 && (
          <SectionPagination
            currentPage={appliedApplicantCurrentPage}
            setCurrentPage={setappliedApplicantCurrentPage}
            totalPages={appliedApplicantListPaginationData?.totalPages}
          />
        )}
      </div>
      <ChatJobPopup jobDetailsInfo={updatedJobDetailInfo}/>
      <JobHirePopUp jobDetailId={jobDetailId} applicantId={applicantId} />
      <CoverLetter coverLetter={coverLetter} />
  
    </>
  );
};

export default AppliedApplicantsTable;
