import React, { useState } from "react";
import TrustedWorkersList from "../trusted-workers/trusted-workers-list";

const AddTrustedCompanyPopup = ({
  fetchTrustedCompanyList
}) => {


  return (
    <div
      className="modal fade twm-saved-jobs-view"
      id="add_trusted_company_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered" style={{ "maxWidth" : "800px" }}>
        <div className="modal-content">
          <div className="modal-header" style={{ "marginTop": "0px" }} >
            <h2 className="modal-title" id="sign_up_popupLabel2">
                Add Trusted Companies
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body" style={{ "padding" : "0px 20px" , "maxHeight": "500px", "overflowY": "scroll" }}>
            <TrustedWorkersList fetchTrustedCompanyList={fetchTrustedCompanyList} trustedWorker={null}/>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="site-button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTrustedCompanyPopup;
