import React, { useState, useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Toast from "../toast";
import Spinner from "../spinner";
import apiCall from "../../../apicall";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SessionExpired from "../sessionExpired";
import CardPaymentScreen from "./payment/paymentScreen";
import ACHPaymentScreen from "./payment/ach-payment-screen";
import ConfirmationPostJobPopUp from "../../pannels/public-user/sections/jobs/confirmationPostJob";
import { postJobContactPersonDetails } from "../../../store/reducers/menu";
import { userDetails } from "../../../store/reducers/menu";
import TableLoader from "../tableLoader";
import { logEvent_Analytics } from "../firebase/firebase-analytics-service";

const JobPostForm = ({
  role_type,
  selectedItemId,
  empDetailId,
  handleClosePopup,
  modalShow,
  handleHideJobType,
  empDetailsInfo
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const isACHEnalbed = process.env.REACT_APP_ENABLED_ACH === "true";
  const isManageTeam = true;
  const { TOKEN, userInfo, contactPersonInfo } = useSelector((state) => state.menu);
  console.log("userInfo:::::::::", userInfo)
  const autocompleteRef = useRef();
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [isComputeChargeLoading, setIsComputeChargeLoading] = useState(false);
  const [isLoadingPositionList, setIsLoadingPositionList] = useState(false);
  const [computeChargesDetails, setComputeChargesDetails] = useState({});
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isEmpLocationListLoading, setIsEmpLocationListLoading] = useState(false);
  const [positionDropdownList, setPositionDropdownList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const [empLocationInfo, setEmpLocationInfo] = useState([]);
  const [selectedItemPostId, setSelectedItemPostId] = useState("");
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [postJobReqParam, setPostJobReqParam] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [registerResponseData, setRegisterResponseData] = useState();
  const [userinfo, setUserInfo] = useState()
  const [initialFormValues, setInitialFormValues] = useState({
    jobTitle: "",
    position: "",
    profession: "",
    phoneNumber: contactPersonInfo?.contactPersonPhone,
    timeIn: "",
    timeOut: "",
    hourlyRate: "",
    date: "",
    contactPerson: contactPersonInfo?.contactPerson,
    endDate: "",
    startDate: "",
    hourPerDay: "",
    notes: "",
    selectedLocation: "",
    selectedLocationId: "",
    selectedItemPostId: selectedItemPostId,
    workHours: "1",
    totalWorkTime: "1.00",
    privateJob: false,
    accessType: ""
  });
  const [formValues, setFormValues] = useState({
    jobTitle: "",
    position: "",
    profession: "",
    // firstName: "",
    // lastName: "",
    phoneNumber: contactPersonInfo?.contactPersonPhone,
    timeIn: "",
    timeOut: "",
    hourlyRate: "",
    date: "",
    contactPerson: contactPersonInfo?.contactPerson,
    endDate: "",
    startDate: "",
    hourPerDay: "",
    notes: "",
    selectedLocation: "",
    selectedLocationId: "",
    maxEndDate: null,
    workHours: "1",
    totalWorkTime: "1.00",
    privateJob: false,
    accessType: "",
    noOfCandidates: 1
  });
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const userRoleType = userInfo?.roleEntities.map((item)=>item?.roleType);
  const [formErrors, setFormErrors] = useState({});
  const [locationLoader, setLocationLoader] = useState(false);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false); 
  const empDetailPostId = userInfo?.id;

    const handleAccordionToggle = (e) => {
      e.stopPropagation(); 
      setIsAccordionOpen(!isAccordionOpen); 
    };

  const getCurrentClockinClockoutTime = (paymentMethodResponse) => {
    const currentDate = new Date();
    const timeIn = new Date(currentDate.getTime() + 60 * 60 * 1000); // 60 minutes ahead
    var minutes = timeIn.getMinutes();
    var roundedMinutes = Math.ceil(minutes / 15) * 15; // Round to nearest 15 minutes
    timeIn.setMinutes(roundedMinutes);
    const timeOut = new Date(timeIn.getTime() + 60 * 60 * 1000); // 60 minutes ahead of time in
    // const startDate = currentDate;
    // const endDate = new Date(currentDate);
    // endDate.setDate(currentDate.getDate() + 1); // Next day

       // Determine startDate and endDate based on paymentMethod
       const startDate = currentDate;
       const endDate = new Date(currentDate);
       
       let maxEndDate = null;
       console.log("empDetailsInfo",paymentMethodResponse,empDetailsInfo)
       if (paymentMethodResponse?.paymentMethod === 'stripe' || empDetailsInfo?.paymentMethod === 'stripe') {
         endDate.setDate(currentDate.getDate() + 6); 
         maxEndDate = new Date(startDate);
         maxEndDate.setDate(startDate.getDate() + 6);
       } else {
         endDate.setDate(currentDate.getDate() + 1); // 1 day ahead for other payment methods
       }

    setFormValues((prevValues) => ({
      ...prevValues,
      date: currentDate,
      timeIn: timeIn,
      timeOut: timeOut,
      startDate: startDate,
      endDate: endDate,
      maxEndDate: maxEndDate
    }));
  };

  const getPaymentMethodAndTime =async () =>{
    let paymentMethodResponse;
    if(userRoleType.includes("ROLE_INSTITUTION_ADMIN")){
     paymentMethodResponse = await checkPaymentMethod()
    }
    getCurrentClockinClockoutTime(paymentMethodResponse);
  }

  useEffect(() => {
    getPaymentMethodAndTime()
    logEvent_Analytics('jobpost_screen_web');
  }, []);

  // Manually retain focus for noOfCandidates field
  useEffect(() => {
    if(inputRef.current){
      inputRef.current.focus();
    }
  },[formValues?.noOfCandidates]);

  useEffect(() => {
    setFormErrors({});
  }, [role_type]);

  // const calculateTotalHours = (fieldType, value) => {
  //   const hours = fieldType === 'workHours' ? value : formValues.workHours;
  //   const minutes = fieldType === 'workFraction' ? value : formValues.workFraction;
    
  //   const hoursValue = parseFloat(hours) || 0;
  //   const minutesValue = parseFloat(minutes) || 0;
  
  //   const totalHoursInDecimal = hoursValue + minutesValue;
  //   const timeIn = fieldType === 'timeIn' ? value : formValues.timeIn;
  //   const timeOut = fieldType === 'timeOut' ? value : formValues.timeOut;
    
  //   const startTime = new Date(timeIn);
  //   const endTime = new Date(timeOut);
  
  //   startTime.setSeconds(0, 0);
  //   endTime.setSeconds(0, 0);
  
  //   const timeDifference = (endTime - startTime) / (1000 * 60 * 60); // Convert milliseconds to hours
  
  //   console.log("timeDifference====", hoursValue, minutesValue, startTime, endTime, timeDifference);
  
  //   if (totalHoursInDecimal.toFixed(2) > 0.0 && parseFloat(totalHoursInDecimal.toFixed(2)) > timeDifference) {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       workHours: `Worked hours cannot exceed the available time of ${timeDifference.toFixed(2)} hours.`,
  //     }));
  //   } else {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       workHours: ""
  //     }));
  //   }
  
  //   setFormValues((prev) => ({
  //     ...prev,
  //     totalWorkTime: parseFloat(totalHoursInDecimal.toFixed(2))
  //   }));
  // };

  const calculateTotalHours = (fieldType, value) => {
    const hours = fieldType === 'workHours' ? value : formValues.workHours;
    const minutes = fieldType === 'workFraction' ? value : formValues.workFraction;
 
    const hoursValue = parseInt(hours) || 0;
    const minutesValue = parseInt(minutes) || 0;
 
    // Ensure minutes don't exceed 59
    if (minutesValue >= 60) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        workFraction: "Minutes cannot exceed 59."
      }));
      return;
    }
 
    // Convert minutes to decimal (e.g., 45 minutes = 0.75)
    const minutesInDecimal = minutesValue / 60;
    const totalHoursInDecimal = hoursValue + minutesInDecimal;
 
    const timeIn = fieldType === 'timeIn' ? value : formValues.timeIn;
    const timeOut = fieldType === 'timeOut' ? value : formValues.timeOut;
 
    const startTime = new Date(timeIn);
    const endTime = new Date(timeOut);
 
    startTime.setSeconds(0, 0);
    endTime.setSeconds(0, 0);
 
    // Calculate the time difference in minutes
    const timeDifferenceInMinutes = (endTime - startTime) / (1000 * 60); // Convert milliseconds to minutes
 
    // Convert total time difference to hours and remaining minutes
    const totalHours = Math.floor(timeDifferenceInMinutes / 60); // Full hours
    const remainingMinutes = timeDifferenceInMinutes % 60; // Remaining minutes
 
    const timeDifference = totalHours + (remainingMinutes / 60);
 
    // Adjust the time difference to hours and minutes (e.g., 1 hour 30 minutes becomes 1.30)
   
 
 
    // Validation check for exceeding available time
    if (totalHoursInDecimal > 0 && totalHoursInDecimal > timeDifference) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        workHours: `The duration cannot exceed the available time of ${timeDifference.toFixed(2)} hours`,
      }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        workHours: "",
      }));
    }
 
    // Update the form values with decimal hours (e.g., 1.75)
    setFormValues((prev) => ({
      ...prev,
      totalWorkTime: totalHoursInDecimal.toFixed(2), // Format as decimal (e.g., 1.75)
    }));
  };

  const handleAccessTypeChange = (e, type) => {
    const { checked } = e.target;
  
    setFormValues((prev) => {
      let newAccessType = prev.accessType;
  
      if (checked) {
        // Add the selected access type
        if (type === "employeesOnly" && prev.accessType === "trustedWorkersOnly") {
          newAccessType = "both";
        } else if (type === "trustedWorkersOnly" && prev.accessType === "employeesOnly") {
          newAccessType = "both";
        } else {
          newAccessType = type;
        }
      } else {
        // Remove the deselected access type
        if (type === "employeesOnly" && prev.accessType === "both") {
          newAccessType = "trustedWorkersOnly";
        } else if (type === "trustedWorkersOnly" && prev.accessType === "both") {
          newAccessType = "employeesOnly";
        } else {
          newAccessType = "";
        }
      }
  
      return {
        ...prev,
        accessType: newAccessType,
      };
    });

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      accessType: "",
    }));
  };
  

  const handleChange = (e, field) => {
    let { value, checked } = e.target;
 
    const { hourPerDay, workHours, workFraction } = formValues;
    if (field === 'hourlyRate') {
      // Allow only numbers and up to two decimal places, and allow empty value
      const regex = /^\d*\.?\d{0,2}$/;
      if (!regex.test(value) && value !== "") {
        return; // If the input doesn't match the regex and isn't empty, do not update the state
      }
    }
 
    // if (field === 'workHours') {
    //   // Allow only whole numbers and ensure the value does not exceed 24
    //   const regex = /^\d*$/;
    //   const numericValue = parseFloat(value);
    //   if ((!regex.test(value) && value !== "") || numericValue > 24) {
    //     return; // Do not update the state if the value is not a whole number or greater than 24
    //   }
    // }
 
    // if (field === "workHours") {
    //   // Ensure workHours accepts only whole numbers (no decimals)
    //   const regex = /^\d+$/;
    //   if (!regex.test(value) && value !== "") {
    //     setFormErrors((prevErrors) => ({
    //       ...prevErrors,
    //       [field]: "Please enter a valid whole number for work hours.",
    //     }));
    //     return;
    //   }
    // }

  

    if (field === 'workHours') {
      if (value === "") {
        setFormValues((prev) => ({
            ...prev,
            workHours: "",
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            workHours: "", // Clear the error for this field if empty
        }));
        return; // Exit early if clearing the field
      }
      // Only allow whole numbers between 1 and 24
      const regex = /^[1-9]\d*$/; // Matches whole numbers greater than 0
      const numericValue = parseInt(value, 10);
  
      if (!regex.test(value) || numericValue < 1 || numericValue > 24) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          workHours: "Please enter a number between 1 and 24.",
        }));
        return; // Do not update the state if the value is invalid
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          workHours: "", // Clear the error for this field if valid
        }));
      }
    }
 
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "", // Clear the error for this field
    }));
 
    if (role_type === "Single") {
      if (field === "workHours" || field === "workFraction") {
        // const workHoursValue = parseFloat(value);
        calculateTotalHours(field, value)
        // const regex = /^\d+$/; // Only allow whole numbers (no decimals)
        // const workHoursValue = parseInt(value, 10);
        // if (!regex.test(value) && value !== "") {
        //   return; // If the input doesn't match the regex and isn't empty, do not update the state
        // }
 
        // const timeIn = formValues?.timeIn;
        // const timeOut = formValues?.timeOut;
 
        // if (timeIn && timeOut) {
        //   // Calculate time difference in hours between timeIn and timeOut
        //   const timeDifferenceInHours = Math.round(Math.abs(timeOut - timeIn) / (1000 * 60 * 60));
        //   if (workHoursValue > timeDifferenceInHours) {
        //     setFormErrors((prevErrors) => ({
        //       ...prevErrors,
        //       workHours: `Work Hours cannot be greater than the time window.`,
        //     }));
        //     return; // Do not update the state if validation fails
        //   }
        // }
      }
    }
    if (role_type === "Multiple") {
      const hoursValue = field === "workHours" ? parseFloat(value) || 0 : parseFloat(workHours) || 0;
      const fractionValue = field === "workFraction" ? parseFloat(value) / 60 || 0 : parseFloat(workFraction) / 60 || 0;
 
      const totalHours = hoursValue + fractionValue;
      const maxHoursPerDay = parseFloat(hourPerDay) || 0;
 
      // if (totalHours > maxHoursPerDay) {
      //   setFormErrors((prevErrors) => ({
      //     ...prevErrors,
      //     workHours: "Work time cannot exceed time per day.",
      //   }));
      // }else{
      //   setFormErrors((prevErrors) => ({
      //     ...prevErrors,
      //     workHours: "",
      //   }));
      // }
      setFormValues((prev) => ({
        ...prev,
        workHoursMulti: totalHours,
      }));
    }
 
 
    setFormValues((formValuesState) => ({
      ...formValuesState,
      [field]: value,
    }));
 
    handleInputChange(e, field);
   
 
    if (field === 'contactPerson') {
      dispatch(postJobContactPersonDetails({
        ...contactPersonInfo,
        contactPerson: value
      }));
    }

    console.log("accessType----------",checked,field)
    if (field === 'accessType') {
      setFormValues((prev) => ({
        ...prev,
        accessType: checked ? "trustedWorkersOnly" :  "employeesOnly",
        hourlyRate: checked ? formValues?.hourlyRate : "" 
      }));
    }
    if (field === 'privateJob') {
      setFormValues((prev) => ({
        ...prev,
        privateJob: checked,
        accessType: checked ? formValues?.accessType !== "" ? formValues?.accessType : "employeesOnly" : ""
      }));
    }
  };

  // const handleChange = (e, field) => {

    
  //   if (field === 'hourlyRate') {
  //     // Allow only numbers and up to two decimal places
  //     const regex = /^\d+(\.\d{0,2})?$/;
  //     if (!regex.test(e.target.value)) {
  //       return; // If the input doesn't match the regex, do not update the state
  //     }
  //   }
  
  //   setFormValues((formValuesState) => ({
  //     ...formValuesState,
  //     [field]: e.target.value,
  //   }));


  //   handleInputChange(e, field);
  //   setFormErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [field]: "", // Clear the error for this field
  //   }));



  //   if(field === 'contactPerson'){
  //     dispatch(postJobContactPersonDetails({
  //       ...contactPersonInfo,
  //       contactPerson: e.target.value
  //     }))
  //   }
  // };

  // const handleTimeInputChange = (date, fieldName) => {
  //   if (fieldName === "timeIn") {
  //     const timeInValue = date;
  //     const timeInDate = moment(formValues.date)
  //       .startOf("day")
  //       .add(moment.duration(timeInValue));
  //     const timeOutDate = timeInDate.clone().add(15, "minutes");
  //     const formattedTimeOut = timeOutDate.format("HH:mm");

  //     console.log("timeInValue------", timeInValue);
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       timeIn: timeInValue,
  //       timeOut: formattedTimeOut,
  //     }));

  //     const timeDiff = timeOutDate.diff(timeInDate, "minutes");
  //     if (timeDiff < 60) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         timeOut: "Time Out must be at least 60 minutes after Time In.",
  //       }));
  //     } else {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [fieldName]: "",
  //       }));
  //     }
  //   } else {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [fieldName]: "",
  //     }));
  //   }
  // };

  // const handleTimeInputChange = (date, fieldName) => {
  //   if (fieldName === "timeIn") {
  //     const timeInValue = date;
  //     const timeOutValue = new Date(timeInValue.getTime() + 60 * 60 * 1000); // 1 hour later

  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       timeIn: timeInValue,
  //       timeOut: timeOutValue,
  //     }));

  //     calculateTotalHours('timeOut', timeOutValue)

  //     const timeDiff = (timeOutValue - timeInValue) / (60 * 1000); // Difference in minutes
  //     if (timeDiff < 60) {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         timeOut: "Time Out must be at least 60 minutes after Time In.",
  //       }));
  //     } else {
  //       setFormErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [fieldName]: "",
  //       }));
  //     }
  //   } else {
  //     setFormErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [fieldName]: "",
  //     }));
  //   }
  // };
  const handleTimeInputChange = (date, fieldName) => {
    let timeInValue, timeOutValue;
    
    if (fieldName === "timeIn") {
      timeInValue = date;
      timeOutValue = formValues.timeOut || new Date(timeInValue.getTime() + 60 * 60 * 1000); // default 1 hour later if not set

      setFormValues((prevValues) => ({
        ...prevValues,
        timeIn: timeInValue,
        timeOut: timeOutValue, // Ensure timeOut is updated
      }));

      // Check validation between timeIn and timeOut
      calculateTotalHours('timeIn', timeInValue);

      const timeDiff = (timeOutValue - timeInValue) / (60 * 1000); // Difference in minutes
      console.log("timeDiff", timeDiff)
      if (timeDiff < 60) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeOut: "Time Out must be at least 60 minutes after Time In.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeOut: "", // Clear error if valid
        }));
      }
    } else if (fieldName === "timeOut") {
      timeInValue = formValues.timeIn;
      timeOutValue = date;

      setFormValues((prevValues) => ({
        ...prevValues,
        timeOut: timeOutValue,
      }));

      // Check validation between timeIn and timeOut
      calculateTotalHours('timeOut', timeOutValue);

      const timeDiff = (timeOutValue - timeInValue) / (60 * 1000); // Difference in minutes
      console.log("timeDiffOut", timeDiff)
      if (timeDiff < 60) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeOut: "Time Out must be at least 60 minutes after Time In.",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          timeOut: "", // Clear error if valid
        }));
      }
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "",
      }));
    }
  };


  const handleTimeChange =async (date, field) => {
    setFormValues((formValuesState) => ({
      ...formValuesState,
      [field]: date,
    }));
    console.log("date------", date);
    await handleTimeInputChange(date, field);
    if(field === 'timeOut'){
      calculateTotalHours(field, date)
    }
  };

  const handleDatePickerChange = (date, field) => {
    setFormValues((formValuesState) => ({
      ...formValuesState,
      [field]: date,
    }));
    handleDateChange(date, field);
  };

  const getEmpLocationData = async () => {
    try {
      setLocationLoader(true);
      const api = `institutions/locations?offset=0&sort=createdDate,desc&page=0&size=500`;
      const empLocationResponse = await apiCall(api, "GET", null, TOKEN);
      if (empLocationResponse?.data.content?.length > 0) {
        //setSessionExpired(false);
        setEmpLocationInfo(empLocationResponse.data.content);
      } else if (empLocationResponse?.data.content?.length === 0) {
        setEmpLocationInfo([]);
      } else if (empLocationResponse?.response?.status === 401) {
        //setSessionExpired(true);
        // setErrorMessage("Your session has expired!!");
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(empLocationResponse?.response?.data?.message)
      }
      setLocationLoader(false);
    } catch (err) {
      // setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      setLocationLoader(false);
    }
  };
  const resetState = () => {
    setSelectedType("");
    setSelectedFields([]);
    setFormValues(initialFormValues);
    //setErrSuccessMsg({ message: "", status: "error" });
    setFormErrors({});
  };

  useEffect(() => {
    if (userRoleType.includes("ROLE_INSTITUTION_ADMIN")) {
      getEmpLocationData();
    }
    if (modalShow) {
      resetState();
    }
  }, []);

  const handleLocationChange = (event) => {
    setSelectedItemPostId(event.target.value);
    const location = empLocationInfo.find(
      (loc) => loc.id === event.target.value
    );
    setFormValues((prevValues) => ({
      ...prevValues,
      selectedLocation: location?.locationName,
      selectedLocationId: location?.id
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      selectedItemPostId: "", // Clear the error for this field
      selectedLocation: ""
    }));
  };
  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-numeric characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      dispatch(postJobContactPersonDetails({
        ...contactPersonInfo,
        contactPersonPhone : `${match[1]}-${match[2]}-${match[3]}`
      }))
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return phoneNumber; // Return original value if not 10 digits
  };

  const handleInputChange = (e, fieldName) => {
    const { name, value } = e.target;
    if (fieldName === "phoneNumber") {
      // Format phone number when 10 digits are completed
      const formattedPhoneNumber = formatPhoneNumber(value);
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName || name]: formattedPhoneNumber,
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        [fieldName || name]: value,
      }));
    }

    // setFormErrors((prevErrors) => ({
    //   ...prevErrors,
    //   [fieldName || name]: "",
    // }));
  };

  const handleDateChange = (date, fieldName) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [fieldName]: date,
    }));
    
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: "",
    }));

    if (fieldName === "startDate" && (userInfo?.paymentMethod === 'stripe' || empDetailsInfo?.paymentMethod === 'stripe')) {
      const maxDate = new Date(date);
      maxDate.setDate(date.getDate() + 6); // Set max date to 7 days after the selected start date
      setFormValues((prevValues) => ({
        ...prevValues,
        endDate: maxDate,
        maxEndDate: maxDate,
      }));
    }
  };
  const today = new Date();

  const checkPaymentMethod =async () =>{
    try {
      const accountResponse = await apiCall(
        "account",
        "GET",
        null,
        TOKEN
      );
      console.log("accountResponse-----",accountResponse)
      if (accountResponse?.data?.id && accountResponse?.status === 200) {
        dispatch(userDetails(accountResponse?.data));
        setUserInfo(accountResponse?.data)
        return accountResponse?.data;
      } else {
        displayErrMsg(accountResponse?.response?.data?.message);
        return false;
      }
    } catch (err) {
      // setSessionExpired(false);
      displayErrMsg("Network Error!");
      return false;
    }
  }

  const validateForm = (paymentMethodResponse) => {
    const errors = {};

    if (!formValues.profession || formValues.profession.trim() === "") {
      errors.profession = "Profession is required.";
    }
    if (!formValues.contactPerson || formValues.contactPerson.trim() === "") {
      errors.contactPerson = "Contact Person is required.";
    }
  
    // Validate fields common to both roles
    if (!formValues.jobTitle || formValues.jobTitle.trim() === "") {
      errors.jobTitle = "Job Title is required.";
    }

    if (!formValues.phoneNumber || formValues.phoneNumber.length != 12) {
      errors.phoneNumber = "Phone number must have 10 digits.";

      // errors.phoneNumber = "Phone number must be in the format 123-456-7890.";
    }

    if (userInfo?.manageTeam || empDetailsInfo?.manageTeam) {
      if(!formValues?.noOfCandidates || formValues?.noOfCandidates === ""){
        errors.noOfCandidates = "Number of hires is required.";
      }
    }

    // if (!formValues.contactPerson || formValues.contactPerson.trim() === "") {
    //   errors.contactPerson = "Contact Person is required.";
    // }

    // console.log("parseFloat(formValues.hourlyRate) > 40",userRoleType,formValues.hourlyRate, 40)

    //  if (userRoleType == "ROLE_INSTITUTION_ADMIN" && role_type === "Single") {
    //     if (parseFloat(formValues.hourlyRate) <= 40) {
    //       errors.hourlyRate = "Hourly Rate must be greater than 40.";
    //     }
    //   }
   
      if (!formValues.hourlyRate || formValues.hourlyRate.trim() === "") {
        if(formValues?.accessType !== "employeesOnly"){
         errors.hourlyRate = "Hourly Rate is required.";
        }
  
        if (role_type === "Single") {
          if (!formValues.date) {
            errors.date = "Date  is required.";
          }
          if (!formValues.timeIn) {
            errors.timeIn = "Time In is required.";
          }
          if (!formValues.timeOut) {
            errors.timeOut = "Time Out is required.";
          }
          
          const timeInMoment = moment(formValues.timeIn, "hh:mm a");
          const timeOutMoment = moment(formValues.timeOut, "hh:mm a");
          const currentTime = moment();
          const timeDifference = timeOutMoment.diff(timeInMoment, "minutes");
          const timeInDifference = timeInMoment.diff(currentTime, "minutes");
  
          if (timeInDifference < 60) {
            errors.timeIn =
              "Time In must be at least one hour from the current time.";
          }
          if (timeInMoment.isSameOrAfter(timeOutMoment)) {
            errors.timeIn = "Time In must be before Time Out.";
          }
  
          if (timeDifference < 60) {
            errors.timeOut =
              "Time Out must be at least 60 minutes after Time In.";
          }
        }
     
        // if (userRoleType == "ROLE_INSTITUTION_ADMIN") {
        //   if (!selectedItemPostId || selectedItemPostId.trim() === "") {
        //     errors.selectedItemPostId = "Location is required.";
        //   }
        // }
    }
   
    if (formValues.privateJob) {
      if (!formValues?.accessType || !(formValues?.accessType === "employeesOnly" || formValues?.accessType === "trustedWorkersOnly" || formValues?.accessType === "both")) {
        errors.accessType = "Please select who can see this job.";
      }
    }

    if((userRoleType.includes("ROLE_INSTITUTION_ADMIN") || userRoleType.includes("ROLE_OPENSTAFF_ADMIN") || userRoleType.includes("ROLE_INSTITUTION_POSTER_ADMIN"))){
      if (!formValues?.workHours || formValues?.workHours?.trim() === "") {
        errors.workHours = role_type === "Single" ? "Hours is required." : "Hours (Daily) is required.";
      }
    }

    if (userRoleType.includes("ROLE_INSTITUTION_ADMIN")) {
      if (!formValues.selectedLocation || formValues.selectedLocation.trim() === "") {
        errors.selectedLocation = "Select at least one location.";
      }
    }

    if (role_type === "Multiple") {
      // if (!formValues.hourPerDay || formValues.hourPerDay.trim() === "") {
      //   errors.hourPerDay = "Work Time per Day is required.";
      // }
      if (!formValues.startDate) {
        errors.startDate = "Start Date is required.";
      }
      if (!formValues.endDate) {
        errors.endDate = "End Date is required.";
      } 
      // else {
      //   const startDateMoment = moment(formValues.startDate);
      //   const endDateMoment = moment(formValues.endDate);

      //   console.log("startDateMoment",startDateMoment,endDateMoment)
      //   if (endDateMoment.diff(startDateMoment, "days") !== 1) {
      //     errors.endDate = "End Date must be one day after Start Date.";
      //   }
      // }
      if (formValues.startDate && formValues.endDate) {
        // const startDateMoment = moment(formValues.startDate);
        // const endDateMoment = moment(formValues.endDate);
        let startDateMoment = moment(formValues.startDate).startOf('day'); // Set time to 12:00 AM
        let endDateMoment = moment(formValues.endDate).endOf('day'); // Set time to 11:59 PM
  
        if (endDateMoment.isSame(startDateMoment, 'day')) {
          errors.endDate = "End Date must not be the same as Start Date.";
        }
        if (endDateMoment.isBefore(startDateMoment, 'day')) {
          errors.endDate = "End Date must be after Start Date.";
        }
        // console.log("userinfooo",paymentMethodResponse)
        // if (paymentMethodResponse?.paymentMethod === 'stripe') {
        //   const diffDays = endDateMoment.diff(startDateMoment, 'days');
        //   if (diffDays < 6) {
        //     errors.endDate = "End Date must be at least 7 days after Start Date.";
        //   } else if (diffDays > 6) {
        //     errors.endDate = "End Date must not be more than 7 days after Start Date.";
        //   }
        // }
        const hoursValue = parseFloat(formValues.workHours) || 0;
        const fractionValue = parseFloat(formValues.workFraction) / 60 || 0;
        const totalHours = hoursValue + fractionValue;
        const maxHoursPerDay = parseFloat(formValues.hourPerDay) || 0;
 
        // if (totalHours > maxHoursPerDay) {
        //   errors.workHours = "Work time cannot exceed time per day.";
        // }
      }
    }

    console.log("set for errors", errors);
    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Return true if no errors, false if there are errors
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  const handlePositionchange = (e) => {
    if (e.target.value !== "") {
      setSelectedType(e.target.value);
      const selectedPosition = positionDropdownList.find(
        (pos) => pos.typeName === e.target.value
      );
      const fileds = JSON.parse(selectedPosition.relatedJobFields);
      setFormValues((prevValues) => ({
        ...prevValues,
        profession: selectedPosition?.typeName || "",
      }));

      setSelectedFields(selectedPosition ? fileds.fields : []);
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        profession: "", // Clear the error for this field
      }));
    }
  };

  const displaySuccessMsg = () => {
    setIsLoading(false);
    resetState();
    if (autocompleteRef.current) {
      autocompleteRef.current.value = "";
    }
  };

  const computeCharges = async (charges) => {
    const api = `institutions/jobs/computeCharges`;
    try {
      const computeChargeResponse = await apiCall(api, "POST", charges, TOKEN);
      console.log("user list computeChargeResponse", computeChargeResponse);
      if (computeChargeResponse?.status === 200) {
        setSessionExpired(false);
        setComputeChargesDetails(computeChargeResponse?.data);
        setShowPaymentScreen(true);
        handleHideJobType(false);
        setIsLoading(false);
        return true;
      } else if (computeChargeResponse?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
        setIsLoading(false);
        return false;
      } else if (computeChargeResponse?.response?.status === 400) {
        displayErrMsg(computeChargeResponse?.response?.data?.errorMessage);
        setIsLoading(false);
        return false;
      } else {
        setSessionExpired(false);
        setIsLoading(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(computeChargeResponse?.response?.data?.message)
        return false;
      }
    } catch (error) {
      console.log("compute charges error", error);
      setSessionExpired(false);
      setIsLoading(false);
      displayErrMsg("Something went wrong!");
      return false;
    }
  };

  const postJobFunction = async (formattedData) => {
    let endpoint;
    if (!userRoleType.includes("ROLE_INSTITUTION_ADMIN"))
      endpoint = `openstaff/jobs/${empDetailId}/postJob`;
    else endpoint = "institutions/jobs";
    try {
      const registerResponse = await apiCall(
        endpoint,
        "POST",
        formattedData,
        TOKEN
      );
      console.log("registerResponse=====", registerResponse);
      if (registerResponse?.status === 200) {
        setRegisterResponseData(registerResponse.data);
        // setErrSuccessMsg({
        //   message: "Job posted successfully!",
        //   status: true,
        // });
        // handleHideJobType(true); //to display the single/multiday job tab
        const ConfirmationBackDrop = document.getElementById("modalBackdrop");
        const ConfirmationPopupModal = document.getElementById(
          "post_job_confirmation_popup"
        );
        if (ConfirmationPopupModal) {
          ConfirmationBackDrop.classList.add("show");
          ConfirmationPopupModal.classList.add("show");
          ConfirmationPopupModal.style.display = "block";
        }
        displaySuccessMsg();
        setIsLoading(false);
        getCurrentClockinClockoutTime();
        return true;
      } else if (registerResponse?.response?.status === 400) {
        displayErrMsg(registerResponse?.response?.data?.errorMessage);
        setIsLoading(false);
        return false;
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(registerResponse?.response?.data?.message)
        setIsLoading(false);
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      displayErrMsg("Something went wrong!");
      console.error("Error:", error);
      return false;
    }
  };

 
const getUtcOffset = () => {
  // You can calculate or get the UTC offset here, for example:
  return moment().utcOffset();
};

  const handleJobSubmit = async (event) => {
    event.preventDefault();
    // let paymentMethodResponse;

    // if(userRoleType === "ROLE_INSTITUTION_ADMIN"){
    //   console.log("checkPaymentMethod--",role_type)
    //    paymentMethodResponse = await checkPaymentMethod();
    // }else{
    //   paymentMethodResponse = empDetailsInfo
    // }
    // console.log("paymentMethodResponse",role_type === "Single",paymentMethodResponse)
    // if (paymentMethodResponse?.id && validateForm(paymentMethodResponse)) {
      if (validateForm()) {
      setIsLoading(true);
      const utcOffset = getUtcOffset();

      let strtDate, strtTime, endDate, endTime, startDateTime, endDateTime;
  
      if (role_type === "Single") {
        strtDate = moment(formValues.date).format("YYYY-MM-DD");
        endDate = moment(formValues.date).format("YYYY-MM-DD");
        strtTime = moment(formValues.timeIn, "hh:mm a").format("HH:mm");
        endTime = moment(formValues.timeOut, "hh:mm a").format("HH:mm");
        startDateTime = moment(`${strtDate} ${strtTime}`, "YYYY-MM-DD HH:mm:ss").toDate();
        endDateTime = moment(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm:ss").toDate();
      } else {
        strtDate = moment(formValues.startDate).format("YYYY-MM-DD");
        endDate = moment(formValues.endDate).format("YYYY-MM-DD");
        strtTime = "00:00:00";
        endTime = "23:59:59";
        startDateTime = moment.utc(`${strtDate} ${strtTime}`, "YYYY-MM-DD HH:mm:ss").toDate();
        endDateTime = moment.utc(`${endDate} ${endTime}`, "YYYY-MM-DD HH:mm:ss").toDate();
      }
  
      console.log("start date time-------",strtDate, endDate, strtTime, endTime)
      // Print formatted dates for debugging
      console.log("startDateTime:", startDateTime.toISOString());
      console.log("endDateTime:", endDateTime.toISOString());
  
      let fnlStartDate = moment.utc(moment(startDateTime)).format('YYYY-MM-DD HH:mm:ss');
      let fnlEndDate = moment.utc(moment(endDateTime)).format('YYYY-MM-DD HH:mm:ss');
  
      console.log("fnlStartDate:", fnlStartDate);
      console.log("fnlEndDate:", fnlEndDate);
  
      // let strtTimeVal = new Date();
      // let strtTimeMul = moment(strtTimeVal).format("hh:mm a");

      // let endTimeVal = new Date();
      // let endTimeMul = moment(endTimeVal).format("hh:mm a");
      // let strtDate =
      //   role_type != "Single"
      //     ? moment(formValues.startDate).format("YYYY-MM-DD")
      //     : moment(formValues.date).format("YYYY-MM-DD");
      // console.log(strtDate);
      // let strtTime =
      //   role_type != "Single"
      //     ? strtTimeMul
      //     : moment(formValues.timeIn, "hh:mm a").format("hh:mm a");
      // console.log(strtTime);

      // let endDate =
      //   role_type != "Single"
      //     ? moment(formValues.endDate).format("YYYY-MM-DD")
      //     : moment(formValues.date).format("YYYY-MM-DD");
      // console.log(endDate);

      // let endTime =
      //   role_type != "Single"
      //     ? endTimeMul
      //     : moment(formValues.timeOut, "hh:mm a").format("hh:mm a");

      // let startDateObj;
      // let endDateObj;
      // console.log(endDateObj);
      // let fnlStartDate;
      // let fnlEndDate;

      // if( role_type != "Single"){
      //   startDateObj = moment(
      //     strtDate + " " + "00:00",
      //     "YYYY-MM-DD hh:mm a"
      //   );
      //   endDateObj = moment(endDate + " " + "23:59:59", "YYYY-MM-DD hh:mm a");
      //    fnlStartDate =
      //    moment(startDateObj)
      //   .format("yyyy-MM-DD HH:mm:ss");
      //  fnlEndDate = moment(endDateObj)
      //   .format("yyyy-MM-DD HH:mm:ss");
      // }else{
      //   startDateObj = moment(
      //     strtDate + " " + strtTime,
      //     "YYYY-MM-DD hh:mm a"
      //   );
      //   endDateObj = moment(endDate + " " + endTime , "YYYY-MM-DD hh:mm a");
      //    fnlStartDate = moment
      //   .utc(moment(startDateObj))
      //   .format("yyyy-MM-DD HH:mm:ss");
      // console.log(fnlStartDate);
      //  fnlEndDate = moment
      //   .utc(moment(endDateObj))
      //   .format("yyyy-MM-DD HH:mm:ss");
      // }
     
      // console.log(fnlEndDate);

      const formattedData = {
        jobPosition: formValues.profession,
        jobTitle: formValues.jobTitle,
        startTime: fnlStartDate,
        endTime: fnlEndDate,
        hoursPerDay: "0",
        contactPerson: formValues.contactPerson,
        contactPhone: formValues.phoneNumber,
        workHours: role_type === "Single" ? formValues.totalWorkTime : formValues.workHoursMulti,
        insLocationId:
          userRoleType.includes("ROLE_INSTITUTION_ADMIN")
            ? selectedItemPostId
            : selectedItemId,
        multipleDayJob: role_type != "Single" ? true : false,
        // paymentIntentId: "",
        firstName: "",
        lastName: "",
        salary: (formValues?.hourlyRate * 100)?.toFixed(),
        notes: formValues.notes,
        isPrivateJob: formValues?.privateJob,
        isEmployeeOnly : formValues?.accessType === "employeesOnly" || formValues?.accessType === "both",
        isTrustedWorkerOnly : formValues?.accessType === "trustedWorkersOnly" || formValues?.accessType === "both",
        actualStartTime: fnlStartDate,
        actualEndTime: fnlEndDate
      };

      if(formValues?.privateJob &&  formValues?.accessType === "employeesOnly"){
        formattedData.salary = 0
      }


        formattedData.noOfCandidates =  formValues?.noOfCandidates;
      
        
      selectedFields.forEach((field) => {
        formattedData[field.mapField] = formValues[field.mapField];
      });

      if (userRoleType.includes("ROLE_INSTITUTION_ADMIN")) {
        let dataObject = {
          startTime: fnlStartDate,
          endTime: fnlEndDate,
          workHours: role_type === "Single" ? formValues.totalWorkTime : formValues.workHoursMulti,
          hourlyWage: (formValues.hourlyRate * 100)?.toFixed()?.toString(),
          multipleDayJob: role_type === "Multiple" ? true : false,
        };

        if (role_type === "Multiple") {
          dataObject.hoursPerDay = "0";
        }

        console.log("formattedData---",formattedData,formattedData?.isPrivateJob)

        setPostJobReqParam(formattedData);
        if(formattedData?.isPrivateJob){
          await postJobFunction(formattedData);
        }else{
          await computeCharges(dataObject);
        }
      }

      if (!userRoleType.includes("ROLE_INSTITUTION_ADMIN")) {
        await postJobFunction(formattedData);
      }
    } else {
      setIsLoading(false);
      displayErrMsg("Check the form validation!");
      console.log("Form validation failed.");
    }
  };

  const getPositionDropdownList = async () => {
    try {
      setIsLoadingPositionList(true);
      const positionResponse = await apiCall("config/allJobTypes", "GET", null);
      if (
        positionResponse?.status === 200 &&
        positionResponse?.data?.length > 0
      ) {
        setIsLoadingPositionList(false);
        setPositionDropdownList(positionResponse?.data);
      }else if( positionResponse?.data?.length === 0){
        setIsLoadingPositionList(false);
        setPositionDropdownList([])
      } else {
        setIsLoadingPositionList(false);
        setPositionDropdownList([])
        displayErrMsg("Somthing went wrong!");
      }
    } catch (err) {
      setIsLoadingPositionList(false);
      displayErrMsg("Somthing went wrong!");
    }
  };

  useEffect(() => {
    getPositionDropdownList();
  }, []);
  const dropdownIconBoxStyle = {
    position: "relative",
  };
  // const handleJobPostSuccess = () => {
  //   setIsPopupVisible(true);
  // };

  // const closePopup = () => {
  //   setIsPopupVisible(false);
  // };

  const dropdownIconStyle = {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
  };

  const dropdownPaddingRight = {
    paddingRight: "30px", // Adjust the padding to make room for the icon
  };

  const PrivateJobSection = () => {
    return (
      <div className="tw-faq-section">
      <div className="accordion tw-faq" id="sf-faq-accordion-2">
          <div className="accordion-item" key={1}>
            <button
              className={`accordion-button ${isAccordionOpen ? '' : 'collapsed'}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#advancedFeature`}
              aria-expanded={isAccordionOpen ? 'true' : 'false'}
              // disabled={!(userInfo?.manageTeam || empDetailsInfo?.manageTeam)}
              onClick={handleAccordionToggle}
            >
              Advanced Options 
             {!(userInfo?.manageTeam || empDetailsInfo?.manageTeam) && <span style={{marginLeft: 5}} title="This section is currently disabled for your account. Please contact an admin to enable Advanced Options." data-bs-toggle="tooltip" data-bs-placement="top"><i className="fa fa-info-circle" ></i></span>} 
            </button>
            <div
              id={`advancedFeature`}
              className={`accordion-collapse collapse ${isAccordionOpen ? 'show' : ''}`}
              data-bs-parent="#sf-faq-accordion-2"
            >
              <div className="accordion-body">
                 {/* Private Job Checkbox */}
                    {/* {
                    (userInfo?.manageTeam || empDetailsInfo?.manageTeam) && 
                    <> */}
                    <div className="col-xl-4 col-lg-4 col-md-12">
                      <div className="form-group">
                        <label className="custom-checkbox" style={{ fontSize : '16px' }}>
                          <input
                            type="checkbox"
                            name="privateJob"
                            checked={formValues.privateJob}
                            onChange={(e) => handleChange(e, "privateJob")}
                            disabled={!(userInfo?.manageTeam || empDetailsInfo?.manageTeam)}
                          />
                          Private Job
                        </label>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12">
                      <div className="form-group">
                        {/* <label>Access Type</label> */}
                        <div className="checkbox-group">
                          <label  className="custom-checkbox" style={{ fontSize : '16px' }}>
                            <input
                              type="checkbox"
                              name="employeesOnly"
                              checked={formValues.accessType === "employeesOnly" || formValues.accessType === "both"}
                              disabled={!formValues.privateJob}
                              onChange={(e) => handleAccessTypeChange(e, "employeesOnly")}
                            />
                            Employees
                          </label>
                        </div>
                        {formErrors.accessType && <div className="error-message">{formErrors.accessType}</div>}
                      </div>
                      </div>
                    <div className="col-xl-4 col-lg-4 col-md-12">
                      <div className="form-group">
                        {/* <label>Access Type</label> */}
                        <div className="checkbox-group">
                          <label className="custom-checkbox" style={{ fontSize : '16px' }}>
                            <input
                              type="checkbox"
                              name="trustedWorkersOnly"
                              checked={formValues.accessType === "trustedWorkersOnly" || formValues.accessType === "both"}
                              disabled={!formValues.privateJob}
                              onChange={(e) => handleAccessTypeChange(e, "trustedWorkersOnly")}
                            />
                            Trusted Workers
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* {
                    (userInfo?.manageTeam || empDetailsInfo?.manageTeam) &&  */}
                    <div className="col-xl-12 col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>Number of Hires</label>
                      <div className="ls-inputicon-box">
                        <input
                          className="form-control"
                          name="noOfCandidates"
                          type="text"
                          ref={inputRef}
                          placeholder="Number of Hires"
                          value={formValues.noOfCandidates}
                          style={{backgroundColor: '#fff'}}
                          onChange={(e) => handleChange(e, "noOfCandidates")}
                          disabled={!(userInfo?.manageTeam || empDetailsInfo?.manageTeam)}
                        />
                        <i className="fs-input-icon fas fa-user" />
                      </div>
                      {formErrors.noOfCandidates && (
                        <span className="error-message">
                          {formErrors.noOfCandidates}
                        </span>
                      )}
                    </div>
                    </div>
                    {/* } */}
              </div>
            </div>
         </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {!showPaymentScreen ? (
        <form id="jobseeker_form">
          <div className="panel panel-default">
            <div className="panel-body wt-panel-body p-a20 m-b30 ">
              <div className="row">
                {userRoleType.includes("ROLE_INSTITUTION_ADMIN") && (
                  <div className="col-xl-12 col-lg-12 col-md-12">
                    <div className="form-group">
                      <label>
                        Choose location at which you want to post job
                      </label>
                      {/* <div style={dropdownIconBoxStyle}> */}
                      {!locationLoader ? (
                        empLocationInfo?.length > 0 && (
                          <select
                            className="wt-select-bar-large selectpicker form-control"
                            data-live-search="true"
                            data-bv-field="size"
                            value={formValues?.selectedLocationId}
                            onChange={handleLocationChange}
                          >
                            <option value="">Select Location</option>
                            {empLocationInfo.map((location) => (
                              <option key={location.id} value={location.id}>
                                {location?.locationName} {location?.clientName ? `(${location?.clientName})` : ''}
                              </option>
                            ))}
                          </select>
                        )
                      ) : (
                        <TableLoader />
                      )}

                      {/* <i
                          className="fs-input-icon fa fa-chevron-down"
                          style={dropdownIconStyle}
                        />
                      </div> */}
                      {formErrors.selectedLocation && (
                        <span className="error-message">
                          {formErrors.selectedLocation}
                        </span>
                      )}
                    </div>
                  </div>
                )}
                {/* <div className="col-xl-12 col-lg-12 col-md-12">
              {positionDropdownList?.length > 0 && (
                <div className="form-group" id="jobPosition">
                  <label>Job Position</label>
                  <div
                    className="ls-inputicon-box"
                    style={{ width: "100% !important" }}
                  >
                    <select
                      className="wt-select-bar-2 selectpicker"
                      // className="wt-select-bar-2 selectpicker"
                      data-live-search="true"
                      data-bv-field="size"
                      name="profession"
                      id="profession"
                      value={formValues.profession || ""}
                      onChange={handlePositionchange}
                      style={{
                        width: "100% !important",
                      }}
                    >
                      <option value="">Select Job Type</option>
                      {positionDropdownList.map((position) => (
                        <option
                          key={position.typeName}
                          value={position.typeName}
                        >
                          {position.typeName}
                        </option>
                      ))}
                    </select>
                    {/* <i className="fs-input-icon fas fa-map-marker-alt" /> */}
                {/* </div>

                  {formErrors.profession && (
                    <span className="error-message">
                      {formErrors.profession}
                    </span>
                  )}
                </div>
              )}
            </div> */}
                <div className="col-xl-12 col-lg-12 col-md-12">
                  <div className="form-group ">
                    <label>Job Position</label>
                    <div style={dropdownIconBoxStyle}>
                      <select
                        className="form-control"
                        name="profession"
                        id="profession"
                        style={dropdownPaddingRight}
                        value={formValues.profession || ""}
                        onChange={handlePositionchange}
                        // onChange={handleLocationChange}
                      >
                        <option value="">Select Job Type</option>
                        {positionDropdownList?.map((position) => (
                          <option
                            key={position.typeName}
                            value={position.typeName}
                          >
                            {position.typeName}
                          </option>
                        ))}
                      </select>
                      <i
                        className="fs-input-icon fa fa-chevron-down"
                        style={dropdownIconStyle}
                      />
                    </div>
                    {formErrors.profession && (
                      <span className="error-message">
                        {formErrors.profession}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div
                    className="form-group city-outer-bx has-feedback"
                    id="jobTitle"
                  >
                    <label>Job Title</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="jobTitle"
                        type="text"
                        placeholder="Job Title"
                        value={formValues.jobTitle}
                        onChange={(e) => handleChange(e, "jobTitle")}
                      />
                      {/* <i className="fs-input-icon fa fa-globe-americas" /> */}
                    </div>
                    {formErrors.jobTitle && (
                      <span className="error-message">
                        {formErrors.jobTitle}
                      </span>
                    )}
                  </div>
                </div>
                {console.log("formValues?.accessType,",formValues?.accessType)}
                {
                  formValues?.accessType !== "employeesOnly" ?
                  <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Hourly Rate</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="hourlyRate"
                        type="text"
                        placeholder="Hourly Rate"
                        value={formValues.hourlyRate}
                        maxLength={12}
                        onChange={(e) => handleChange(e, "hourlyRate")}
                      />
                      {/* <i className="fs-input-icon fa fa-phone-alt" /> */}
                    </div>
                    {formErrors.hourlyRate && (
                      <span className="error-message">
                        {formErrors.hourlyRate}
                      </span>
                    )}
                  </div>
                </div>
                  :
                <div className="col-xl-6 col-lg-6 col-md-12"></div>
                }
              
                {selectedFields?.length > 0 &&
                  selectedFields.map((field) => (
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div key={field.mapField} className="form-group">
                        <label>{field.title}</label>
                        <div className="ls-inputicon-box">
                          <input
                            type="text"
                            className="form-control"
                            name={field.mapField}
                            value={formValues[field.mapField] || ""}
                            onChange={(e) => handleChange(e, field.mapField)}
                            required={field.required}
                          />
                          {formErrors[field.mapField] && (
                            <span className="error-message">
                              {formErrors[field.mapField]}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                {/* {role_type == "Single" && (
                  <>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Time in</label>
                        <div className="ls-inputicon-box">
                          <input
                            className="form-control"
                            name="timeIn"
                            type="time"
                            value={formValues.timeIn}
                            onChange={(e) => handleChange(e, "timeIn")}
                          />
                          <i className="fs-input-icon fa fa-clock" />{" "}
                         
                        </div>
                        {formErrors.timeIn && (
                          <span className="error-message">
                            {formErrors.timeIn}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Time Out</label>
                        <div className="ls-inputicon-box">
                          <input
                            className="form-control"
                            name="timeIn"
                            type="time"
                            value={formValues.timeOut}
                            onChange={(e) => handleChange(e, "timeOut")}
                          />
                          <i className="fs-input-icon fa fa-clock" />
                        </div>
                        {formErrors.timeOut && (
                          <span className="error-message">
                            {formErrors.timeOut}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )} */}

                {role_type == "Single" && (
                  <div className="col-xl-4 col-lg-4 col-md-12">
                    <div className="form-group">
                      <label>Date</label>
                      <div className="ls-inputicon-box">
                        {/* <input
                      className="form-control"
                      name="dob"
                      type="date"
                      placeholder="MM/DD/YYYY"
                      value={formValues.dob}
                      onChange={(e) => handleInputChange(e, "dob")}
                    /> */}
                        <ReactDatePicker
                          className="form-control"
                          name="date"
                          id="date"
                          selected={formValues.date}
                          onChange={(date) =>
                            handleDatePickerChange(date, "date")
                          }
                          dateFormat="MM-dd-yyyy"
                          placeholderText="Date"
                          minDate={new Date()}
                          // maxDate={min18Date}
                        />
                        <i className="fs-input-icon fa fa-user-graduate" />
                      </div>
                      {formErrors.date && (
                        <span className="error-message">{formErrors.date}</span>
                      )}
                    </div>
                        </div>
                      )}

                {console.log("role_type-----", role_type, formValues)}
                {role_type === "Single" && (
                  <>
                    {formValues?.timeIn != "" && (
                      <div className="col-xl-2 col-lg-2 col-md-12">
                        {console.log("formValues.timeIn", formValues?.timeIn)}

                        <div className="form-group">
                          <label>Start Time</label>
                          <div className="ls-inputicon-box">
                            <ReactDatePicker
                              className="form-control"
                              selected={formValues.timeIn}
                              onChange={(date) =>
                                handleTimeChange(date, "timeIn")
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15} // 15-minute intervals
                              timeCaption="Time"
                              dateFormat="h:mm aa" // AM/PM format
                              placeholderText="Select time"
                            />
                            <i className="fs-input-icon fa fa-clock" />
                          </div>
                          {formErrors.timeIn && (
                            <span className="error-message">
                              {formErrors.timeIn}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {formValues?.timeOut != "" && (
                      <div className="col-xl-2 col-lg-2 col-md-12">
                        {console.log("formValues.timeIn", formValues?.timeOut)}
                        <div className="form-group">
                          <label>End Time</label>
                          <div className="ls-inputicon-box">
                            <ReactDatePicker
                              className="form-control"
                              selected={formValues?.timeOut}
                              onChange={(date) =>
                                handleTimeChange(date, "timeOut")
                              }
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15} // 15-minute intervals
                              timeCaption="Time"
                              dateFormat="h:mm aa" // AM/PM format
                              placeholderText="Select time"
                            />
                            <i className="fs-input-icon fa fa-clock" />
                          </div>
                          {formErrors.timeOut && (
                            <span className="error-message">
                              {formErrors.timeOut}
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    <div className="col-xl-2 col-lg-2 col-md-5">
                      <div className="form-group">
                        <label>Hours</label>
                        <div className="ls-inputicon-box">
                          <input
                            className="form-control"
                            name="workHours"
                            type="number"
                            placeholder="Hours"
                            // defaultValue={1}
                            value={formValues.workHours}
                            maxLength={12}
                            onChange={(e) => {
                              handleChange(e, "workHours")
                            }
                            }
                          />
                          {/* <i className="fs-input-icon fa fa-phone-alt" /> */}
                        </div>
                        {formErrors.workHours && (
                          <span className="error-message">
                            {formErrors.workHours}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 col-md-12">
                      <div className="form-group">
                        <label>Minutes</label>
                        <div className="ls-inputicon-box">
                          <select
                            className="form-control"
                            name="workFraction"
                            value={formValues.workFraction}
                            onChange={(e) => {
                              handleChange(e, "workFraction")
                            }
                            }
                          >
                            <option value="00">00</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                          </select>
                        </div>
                        {formErrors.workFraction && (
                          <span className="error-message">{formErrors.workFraction}</span>
                        )}
                      </div>
                    </div>
                  </>
                )}            

                {/* {formValues.privateJob && ( */}
                  {/* <div className="col-xl-6 col-lg-6 col-md-12">
                    <div className="form-group">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          name="accessType"
                          id="flexSwitchCheckChecked"
                          checked={formValues.accessType !== "employeesOnly"}
                          onChange={(e) => {
                            handleChange(e, "accessType");
                          }}
                          disabled={!formValues.privateJob}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
                          {formValues.accessType === "employeesOnly" ? "Employees Only" : "Trusted Workers Only"}
                        </label>
                      </div>
                    </div>
                  </div> */}
                {/* )} */}

              


                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label>Contact Person</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="contactPerson"
                        type="text"
                        placeholder="Contact Person"
                        value={formValues.contactPerson}
                        onChange={(e) => handleChange(e, "contactPerson")}
                      />
                      <i className="fs-input-icon fas fa-user" />
                    </div>
                    {formErrors.contactPerson && (
                      <span className="error-message">
                        {formErrors.contactPerson}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12">
                  <div className="form-group">
                    <label> Contact Person Phone</label>
                    <div className="ls-inputicon-box">
                      <input
                        className="form-control"
                        name="phoneNumber"
                        type="text"
                        placeholder="Phone Number"
                        value={formValues.phoneNumber}
                        maxLength={12}
                        onChange={(e) => handleChange(e, "phoneNumber")}
                      />
                      <i className="fs-input-icon fa fa-phone-alt" />
                    </div>
                    {formErrors.phoneNumber && (
                      <span className="error-message">
                        {formErrors.phoneNumber}
                      </span>
                    )}
                  </div>
                </div>

               
                {role_type == "Multiple" && (
                  <>
                    <div className="col-xl-3 col-lg-3 col-md-12">
                      <div className="form-group">
                        <label> Start Date</label>
                        <div className="ls-inputicon-box">
                          <ReactDatePicker
                            className="form-control"
                            name="startDate"
                            id="startDate"
                            selected={formValues.startDate}
                            onChange={(date) =>
                              handleDatePickerChange(date, "startDate")
                            }
                            dateFormat="MM-dd-yyyy"
                            placeholderText="Start Date"
                            minDate={new Date()}
                            // maxDate={min18Date}
                          />
                          <i className="fs-input-icon fas fa-calendar-alt" />
                        </div>
                        {formErrors.startDate && (
                          <span className="error-message">
                            {formErrors.startDate}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-12">
                      <div className="form-group">
                        <label> End Date</label>
                        <div className="ls-inputicon-box">
                          <ReactDatePicker
                            className="form-control"
                            name="endDate"
                            id="endDate"
                            selected={formValues.endDate}
                            onChange={(date) =>
                              handleDatePickerChange(date, "endDate")
                            }
                            dateFormat="MM-dd-yyyy"
                            placeholderText=" End Date"
                            minDate={formValues.startDate || new Date()} 
                            maxDate={
                              userInfo?.paymentMethod === 'stripe' || empDetailsInfo?.paymentMethod === 'stripe'
                                ? formValues?.maxEndDate
                                : null // No max date restriction if not stripe
                            }
                            // maxDate={min18Date}
                          />
                          <i className="fs-input-icon fas fa-calendar-alt" />
                        </div>
                        {formErrors.endDate && (
                          <span className="error-message">
                            {formErrors.endDate}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-md-12">
                      <div className="form-group">
                        <label>Work Time per Day</label>
                        <div className="ls-inputicon-box">
                          <input
                            className="form-control"
                            name="hourPerDay"
                            type="text"
                            placeholder="Work Time per Day"
                            value={formValues.hourPerDay}
                            maxLength={12}
                            onChange={(e) => handleChange(e, "hourPerDay")}
                          />
                          <i className="fs-input-icon fa fa-clock" />
                        </div>
                        {formErrors.hourPerDay && (
                          <span className="error-message">
                            {formErrors.hourPerDay}
                          </span>
                        )}
                      </div>
                    </div> */}
                    <div className="col-xl-3 col-lg-3 col-md-12">
                      <div className="form-group">
                        <label>Hours (Daily)</label>
                        <div className="ls-inputicon-box">
                          <input
                            className="form-control"
                            name="workHours"
                            type="number"
                            placeholder="Hours"
                            value={formValues.workHours}
                            maxLength={12}
                            onChange={(e) => {
                              handleChange(e, "workHours")
                            }
                            }
                          />
                          {/* <i className="fs-input-icon fa fa-phone-alt" /> */}
                        </div>
                        {formErrors.workHours && (
                          <span className="error-message">
                            {formErrors.workHours}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-12">
                      <div className="form-group">
                        <label>Minutes (Daily)</label>
                        <div className="ls-inputicon-box">
                          <select
                            className="form-control"
                            name="workFraction"
                            value={formValues.workFraction}
                            onChange={(e) => {
                              handleChange(e, "workFraction")
                            }
                            }
                          >
                            <option value="00">00</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                          </select>
                        </div>
                        {formErrors.workFraction && (
                          <span className="error-message">{formErrors.workFraction}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Notes</label>
                        <div className="ls-inputicon-box">
                          <textarea
                            style={{
                              width: "100%",
                              padding: "20px",
                              border: "none",
                              height: "100px",
                              borderRadius: "10px",
                              backgroundColor: "#f0f6fe",
                              resize: "vertical",
                            }}
                            className="form-control"
                            name="notes"
                            placeholder="Notes"
                            value={formValues.notes}
                            maxLength={255}
                            onChange={(e) => handleChange(e, "notes")}
                          />
                          {/* <i className="fs-input-icon fa fa-sticky-note" /> */}
                        </div>
                        {formErrors.notes && (
                          <span className="error-message">
                            {formErrors.notes}
                          </span>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {
                  (userInfo?.manageTeam || empDetailsInfo?.manageTeam) && (userRoleType.includes("ROLE_OPENSTAFF_ADMIN") || userRoleType.includes("ROLE_INSTITUTION_POSTER_ADMIN")) && process.env.REACT_APP_ENABLED_ADDITIONAL_OPTION === 'true' ?
                    <PrivateJobSection />
                    :
                    userRoleType.includes("ROLE_INSTITUTION_ADMIN") && process.env.REACT_APP_ENABLED_ADDITIONAL_OPTION === 'true' && <PrivateJobSection />
                }
                             

                <div className="col-lg-12 col-md-12">
                  <div className="text-left">
                    <button
                      type="submit"
                      className="site-button"
                      onClick={handleJobSubmit}
                    >
                      {isLoading ? <Spinner /> : "Post Job"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      ) : (
        isACHEnalbed ?
        userInfo?.enabledACH ?
        <ACHPaymentScreen 
          computeChargesDetails={computeChargesDetails}
          setShowPaymentScreen={setShowPaymentScreen}
          postJobFunction={postJobFunction}
          postJobReqParam={postJobReqParam}
          handleHideJobType={handleHideJobType}
        />
        :
        <CardPaymentScreen
          computeChargesDetails={computeChargesDetails}
          setShowPaymentScreen={setShowPaymentScreen}
          postJobFunction={postJobFunction}
          postJobReqParam={postJobReqParam}
          handleHideJobType={handleHideJobType}
          formValues={formValues}
        />
        : 
          <CardPaymentScreen
          computeChargesDetails={computeChargesDetails}
          setShowPaymentScreen={setShowPaymentScreen}
          postJobFunction={postJobFunction}
          postJobReqParam={postJobReqParam}
          handleHideJobType={handleHideJobType}
          formValues={formValues}
        />
      )}
      <ConfirmationPostJobPopUp registerResponseData={registerResponseData}  handleHideJobType={handleHideJobType} setShowPaymentScreen={setShowPaymentScreen}/>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message !== "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default JobPostForm;
