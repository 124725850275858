import React from "react";

const CanSearchKey = ({
    searchKey,
    handleSearchKeyChange,
    handleSearchClick,
    clearSearch
}) => {
  return (
    <>
      <div className="input-group my-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search By Name"
          id="paymemploent-searchkey"
          value={searchKey}
          onChange={(e) => handleSearchKeyChange(e)}
          onKeyPress={handleSearchClick}
        />
        {searchKey && (
          <button
            type="button"
            className="btn"
            onClick={clearSearch}
            style={{
              position: "absolute",
              right: "40px",
              top: 14,
              zIndex: 2,
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <i className="fas fa-times" />
          </button>
        )}
        <button
          type="button"
          className="btn"
          style={{
            position: "absolute",
            right: "10px",
            top: 14,
            zIndex: 2,
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          <i className="feather-search" onClick={handleSearchClick} />
        </button>
      </div>
    </>
  );
};

export default CanSearchKey;
