import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../../../../../apicall";
import SectionRecordsFilter from "../../../../public-user/sections/common/section-records-filter";
import SectionApplicantsList from "../applicant-list";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../../globals/fomat-comapny-name";
import SectionJobsSidebar1 from "../../../../public-user/sections/jobs/sidebar/section-jobs-sidebar1";
import ManageTeamTabs from "../manage-team-tab";
import Toast from "../../../../../common/toast";
import PrivateApplicantRegister from "./private-applicant-register";

const EmployeesPage = () => {
  const { role_type, TOKEN } = useSelector((state) => state.menu);
  const [tableFilter, setTableFilter] = useState({
    sortType: "createdDate",
    sortDirection: "desc",
    searchKey: "",
  });
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [applicantsListData, setApplicantsListData] = useState([]);
  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setApplicantsListData([]);
    setPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const fetchapplicantsList = async () => {
    try {
      let data = null;
      console.log("fetchempList-----", role_type, tableFilter?.searchKey);

      const api = `${`institutions/employees`}?sortBy=${
        tableFilter?.sortType
      }&sortDirection=${tableFilter?.sortDirection}&offset=0&page=${
        currentPage - 1
      }&size=${rowPerPage}&searchKey=${tableFilter?.searchKey}`;

      setIsLoading(true);
      const result = await apiCall(api, "GET", data, TOKEN);
      if (result?.data?.content?.length > 0) {
        setSessionExpired(false);
        setApplicantsListData(result.data.content);
        setPaginationData({
          totalElements: result.data.totalElements,
          totalPages: result.data.totalPages,
        });
      } else if (result?.data?.content?.length === 0 && result?.status == 200) {
        setSessionExpired(false);
        // displayErrMsg("No results found.!");
        setApplicantsListData([]);
        setPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
      } else if (result?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(result?.response?.data?.message);
      }
      console.log("user list result", result);
    } catch (error) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchapplicantsList();
  }, [currentPage, tableFilter, rowPerPage]);

  return (
    <>
      <div>
        <Helmet>
          <title>{`Manage Team | ${formatCompanyName()}`}</title>
          <meta
            name="description"
            content="Welcome to the Manage Team Page! Here, The Manage Team page allows administrators to register new employees and add them in the existing list."
          />
          <meta
            name="keywords"
            content="team management, team structure, team overview, manage team"
          />
        </Helmet>
      </div>
      <div className="section-full p-t120  p-b90 site-bg-white mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionJobsSidebar1
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="twm-sign-up-screen">
                <div className="sign-up-container">
                  <form>
                    <div className="body">
                      <div className="twm-tabs-style-2">
                        <ManageTeamTabs />
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="sign-employees"
                          >
                            <div className="section-full site-bg-white">
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                    <div class="panel-heading wt-panel-heading p-a20 mb-4 d-flex justify-content-between align-items-center">
                                      <h3 class="wt-title">Employees</h3>
                                      <a
                                        className="site-button"
                                        data-bs-toggle="modal"
                                        href={"#add_applicant_popup"}
                                        role="button"
                                      >
                                        Add
                                      </a>
                                    </div>
                                    <SectionRecordsFilter
                                      _config={_filterConfig}
                                      rowPerPage={rowPerPage}
                                      handleRecordsPerPageChange={
                                        handleRecordsPerPageChange
                                      }
                                      setTableFilter={setTableFilter}
                                      tableFilter={tableFilter}
                                      setCurrentPage={setCurrentPage}
                                      currentPage={currentPage}
                                    />
                                    <SectionApplicantsList
                                      isLoading={isLoading}
                                      applicantsListData={applicantsListData}
                                      currentPage={currentPage}
                                      setCurrentPage={setCurrentPage}
                                      totalPages={paginationData.totalPages}
                                    />
                                  </div>
                                </div>
                              </div>
                              <PrivateApplicantRegister
                                fetchapplicantsList={fetchapplicantsList}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default EmployeesPage;
