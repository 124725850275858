import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ManageTeamTabs = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;

  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className={
            currentUrl === "/manage-team-employee" ||
            currentUrl === "/manage-team-employee/"
              ? "nav-link active"
              : "nav-link"
          }
          data-bs-toggle="tab"
          data-bs-target="#sign-employees"
          type="button"
          onClick={() => navigate("/manage-team-employee")}
        >
          <i className="fas fa-user-tie" /> Employees
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={
            currentUrl === "/manage-team-trusted-worker" ||
            currentUrl === "/manage-team-trusted-worker/"
              ? "nav-link active"
              : "nav-link"
          }
          data-bs-toggle="tab"
          data-bs-target="#sign-trusted-workers"
          type="button"
          onClick={() => navigate("/manage-team-trusted-worker")}
        >
          <i className="fa fa-shield-alt" /> Trusted Workers
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className={
            currentUrl === "/manage-team-trusted-companies" ||
            currentUrl === "/manage-team-trusted-companies/"
              ? "nav-link active"
              : "nav-link"
          }
          data-bs-toggle="tab"
          data-bs-target="#sign-trusted-companies"
          type="button"
          onClick={() => navigate("/manage-team-trusted-companies")}
        >
          <i className="fa fa-handshake" /> Trusted Companies
        </button>
      </li>
    </ul>
  );
};

export default ManageTeamTabs;
