import { NavLink, useLocation } from "react-router-dom";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import SectionPagination from "../../../public-user/sections/common/section-pagination";
import moment from "moment";
import GetProfileImage from "../../../../common/getProfileImage";
import TableLoader from "../../../../common/tableLoader";
import apiCall from "../../../../../apicall";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";

function SectionApplicantsList({
  isLoading,
  applicantsListData,
  currentPage,
  setCurrentPage,
  totalPages,
  isHiredSeekerList,
  fetchTrustedWorkersList,
  fetchHiredJobSeeker,
  trustedWorker,
  fetchTrustedCompanyList
}) {
  console.log("applicantsListData", applicantsListData);
  const { TOKEN } = useSelector((state) => state.menu);
  const [isAddTrustedWorkerLoading, setIsAddTrustedWorkerLoading] = useState(null);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const location = useLocation();
  const currentUrl = location.pathname;

  const StarRating = ({ rating }) => {
    const stars = [];
    console.log("rating----------", rating);
    for (let i = 0; i < 5; i++) {
      if (rating >= i + 1) {
        stars.push(
          <i key={i} className="fas fa-star" style={{ color: "#128c5e" }} />
        );
      } else if (rating >= i + 0.5) {
        stars.push(
          <i
            key={i + 0.5}
            className="fas fa-star-half-alt"
            style={{ color: "#128c5e" }}
          />
        );
      } else {
        stars.push(
          <i key={i} className="far fa-star" style={{ color: "lightgrey" }} />
        );
      }
    }

    return <span>{stars}</span>;
  };

  const handleInviteTrustedWorker = async (applicantId, item) => {
    try {
      setIsAddTrustedWorkerLoading(applicantId);
      // const api = `institutions/addTrustedWorkers`;
      const api =
      currentUrl === "/manage-team-trusted-companies"
        ? "institutions/sendInviteTrustedCompany"
        : "institutions/sendInviteTrustedWorker";
      const method =
      currentUrl === "/manage-team-trusted-companies"
        ? "POST"
        : "PUT";
      const inviteTrustedWorkerResponse = await apiCall(
        api,
        method,
        applicantId,
        TOKEN,
        false,
        false,
        true
      );

      console.log("inviteTrustedWorkerResponse---", inviteTrustedWorkerResponse);
      if (inviteTrustedWorkerResponse?.status === 200) {
        setErrSuccessMsg({
          message: `${ currentUrl === "/manage-team-trusted-companies" ? item?.name : `${item?.firstName} ${item?.lastName}`} has been invited.`,
          status: true,
        });
        fetchHiredJobSeeker();
        if(currentUrl === "/manage-team-trusted-companies"){
          fetchTrustedCompanyList();
        }else{
          fetchTrustedWorkersList();
        }
      } else if (inviteTrustedWorkerResponse?.response?.status == 401) {
        setErrSuccessMsg({
          message: "Your session has been expired!!",
          status: false,
        });
      } else {
        setErrSuccessMsg({
          message: inviteTrustedWorkerResponse?.response?.data?.errorMessage,
          status: false,
        });
      }
    } catch (err) {
      console.log("errrrr",err)
      setErrSuccessMsg({
        message: "Network Error",
        status: false,
      });
    } finally {
      setIsAddTrustedWorkerLoading(null);
    }
  };

  return (
    <>
      <div className="twm-jobs-list-wrap">
        <ul>
          {!isLoading ? (
            applicantsListData?.length > 0 ? (
              applicantsListData?.map((item) => (
                <li key={item.id}>
                  <div className="twm-jobs-list-style1 mb-5">
                    <div className="twm-media">
                      <GetProfileImage id={isHiredSeekerList ? item?.userEntityId : item?.id} type={currentUrl === "/manage-team-trusted-companies" ? "jobList" : "userAvatar"} />
                    </div>
                    <div className="twm-mid-content">
                        <h4>
                          {currentUrl === "/manage-team-trusted-companies"
                          ? item?.name
                            : `${item?.firstName} ${item?.lastName}`}
                          {/* <span className="twm-job-post-duration">
                              / {item?.userName}
                            </span> */}
                        </h4>
                      <div>
                        <StarRating rating={item?.averageNumStars} />{" "}
                        <span style={{ fontSize: 12 }}>
                          {(item?.numberOfReview || 0) + " Reviews"}
                        </span>
                      </div>
                      {!isHiredSeekerList && currentUrl != "/manage-team-trusted-companies" ? (
                        <>
                          <span className="twm-job-address">{item?.email}</span>
                        </>
                      ) : (
                        <div style={{ marginTop: "60px"}}></div>
                      )}
                    </div>

                    <div className="twm-right-content">
                      {!isHiredSeekerList && currentUrl != "/manage-team-trusted-companies" ? (
                        <>
                          {item?.accountStatus && (<div className="twm-jobs-category green">
                            <span
                              className={
                                item?.accountStatus === "VERIFIED"
                                  ? "twm-bg-green"
                                  : "twm-bg-brown"
                              }
                            >
                              {item?.accountStatus}
                            </span>
                          </div>
                          )}

                          <div className="twm-jobs-amount">
                            {item?.roleEntities[0]?.roleType}
                          </div>
                        </>
                      ) : (
                        <div className="twm-jobs-category green">
                          <a
                            className="site-button"
                            // style={{display: 'inline-block', width: '3rem'}}
                            role="button"
                            onClick={() =>
                              handleInviteTrustedWorker(currentUrl === "/manage-team-trusted-companies" ? item?.id : item?.userEntityId, item)
                            }
                          >
                           {isAddTrustedWorkerLoading === (currentUrl === "/manage-team-trusted-companies" ? item?.id : item?.userEntityId) ? (
                              <Spinner />
                            ) : (
                              "Invite"
                            )}
                          </a>
                        </div>
                      )}
                      { item?.status === "PENDING" && (
                        <h4 style={{ color: "orange" }}>{item?.status}</h4>
                      )}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              "No results found."
            )
          ) : (
            <TableLoader />
          )}
        </ul>
      </div>
      <SectionPagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
      />
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default SectionApplicantsList;
