import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-autocomplete";
// import { GOOGLE_API } from "../../../../../config";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import SessionExpired from "../../../../common/sessionExpired";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

const mapContainerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: parseFloat(process.env.REACT_APP_DEFAULT_LATITUDE), 
  lng: parseFloat(process.env.REACT_APP_DEFAULT_LONGITUDE),
};



const AddLocation = ({
  empDetails,
  setLocationCurrentPage,
  refreshedLocationList,
}) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [locationDetails, setLocationDetails] = useState({
    locationName: "",
    address: "",
    city: "",
    country: "",
    state: "",
    zipcode: "",
    buildingNum: "",
    clientLocation: false,
    clientName: ""
  });
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [autocompleteKey, setAutocompleteKey] = useState(Date.now());
  const [autocompleteInput, setAutocompleteInput] = useState("");
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
    libraries: ["places"],
  });

  useEffect(()=>{
    logEvent_Analytics('add_location_web');
  },[])

  const handleAutocompleteInputChange = (e) => {
    setAutocompleteInput(e.target.value);
  };

  const handlePlaceSelected = (place) => {
    const addressComponents = place.address_components;
    console.log("place.address_components---------",addressComponents)
    setAutocompleteInput(place.formatted_address);
    const streetNumber = addressComponents.find(component =>
        component.types.includes("street_number")
    )?.long_name || "";

    console.log("location namemmm",streetNumber)


    const route = addressComponents.find(component =>
        component.types.includes("route")
    )?.long_name || "";

    const sublocality = addressComponents.find(component =>
        component.types.includes("sublocality_level_1") ||
        component.types.includes("locality") ||
        component.types.includes("neighborhood")
    )?.long_name || "";

    

    const locationName = `${streetNumber} ${route}`;

    console.log("location namemmm",locationName)

    setLocationDetails({
        locationName: locationName.trim() || place.formatted_address,
        address: place.formatted_address.split(",")[0] || "",
        city:
            addressComponents.find((component) =>
                component.types.includes("locality")
            )?.short_name || "",
        country:
            addressComponents.find((component) =>
                component.types.includes("country")
            )?.short_name || "",
        state:
            addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
            )?.short_name || "",
        zipcode:
            addressComponents.find((component) =>
                component.types.includes("postal_code")
            )?.long_name || "",
    });

    setSelectedPlace(place);
    setMarkerPosition({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
    });
};

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
  };

  const handleInputChange = (e, fieldName) => {
    const { name, value, checked, type } = e.target;
    setLocationDetails((prevValues) => ({
      ...prevValues,
      [fieldName || name]: type === "checkbox" ? checked : value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName || name]: "",
    }));
  };

  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setMarkerPosition({ lat, lng });

    try {
      const geocoder = new window.google.maps.Geocoder();
      const response = await geocoder.geocode({ location: { lat, lng } });

      if (response.results[0]) {
        handlePlaceSelected(response.results[0]);
      } else {
        displayErrMsg("No results found for the selected location.");
      }
    } catch (error) {
      displayErrMsg("Geocoding failed due to: " + error.message);
    }
  };

  const validateForm = () => {
    const errors = {};
  
    // Validate fields common to both roles
    if (!locationDetails.locationName || locationDetails.locationName.trim() === "" ) {
      errors.locationName = "Location Name is required.";
    }
    if (locationDetails.clientLocation) {
        if (!locationDetails.clientName || locationDetails.clientName.trim() === "") {
            errors.clientName = "Client Name is required.";
        }
    }
    console.log("Validation Errors:", errors);
    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors, false if there are errors
  };
  useEffect(() => {
    if (locationDetails.locationName) {
        validateForm();
    }
}, [locationDetails.locationName]);

  const handleAddLocation = async () => {
    if (validateForm()) {
      const locationData = {
        address: locationDetails.address.trim(),
        city: locationDetails.city,
        country: locationDetails.country,
        locationName: locationDetails.locationName,
        location: {
          longitude: selectedPlace.geometry.location.lng(),
          latitude: selectedPlace.geometry.location.lat(),
        },
        state: locationDetails.state,
        zipcode: locationDetails.zipcode,
        suitNumber: locationDetails.buildingNum,
        clientLocation: locationDetails.clientLocation,
        clientName: locationDetails.clientName
      };

      const url = `openstaff/institutions/${empDetails?.id}/addLocation`;

      try {
        setIsLoading(true);
        const addLocationResponse = await apiCall(
          url,
          "POST",
          locationData,
          TOKEN
        );
        console.log("add Location response", addLocationResponse);
        if (addLocationResponse?.status === 200) {
          setIsLoading(false);
          setSessionExpired(false);
          setAutocompleteInput("");
          setLocationCurrentPage(1);
          refreshedLocationList();
          setAutocompleteKey(Date.now());
          setErrSuccessMsg({
            message: "Location Added Successfully!",
            status: "Success",
          });
          // Clear the map and address
          setLocationDetails({
            locationName: "",
            address: "",
            city: "",
            country: "",
            state: "",
            zipcode: "",
            buildingNum: "",
            clientName: "",
            clientLocation: false,
          });
        } else if (addLocationResponse?.response?.status == 401) {
          displayErrMsg("Your session has been expired!");
          setSessionExpired(true);
        } else if (addLocationResponse?.response?.status == 400) {
          displayErrMsg(addLocationResponse?.response?.data?.errorMessage);
          setSessionExpired(false);
        } else {
          setSessionExpired(false);
          // displayErrMsg("Something went wrong!");
          displayErrMsg(addLocationResponse?.response?.data?.message)
        }
        setSelectedPlace(null);
        setMarkerPosition(null);
      } catch (err) {
        setSessionExpired(false);
        displayErrMsg("Something went wrong!");
      }
    } else {
      displayErrMsg("Check the form validation!");
    }
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <div
      className="modal fade twm-sign-up"
      id="add_location_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              Add Location
            </h2>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            <div className="form-group">
            <GooglePlacesAutocomplete
              key={autocompleteKey}
              apiKey={process.env.REACT_APP_GOOGLE_API}
              onPlaceSelected={handlePlaceSelected}
              placeholder="Start typing address or place pin on map"
              className="form-control"
              defaultValue={autocompleteInput}
              types={["geocode"]}
              options={{
                types: ["address"],
                bounds: {
                  north: parseFloat(process.env.REACT_APP_LOC_NORTH_BOUND),  
                  south: parseFloat(process.env.REACT_APP_LOC_SOUTH_BOUND),  
                  east:  parseFloat(process.env.REACT_APP_LOC_EAST_BOUND), 
                  west:  parseFloat(process.env.REACT_APP_LOC_WEST_BOUND),  
                },
                componentRestrictions: { country: "us" },
              }}
              inputProps={{
                value: autocompleteInput,
                onChange: handleAutocompleteInputChange,
              }}
            /></div>
            {locationDetails.address && (<div className="col-xl-12 col-lg-12 col-md-12">
              <div className="form-group " style={{ marginTop: "30px" }}>
                <label>Location Name</label>
                <div className="ls-inputicon-box">
                  <input
                    className="form-control"
                    name="locationName"
                    type="text"
                    placeholder="Location Name"
                    value={locationDetails.locationName}
                    onChange={(e) => handleInputChange(e, "locationName")}
                  />
                </div>
                {formErrors.locationName && (
                  <span className="error-message">
                    {formErrors.locationName}
                  </span>
                )}
              </div>
            </div>
            )}
            {locationDetails.address && (<div className="col-xl-12 col-lg-12 col-md-12">
              <div className="form-group ">
                <label>Suite/Unit/Building</label>
                <div className="ls-inputicon-box">
                  <input
                    className="form-control"
                    name="buildingNum"
                    type="text"
                    placeholder="Suite/Unit/Building"
                    value={locationDetails.buildingNum}
                    onChange={(e) => handleInputChange(e, "buildingNum")}
                  />
                </div>
              </div>
            </div>
            )}
            {locationDetails.address && (<div className="col-xl-12 col-lg-12 col-md-12">
              <div className="form-group ">
                <label className="custom-checkbox manage-team-checkbox">
                  <input
                    type="checkbox"
                    name="clientLocation"
                    checked={locationDetails?.clientLocation || false}
                    onChange={(e) => handleInputChange(e, "clientLocation")}
                  />
                  <span className="custom-checkbox-label ml-3">Client Location</span>
                </label>
              </div>
            </div>
            )}
            {locationDetails?.clientLocation && (<div className="col-xl-12 col-lg-12 col-md-12">
              <div className="form-group ">
                <label>Client Name</label>
                <div className="ls-inputicon-box">
                  <input
                    className="form-control"
                    name="buildingNum"
                    type="text"
                    placeholder="Client Name"
                    value={locationDetails?.clientName}
                    onChange={(e) => handleInputChange(e, "clientName")}
                  />
                </div>
                {formErrors.clientName && (
                  <span className="error-message">
                    {formErrors?.clientName}
                  </span>
                )}
              </div>
            </div>
            )}
            {/* <div style={{ height: "400px", marginTop: "10px" }}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={12}
                center={markerPosition || center}
              >
                {markerPosition && <Marker position={markerPosition} />}
              </GoogleMap>
            </div> */}

            <div style={{ height: "400px", marginTop: "10px" }}>
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={12}
                center={markerPosition || center}
                onClick={handleMapClick} // Add this line to allow clicking on the map
              >
                {markerPosition && <Marker position={markerPosition} />}
              </GoogleMap>
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={handleAddLocation} className="btn btn-primary">
              {isLoading ? <Spinner /> : "Add Location"}
            </button>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
      {sessionExpired && <SessionExpired />}
    </div>
  );
};

export default AddLocation;
