import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../../../../../apicall";
import SectionRecordsFilter from "../../../../public-user/sections/common/section-records-filter";
import SectionJobsSidebar1 from "../../../../public-user/sections/jobs/sidebar/section-jobs-sidebar1";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../../globals/fomat-comapny-name";
import ManageTeamTabs from "../manage-team-tab";
import Toast from "../../../../../common/toast";
import AddTrustedCompanyPopup from "./add-trusted-companies-popup";
import TrustedCompanyTabs from "./trusted-companies-tab";
import CanMyTeamList from "../../../components/can-my-teams/can-my-team-list";

const TrustedCompaniesPage = () => {
  const { role_type, TOKEN } = useSelector((state) => state.menu);
  const [tableFilter, setTableFilter] = useState({
    sortType: "createdDate",
    sortDirection: "desc",
    searchKey: "",
  });
  const [activeTab, setActiveTab] = useState("teams");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  console.log("activeTab", activeTab)
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [trustedCompanyList, setTrustedCompanyList] = useState([]);
  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setTrustedCompanyList([]);
    setPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const fetchTrustedCompanyList = async () => {
    try {
      let data = null;
      console.log("fetchempList-----", role_type, tableFilter?.searchKey);

      const api = `${activeTab === "teams" 
        ? "institutions/trustedCompany"
        :"institutions/companyInvites"
      }?sortBy=${tableFilter?.sortType
        }&sortDirection=${tableFilter?.sortDirection}&offset=0&page=${currentPage - 1
        }&size=${rowPerPage}&searchKey=${tableFilter?.searchKey}`;

      setIsLoading(true);
      const result = await apiCall(api, "GET", data, TOKEN);
      console.log("result-----------", result)
      if (result?.data?.content?.length > 0) {
        setTrustedCompanyList(result.data.content);
        setPaginationData({
          totalElements: result.data.totalElements,
          totalPages: result.data.totalPages,
        });
      } else if (result?.data?.content?.length === 0 && result?.status == 200) {
        setTrustedCompanyList([]);
        setPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
      } else if (result?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        displayErrMsg(result?.response?.data?.message);
      }
      console.log("user list result", result);
    } catch (error) {
      displayErrMsg("Something went wrong!");
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchTrustedCompanyList();
  }, [currentPage, tableFilter, rowPerPage, activeTab]);

  return (
    <>
      <div>
        <Helmet>
          <title>{`Manage Team | Trusted Company | ${formatCompanyName()}`}</title>
          <meta
            name="description"
            content="Welcome to the Manage Team Page! Here, The Manage Team page allows administrators to register new employees and add them in the existing list."
          />
          <meta
            name="keywords"
            content="team management, team structure, team overview, manage team"
          />
        </Helmet>
      </div>
      <div className="section-full p-t120  p-b90 site-bg-white mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionJobsSidebar1
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="twm-sign-up-screen">
                <div className="sign-up-container">
                  <form>
                    <div className="body">
                      <div className="twm-tabs-style-2">
                        <ManageTeamTabs />
                        <div className="tab-content" id="myTabContent">
                          <div
                            className="tab-pane fade show active"
                            id="sign-employees"
                          ></div>
                          <div className="section-full site-bg-white">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-12 col-md-12">

                                  <div class="panel-heading wt-panel-heading p-a20 mb-4 d-flex justify-content-between align-items-center">
                                    <h3 class="wt-title">Trusted Companies</h3>
                                  </div>
                                  <TrustedCompanyTabs handleTabChange={handleTabChange} activeTab={activeTab} fetchTrustedCompanyList={fetchTrustedCompanyList} />
                                  <div className="tab-content" id="myTab2Content">
                                    {trustedCompanyList?.length > 0 && (<SectionRecordsFilter
                                      _config={_filterConfig}
                                      rowPerPage={rowPerPage}
                                      handleRecordsPerPageChange={
                                        handleRecordsPerPageChange
                                      }
                                      setTableFilter={setTableFilter}
                                      tableFilter={tableFilter}
                                      setCurrentPage={setCurrentPage}
                                    />)}
                                    <CanMyTeamList
                                      isLoading={isLoading}
                                      myTeamList={trustedCompanyList}
                                      currentPage={currentPage}
                                      setCurrentPage={setCurrentPage}
                                      totalPages={paginationData.totalPages}
                                      isCanInvites={activeTab === 'invites'}
                                      fetchInvitesList={fetchTrustedCompanyList}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddTrustedCompanyPopup fetchTrustedCompanyList={fetchTrustedCompanyList} />
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default TrustedCompaniesPage;
