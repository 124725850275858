import React, { useState } from "react";
import axios from "axios";
import BASE_URL from "../../../../../config";
import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../../../common/spinner";

const JobReviewRate = ({
  jobDetailsInfo,
  jobDetailId,
  jobRating,
  handleClosePopup,
  currentPage,
  fetchjobList,
  setCurrentPage,
  displayErrMsg,
  displaySuccessMsg,
  userEntityId,
  hiredCandidatesList,
  getHiredCandidatesList,
  getJobDetailsData
}) => {
  const location = useLocation();
  const currentUrl = location.pathname;
  const [rating, setRating] = useState(jobRating ? jobRating : 0);
  const [review, setReview] = useState("");
  const { TOKEN, role_type } = useSelector((state) => state.menu);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  console.log("userEntityId====",userEntityId)

  const handleRating = (newRating) => {
    // if (jobRating === 0) {
      setRating(newRating);
    // }
  };

  const handleReviewChange = (e) => {
    setReview(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (rating === 0) {
      displayErrMsg("Rating is required");
      return;
    }

    setLoading(true);

    try {
      let url;
      if (role_type.includes("ROLE_USER")) {
        url = `${BASE_URL}jobs/${jobDetailId}/reviewJob`;
      } else if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
        if (currentPage === "jobList") {
          url = `${BASE_URL}institutions/jobs/${jobDetailsInfo}/reviewJob`;
        } else if (currentPage === "approveFinish") {
           if(hiredCandidatesList?.length === 1){
            url = `${BASE_URL}institutions/jobs/${jobDetailId}/approveClockInClockOut`;
           }else{
            url = `${BASE_URL}institutions/jobs/${jobDetailId}/approveClockInClockOut/${userEntityId}`;
           }
        }
      }

      const config = {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${TOKEN}`,
        },
      };

      const formData = {
        nStars: rating,
        review: review,
      };

      let response = "";

      if (role_type.includes("ROLE_USER")) {
        response = await axios.post(url, formData, config);
        console.log("response:::::", response);
      } else if (role_type.includes("ROLE_INSTITUTION_ADMIN")) {
        if (currentPage === "jobList") {
          response = await axios.post(url, formData, config);
        } else if (currentPage === "approveFinish") {
          response = await axios.put(url, formData, config);
        }
      }


      if (response.status === 200) {
        displaySuccessMsg("Rating and review added successfully!");
        setIsLoading(false);
        setRating(0);
        setReview("");
        if (currentPage === "jobList") {
          setCurrentPage(1);
          fetchjobList();
        }else if(currentPage === "approveFinish"){
          getHiredCandidatesList();
          getJobDetailsData();
        }

        handleClosePopup();
      } else {
        displayErrMsg(response?.response?.data?.message);
      }
    } catch (error) {
      console.log("error------", error);
      if (
        error?.response?.data?.errorMessage
      ) {
        displayErrMsg(error.response.data.errorMessage);
      } else {
        displayErrMsg("Something went wrong!");
      }
      // Clear state in case of error
      setRating(0);
      setReview("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div style={{ display: "flex", gap: "10px" }}>
                <h5 className="card-title">Rating:</h5>
                <div className="rating mb-3">
                  {[...Array(5)].map((_, index) => (
                    <i
                      key={index}
                      className="fas fa-regular fa-star"
                      onClick={() => handleRating(index + 1)}
                      style={{
                        color: index < rating ? "#128c5e" : "lightgrey",
                        cursor: "pointer",
                        position: "relative",
                        top: 0,
                      }}
                    />
                  ))}
                </div>
              </div>
              <form>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Write a review"
                    value={review}
                    style={{
                      width: "100%",
                      padding: "20px",
                      border: "none",
                      height: "100px",
                      borderRadius: "10px",
                      backgroundColor: "#f0f6fe",
                      resize: "vertical",
                    }}
                    onChange={handleReviewChange}
                    // disabled={jobRating > 0}
                  ></textarea>
                </div>
                <button
                  type="button"
                  className="btn btn-success"
                  // disabled={loading || jobRating > 0}
                  onClick={handleSubmit}
                  style={{ display: "block", margin: "0 auto" }}
                >
                  {loading ? <Spinner /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobReviewRate;
