import React from "react";
import AddTrustedCompanyPopup from "./add-trusted-companies-popup";

const TrustedCompanyTabs = ({ handleTabChange, activeTab, fetchTrustedCompanyList }) => {

    return (
        <>
            <div class="panel-heading p-a20 mb-4 d-flex justify-content-between align-items-center">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === "teams" ? "active" : ""}`}
                            data-bs-toggle="tab"
                            data-bs-target="#sign-employees"
                            type="button"
                            onClick={() => handleTabChange("teams")}
                        >
                            <i className="fas fa-users" /> Teams
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === "invites" ? "active" : ""}`}
                            data-bs-toggle="tab"
                            data-bs-target="#sign-trusted-workers"
                            type="button"
                            onClick={() => handleTabChange("invites")}
                        >
                            <i className="fa fa-user-plus" /> Invites
                        </button>
                    </li>
                </ul>
                {activeTab === "teams" && (<a
                    className="site-button"
                    data-bs-toggle="modal"
                    href={"#add_trusted_company_popup"}
                    role="button"
                >
                    Add
                </a>
                )}
            </div>
            <AddTrustedCompanyPopup fetchTrustedCompanyList={fetchTrustedCompanyList}/>
        </>
    );
};

export default TrustedCompanyTabs;
