import { useState, useEffect } from "react";
import SectionJobsSidebar1 from "../../sections/jobs/sidebar/section-jobs-sidebar1";
import SectionEmpsList from "../../sections/employers/section-emps-list";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import { loadScript } from "../../../../../globals/constants";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { useLocation } from "react-router-dom";
import JobViewTabs from "../jobs/job-view-tabs";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

function EmpsListPage() {
    const { role_type , TOKEN } = useSelector((state) => state.menu);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [empListData, setEmpListData] = useState([]);
    const [searchLocationLatLng, setSearchLocationLatLng] = useState({
        latitude: location?.state?.lat,
        longitude: location?.state?.lng,
    });
    
    const [tableFilter, setTableFilter] = useState({
        sortType: "recent",
        sortDirection: "desc",
        searchKey: "",
    })
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [paginationData, setPaginationData] = useState({
        totalElements: 0,
        totalPages: 0,
    });
    const [sessionExpired, setSessionExpired] = useState(false);
    const [errSuccessMesg, setErrSuccessMsg] = useState({
        message: "",
        status: "error",
    });
    const _filterConfig = {
        prefix: "Showing",
        type: "results",
        isSorting: true,
        total: paginationData?.totalElements,
        showRange: false,
        showingUpto: "",
    };
    const handleRecordsPerPageChange = (event) => {
        setCurrentPage(1);
        setRowPerPage(event.target.value);
    };
    const displayErrMsg = (errMsg) => {
        setErrSuccessMsg({
            message: errMsg,
            status: false,
        });
        setIsLoading(false);
        setEmpListData([]);
        setPaginationData({
            totalElements: 0,
            totalPages: 0,
        });
    };
    const fetchempList = async () => {
        let data = null;
        console.log("fetchempList-----", role_type, tableFilter?.searchKey);
        const api = `${(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN"))
            ? `openstaff/institutions`
            : ``
            }?sortBy=${tableFilter?.sortType}&sortDirection=${tableFilter?.sortDirection
            }&offset=0&page=${currentPage - 1}&size=${rowPerPage}&searchkey=${tableFilter?.searchKey
            }`;
        try {
            setIsLoading(true);
            const result = await apiCall(api, "GET", data, TOKEN);
            if (result?.data?.content?.length > 0) {
                setIsLoading(false);
                setSessionExpired(false);
                setEmpListData(result.data.content);
                setPaginationData({
                    totalElements: result.data.totalElements,
                    totalPages: result.data.totalPages,
                });
            } else if (result?.data?.content?.length === 0 && result?.status == 200) {
                setSessionExpired(false);
                // displayErrMsg("No results found.!");
                setIsLoading(false);
                setEmpListData([]);
                setPaginationData({
                    totalElements: 0,
                    totalPages: 0,
                });
            } else if (result?.response?.status == 401) {
                setSessionExpired(true);
                displayErrMsg("Your session has been expired!");
            } else {
                setSessionExpired(false);
                // displayErrMsg("Something went wrong!");
                displayErrMsg(result?.response?.data?.message)
            }
            console.log("user list result", result);
        } catch (error) {
            setSessionExpired(false);
            displayErrMsg("Something went wrong!");
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        loadScript("js/custom.js")
    })
    useEffect(() => {
        fetchempList();
    }, [currentPage, tableFilter, rowPerPage]);

    useEffect(() =>{
        logEvent_Analytics('employers_list_web');
    }, [])

    return (
        <>
            <div className="section-full p-t120  p-b90 site-bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 rightSidebar">
                            <SectionJobsSidebar1
                                setTableFilter={setTableFilter}
                                tableFilter={tableFilter}
                                setCurrentPage={setCurrentPage}
                            />
                        </div>
                        <div className="col-lg-8 col-md-12">
                            {/*Filter Short By*/}
                            <div class="panel-heading wt-panel-heading p-a20 mb-4">
                                <h3 class="wt-title mt-4">Companies</h3>
                            </div>
                            <JobViewTabs />
                            <SectionRecordsFilter
                                _config={_filterConfig}
                                rowPerPage={rowPerPage}
                                handleRecordsPerPageChange={handleRecordsPerPageChange}
                                setTableFilter={setTableFilter}
                                tableFilter={tableFilter}
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                            />
                            <SectionEmpsList
                                isLoading={isLoading}
                                empListData={empListData}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={paginationData.totalPages}
                            />
                            {/* <SectionPagination /> */}
                        </div>
                    </div>
                </div>
            </div>
            {sessionExpired && <SessionExpired />}
            {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
        </>
    )
}

export default EmpsListPage;