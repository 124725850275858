import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import SectionPagination from "../../../public-user/sections/common/section-pagination";
import GetProfileImage from "../../../../common/getProfileImage";
import TableLoader from "../../../../common/tableLoader";
import CanAcceptInvites from "./can-invites/can-accept-invites";
import CanRejectInvites from "./can-invites/can-reject-invites";

function CanMyTeamList({
  isLoading,
  myTeamList,
  currentPage,
  setCurrentPage,
  totalPages,
  isCanInvites,
  fetchInvitesList
}) {
  console.log("myTeamList", myTeamList);
  const [selectedCustomer, setSelectedCustomer] = useState();

  const StarRating = ({ rating }) => {
    const stars = [];
    console.log("rating----------", rating);
    for (let i = 0; i < 5; i++) {
      if (rating >= i + 1) {
        stars.push(
          <i key={i} className="fas fa-star" style={{ color: "#128c5e" }} />
        );
      } else if (rating >= i + 0.5) {
        stars.push(
          <i
            key={i + 0.5}
            className="fas fa-star-half-alt"
            style={{ color: "#128c5e" }}
          />
        );
      } else {
        stars.push(
          <i key={i} className="far fa-star" style={{ color: "lightgrey" }} />
        );
      }
    }

    return <span>{stars}</span>;
  };

  return (
    <>
      <div className="twm-jobs-list-wrap">
        <ul>
          {!isLoading ? (
            myTeamList?.length > 0 ? (
              myTeamList?.map((item) => (
                <li key={item.id} style={{ marginTop: "7rem" }}>
                  <div className="twm-jobs-list-style1 mb-5">
                    <div className="twm-media">
                      <GetProfileImage id={item?.id ? item?.id : item?.institutionId} type={"jobList"} />
                    </div>
                    <div className="twm-mid-content">
                        <h4>{`${item?.name}`}</h4>
                      <div>
                        <StarRating rating={item?.averageNumStars} />{" "}
                        <span style={{ fontSize: 12 }}>
                          {(item?.numberOfReview || 0) + " Reviews"}
                        </span>
                      </div>
                    </div>

                    <div className="twm-fot-content">
                      {!isCanInvites && item?.status === "PENDING" && (
                        <h4 style={{ color: "orange" }}>{item?.status}</h4>
                      )}
                      {isCanInvites && (
                        <>
                          <div className="twm-left-info"></div>
                          <div className="twm-right-btn ms-3">
                            <a
                              className="site-button accept-invite"
                              data-bs-toggle="modal"
                              href={"#can_accept_invites"}
                              role="button"
                              onClick={() => setSelectedCustomer(item)}
                            >
                              <i className="fa fa-check" />
                            </a>
                            <a
                              key={item.id}
                              className="site-button reject-invite"
                              data-bs-toggle="modal"
                              role="button"
                              href={`#can_reject_invites`}
                              onClick={() => setSelectedCustomer(item)}
                            >
                              <i className="fa fa-times" />
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              ))
            ) : (
              <p className="mt-3">
                {isCanInvites
                  ? `You do not have any pending team invites.`
                  : ` You are not currently a member of any teams. When a company adds
                you to their team, they will appear here.`}
              </p>
            )
          ) : (
            <TableLoader />
          )}
        </ul>
      </div>
      {myTeamList?.length > 0 && (
        <SectionPagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={totalPages}
        />
      )}
      <CanAcceptInvites selectedCustomer={selectedCustomer} fetchInvitesList={fetchInvitesList}/>
      <CanRejectInvites selectedCustomer={selectedCustomer} fetchInvitesList={fetchInvitesList}/>
    </>
  );
}

export default CanMyTeamList;
