import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../../../../../apicall";
import SectionRecordsFilter from "../../../../public-user/sections/common/section-records-filter";
import CanMyTeamList from "../can-my-team-list";
import { loadScript } from "../../../../../../globals/constants";
import CanSearchKey from "../can-search-key";
import Toast from "../../../../../common/toast";
import CanMyTeamTabs from "../can-my-teams-tab";

const CanInvites = () => {
  const { role_type, TOKEN } = useSelector((state) => state.menu);
  const [tableFilter, setTableFilter] = useState({
    sortType: "name",
    sortDirection: "asc",
    searchKey: "",
  });
  const [sessionExpired, setSessionExpired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [paginationData, setPaginationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [invitesList, setInvitesList] = useState([]);

  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationData?.totalElements,
    showRange: false,
    showingUpto: "",
    showEmployeeNameFilter: true,
  };
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setInvitesList([]);
    setPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const handleSearchClick = (e) => {
    if (e.key === "Enter") {
      setTableFilter({
        ...tableFilter,
        searchKey: searchKey,
      });
      setCurrentPage(1);
    }
  };

  const clearSearch = () => {
    setSearchKey("");
    setCurrentPage(1);
    setTableFilter({
      ...tableFilter,
      searchKey: "",
    });
  };

  const handleSearchKeyChange = (e) => {
    if (e?.target?.value.trim() === "") {
      clearSearch();
    } else {
      setSearchKey(e.target.value);
    }
  };

  const fetchInvitesList = async () => {
    try {
      let data = null;
      console.log("fetchempList-----", role_type, tableFilter?.searchKey);

      const api = `${`applicants/companyInvites`}?sortBy=${
        tableFilter?.sortType
      }&sortDirection=${tableFilter?.sortDirection}&offset=0&page=${
        currentPage - 1
      }&size=${rowPerPage}&searchKey=${tableFilter?.searchKey}`;

      setIsLoading(true);
      const result = await apiCall(api, "GET", data, TOKEN);

      console.log("result company list-----------", result);
      if (result?.data?.content?.length > 0) {
        setSessionExpired(false);
        setInvitesList(result.data.content);
        setPaginationData({
          totalElements: result.data.totalElements,
          totalPages: result.data.totalPages,
        });
      } else if (result?.data?.content?.length === 0 && result?.status == 200) {
        setSessionExpired(false);
        // displayErrMsg("No results found.!");
        setInvitesList([]);
        setPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
      } else if (result?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(result?.response?.data?.message);
      }
      console.log("user list result", result);
    } catch (error) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      console.error("Error fetching user data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInvitesList();
  }, [currentPage, tableFilter, rowPerPage]);

  useEffect(() => {
    loadScript("js/custom.js");
  });

  return (
    <>
      <div class="panel-heading wt-panel-heading p-a20">
        <h3 class="wt-title">My Teams</h3>
      </div>
      <div className="twm-tabs-style-2">
        <CanMyTeamTabs />
      </div>
      {invitesList?.length > 0 && (
        <>
          <CanSearchKey
            searchKey={searchKey}
            handleSearchKeyChange={handleSearchKeyChange}
            handleSearchClick={handleSearchClick}
            clearSearch={clearSearch}
          />

          <SectionRecordsFilter
            _config={_filterConfig}
            rowPerPage={rowPerPage}
            handleRecordsPerPageChange={handleRecordsPerPageChange}
            setTableFilter={setTableFilter}
            tableFilter={tableFilter}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
      <CanMyTeamList
        isLoading={isLoading}
        myTeamList={invitesList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={paginationData.totalPages}
        isCanInvites={true}
        fetchInvitesList={fetchInvitesList}
      />
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default CanInvites;
