import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import Toast from "../../../../../common/toast";
import Spinner from "../../../../../common/spinner";
import apiCall from "../../../../../../apicall";
import { useLocation } from "react-router-dom";

function CanAcceptInvites({ selectedCustomer, fetchInvitesList }) {
  console.log("selectedCustomer=====",selectedCustomer)
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const location = useLocation();
  const currentUrl = location.pathname;
  const modalRef = useRef(null);
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };
  const displaySuccessMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: true,
    });
    setIsLoading(false);
  };
  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      const api =
      currentUrl === "/manage-team-trusted-companies"
        ? `institutions/${selectedCustomer?.id}/acceptRejectInvitation?accepted=true`
        : `applicants/jobs/${selectedCustomer?.id}/acceptRejectInvitation?accepted=true`;
      const response = await apiCall(
        api,
        "POST",
        null,
        TOKEN
      );
      console.log("job hire---", response);

      if (response?.status == 200) {
        displaySuccessMsg(`${selectedCustomer?.name} invitation accepted successfully.`);
        fetchInvitesList();
        setTimeout(() => {
          if (modalRef.current) {
              modalRef.current.click();
          }
        }, 2000);
      } else {
        displayErrMsg(response?.response?.data?.errorMessage);
      }
    } catch (err) {
        displayErrMsg(err);
    } finally{
        setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="modal fade twm-sign-up"
        id="can_accept_invites"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h2 className="modal-title" id="sign_up_popupLabel">
                  Accept invitation?
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={modalRef}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleConfirm}
                >
                  {isLoading ? <Spinner /> : "Yes"}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  ref={modalRef}
                >
                  {"No"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message !== "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default CanAcceptInvites;
