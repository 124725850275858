import React, { useState, useEffect } from "react";
import apiCall from "../../../../../../apicall";
import Toast from "../../../../../common/toast";
import Spinner from "../../../../../common/spinner";
import SessionExpired from "../../../../../common/sessionExpired";
import SectionJobsSidebar1 from "../../../sections/jobs/sidebar/section-jobs-sidebar1";
import SectionRecordsFilter from "../../../sections/common/section-records-filter";
import SectionUsersList from "./sectionUsersList";
import { useSelector } from "react-redux";
import { loadScript } from "../../../../../../globals/constants";
import { Helmet } from "react-helmet-async";
import { formatCompanyName } from "../../../../../../globals/fomat-comapny-name";
import JobViewTabs from "../../jobs/job-view-tabs";
import { logEvent_Analytics } from "../../../../../common/firebase/firebase-analytics-service";
const AdminUsersList = () => {
  const { role_type, TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [userListData, setUserListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const [tableFilter, setTableFilter] = useState({
    status: "All",
    applicant: "",
    customer: "",
    fromDate: "",
    toDate: "",
    sortType: "recent",
    sortDirection: "desc",
    searchKey: "",
    jobType: "",
    applicant: "",
    customer: "",
    roleType: "All",
    jobStatus:
      role_type.includes("ROLE_OPENSTAFF_ADMIN")
        ? "All"
        : role_type.includes("ROLE_USER") || role_type.includes("ROLE_INSTITUTION_ADMIN")
          ? "eOpen"
          : "All",
  });

  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setUserListData([]);
    setPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const fetchUsersList = async () => {
    let data = null;
    const api = `${role_type.includes("ROLE_OPENSTAFF_ADMIN")
      ? `users?status=${tableFilter?.jobStatus}&roleType=${tableFilter?.roleType}&offset=0&sortBy=${tableFilter?.sortType}&sortDirection=${tableFilter?.sortDirection
      }&page=${currentPage - 1
      }&size=${rowPerPage}&searchkey=${tableFilter?.searchKey}`
      : ""
      }`;
    try {
      setIsLoading(true);
      const result = await apiCall(api, "GET", data, TOKEN);
      if (result?.data?.content?.length > 0) {
        setIsLoading(false);
        setSessionExpired(false);
        setUserListData(result.data.content);
        setPaginationData({
          totalElements: result.data.totalElements,
          totalPages: result.data.totalPages,
        });
      } else if (result?.data?.content?.length === 0 && result?.status == 200) {
        setSessionExpired(false);
        // displayErrMsg("No results found.!");
        setIsLoading(false);
        setUserListData([]);
        setPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
      } else if (result?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(result?.response?.data?.message)
      }
      console.log("user list result", result);
    } catch (error) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
  });

  useEffect(() => {
    fetchUsersList();
  }, [currentPage, tableFilter, rowPerPage]);

  useEffect(() =>{
    logEvent_Analytics('users_list_web');
  },[])

  return (
    <>
    <div>
        <Helmet>
          <title>{`User List | User Profiles | User Overview | Active Users | Manage Users | ${formatCompanyName()}`}</title>
          <meta name="description" content="Welcome to the User List Page! This section provides a comprehensive overview of all users within the platform." />
          <meta name="keywords" content="User Profiles, User Management, User Overview, User Roles, Search Users, User Information, Contact Users, User Activity, Role Management, Profile Management, User Insights" />
        </Helmet>
      </div>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionJobsSidebar1
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              <div class="panel-heading wt-panel-heading p-a20 mb-4">
                <h3 class="wt-title mt-4">Users</h3>
              </div>
              <JobViewTabs />
              {/*Filter Short By*/}
              <SectionRecordsFilter
                _config={_filterConfig}
                rowPerPage={rowPerPage}
                handleRecordsPerPageChange={handleRecordsPerPageChange}
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              <SectionUsersList
                isLoading={isLoading}
                userListData={userListData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={paginationData.totalPages}
              />
            </div>
          </div>
        </div>
      </div>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
};

export default AdminUsersList;
