import { useEffect, useState } from "react";
import { loadScript, publicUrlFor } from "../../../../../globals/constants";
import JobZImage from "../../../../common/jobz-img";
import CountUp from "react-countup";
import { publicUser } from "../../../../../globals/route-names";
import { NavLink, useNavigate } from "react-router-dom";
import GooglePlacesAutocomplete from "react-google-autocomplete";
// import { GOOGLE_API } from "../../../../../config";
import moment from "moment";
import apiCall from "../../../../../apicall";
import { useSelector, useDispatch } from "react-redux";
import { pixelBoundsToLatLngBounds } from "@googlemaps/markerclusterer";
import { formatCompanyName } from "../../../../../globals/fomat-comapny-name";
import {
  addressSelectedStatus,
  selectedCategoryType,
  cityListDetails,
  navigateVisitedPage,
  companyLogo,
  themeColors,
  smallcompanyLogo,
  jobDetailsData,
  logout,
  roleType,
  setUserLogo
} from "../../../../../store/reducers/menu";
import Spinner from "../../../../common/spinner";
import GetProfileImage from "../../../../common/getProfileImage";
import TableLoader from "../../../../common/tableLoader";
import { Helmet } from "react-helmet-async";
import { signOut } from "firebase/auth";
import { auth } from "../../../../common/firebase/firebase";
// import BASE_URL from "../../../../../config";
// import {BuildProfile} from "assets/images/build_profile.png"

function Home1Page() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [isAddressSelected, setIsAddressSelected] = useState(true);
  const { TOKEN, role_type, CONFIGKEY, isLoggedIn } = useSelector(
    (state) => state.menu
  );
  const [jobTypes, setJobTypes] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [popularSearch, setPopularSearch] = useState([]);
  const [featureJobList, setFeatureJobList] = useState([]);
  const [isFeatureLoading, setIsFeatureLoading] = useState([]);
  const [jobCount, setJobCount] = useState([]);
  const [liveJobCount, setLiveJobCount] = useState([]);
  const [isliveJobCountLoading, setIsLiveJobCountLoading] = useState(false);
  const [selectedJobType, setSelectedJobType] = useState("All");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedLocationName, setSelectedLocationName] = useState("");
  const [isLoading, setIsLoading] =useState(false)
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [selectedLocationLatLng, setSelectedLocationLatLng] = useState({
    lat: "",
    lng: "",
  });
  const [selectedCityLatLng, setSelectedCityLatLng] = useState({
    lat: "",
    lng: "",
  });
  // const handleJobTypeChange = (event) => {
  //     setSelectedJobType({
  //       ...selectedJobType,
  //       jobTypes: event.target.value,
  //     });
  // };

  function getTimeDifference(postTime) {
    const currentTime = new Date();
    const postDate = new Date(postTime);
    const timeDifference = currentTime.getTime() - postDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (seconds < 60) {
      return `${seconds} sec ago`;
    } else if (minutes < 60) {
      return `${minutes} min ago`;
    } else if (hours < 24) {
      return `${hours} hour ago`;
    } else if (days < 30) {
      return `${days} day ago`;
    } else if (months < 12) {
      return `${months} month ago`;
    } else {
      return `${years} year ago`;
    }
  }

  const fetchData = async () => {
    const positionResponse = await apiCall(
      "config/allJobTypes",
      "GET",
      null,
      TOKEN
    );
    console.log("positionResponse", positionResponse);
    if (positionResponse?.data?.length > 0) {
      const jobTypesData = positionResponse.data.map((item) => item.typeName);
      setJobTypes(jobTypesData);
    }
  };
  const fetchCityList = async () => {
    const cityListResponse = await apiCall(
      "config/locationList",
      "GET",
      null,
      TOKEN
    );
    console.log("cityListResponse", cityListResponse);
    if (cityListResponse?.data?.length > 0) {
      // const cityListData = cityListResponse.data.map((item) => item.city);
      setCityList(cityListResponse?.data);
      dispatch(cityListDetails(cityListResponse?.data));
    }
  };
  const fetchPopularSearch = async () => {
    try{
      const popularSearchResponse = await apiCall(
        "config/jobs/popularSearches",
        "GET",
        null
      )
      console.log("popularSearch", popularSearchResponse);
      if (popularSearchResponse?.data?.length > 0) {
        const jobTitles = popularSearchResponse.data.map((item) => item); // Extract job titles
        setPopularSearch(jobTitles.join(", "));
      }
    }catch(err){
      console.log("kkkkdkdkdkdkd",err)
    }
  }
  
  const fetchCLogo = async () => {
    const response = await apiCall(
      "config/companyLogoLarge",
      "GET",
      null,
      TOKEN,
      false, 
      true
    );
    const smallLogoResponse = await apiCall(
      "config/companyLogo",
      "GET",
      null,
      TOKEN,
      false, 
      true
    );
      if (response.status === 200) {
        const blobResponse = await response?.data;
        const reader = new FileReader();
        reader.readAsDataURL(blobResponse);
  
        reader.onloadend = () => {
          const imageResponse = reader.result;
          dispatch(companyLogo(imageResponse));;
        };
    };
    if (smallLogoResponse.status === 200) {
      const blobResponse = await smallLogoResponse?.data;
      const reader = new FileReader();
      reader.readAsDataURL(blobResponse);

      reader.onloadend = () => {
        const imageResponse = reader.result;
        dispatch(smallcompanyLogo(imageResponse));;
      };
  };
}
const fetchThemeColor = async () => {
  setIsLoading(true)
  try{
  const themeResponse = await apiCall(
    "config/colorTheme?keyName=portalTheme",
    "GET",
    null,
    TOKEN
  );
  console.log('themes::::::::::::::',themeResponse?.data?.themes)
  if (themeResponse?.data?.themes?.length > 0) {
    // const cityListData = cityListResponse.data.map((item) => item.city);
    dispatch(themeColors(themeResponse?.data?.themes));
    setIsLoading(false)
  }
}
catch(err){
  setIsLoading(false) 
}
//   const api = BASE_URL + "config/companyLogo"
//   const response = await fetch(api);

//   if (response.ok && response.status === 200) {
//     const blobResponse = await response.blob();
//     const reader = new FileReader();
//     reader.readAsDataURL(blobResponse);

//     reader.onloadend = () => {
//       const imageResponse = reader.result;
//       dispatch(companyLogo(imageResponse));;
//     };
// };
}
console.log('isLoading::::::::::::::',isLoading)
  const fetchCategoryJobsCount = async () => {
    try{
      setIsCategoryLoading(true)
      setIsLiveJobCountLoading(true)
    const categoryResponse = await apiCall(
      "config/jobs/count",
      "GET",
      null,
      TOKEN
    );
    console.log("categoryResponse", categoryResponse);
    if (categoryResponse?.data) {
      setIsCategoryLoading(false)
      setIsLiveJobCountLoading(false)
      const jobCountData = categoryResponse.data.jobPositionCounts;
      const liveJobCountData = categoryResponse.data.totalCounts;
      console.log("jobcount", jobCountData);
      setLiveJobCount(liveJobCountData);
      setJobCount(jobCountData);
    }
  } catch (err) {
    setIsCategoryLoading(false)
    setIsLiveJobCountLoading(false)
  }
  };

  const fetchFeatureJobList = async () => {
    setIsFeatureLoading(true);
    const featureJobResponse = await apiCall(
      "config/featuredJobs",
      "GET",
      null,
      TOKEN
    );
    if (featureJobResponse?.status == 200) {
      setIsFeatureLoading(false);
      const processedList = Object.keys(featureJobResponse?.data).reduce(
        (acc, key) => {
          if (
            Array.isArray(featureJobResponse?.data[key]) &&
            featureJobResponse?.data[key].length > 0
          ) {
            acc.push(featureJobResponse?.data[key][0]); // Add the first element of the array to the list
          }
          return acc;
        },
        []
      );
      console.log("featureJobResponse", processedList);
      setFeatureJobList(processedList);
    } else {
      setIsFeatureLoading(false);
      setFeatureJobList([]);
    }
  };

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              distance: 1000
            });
          },
          (error) => {
            reject(error);
          }
        );
      } else {
        reject(new Error("Geolocation is not supported by this browser."));
      }
    });
  };

  useEffect(() => {
    fetchThemeColor()
    fetchCLogo()
    fetchData();
    fetchPopularSearch();
    fetchCityList();
    fetchCategoryJobsCount();
    fetchFeatureJobList();
    dispatch(addressSelectedStatus(false));
    dispatch(selectedCategoryType("All"));
    dispatch(navigateVisitedPage(null));
    console.log("isLoggedIn:::::", isLoggedIn)
    if (!isLoggedIn){
      dispatch(logout(null));
      dispatch(roleType([]));
      dispatch(setUserLogo(null));
      signOut(auth);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/job-list");
    }
  }, []);

  const options = {
    items: 3,
    nav: true,
    // loop: true,
    autoplay: true,
    autoplayTimeout: 10000,
    rewind: true,
    margin: 30,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 5,
      },
    },
  };

  useEffect(() => {
    loadScript("js/custom.js");
  });

  const findFilteredJob = async () => {
    let locationLatLng = selectedLocationLatLng;
    if (!selectedLocationLatLng.lat && !selectedLocationLatLng.lng) {
      try {
        locationLatLng = await getCurrentLocation();
        console.log("locationLatLng::::::", locationLatLng)
      } catch (error) {
        console.error("Error getting current location:", error);
      }
    }
    navigate("/job-list", {
      state: {
        latlng: locationLatLng,
        jobType: selectedJobType,
        locationName: selectedLocationName,
      },
    });
    dispatch(
      addressSelectedStatus({
        latlng: locationLatLng,
        jobType: selectedJobType,
        locationName: selectedLocationName,
      })
    );
  };

  const handlePlaceSelect = (place) => {
    if (place?.geometry) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      setSelectedLocationLatLng({
        lat,
        lng,
      });
      setSelectedLocationName(place?.formatted_address);
      // setIsAddressSelected(false);
    }
  };
  const handleJobTypeSelect = (selectedJobType) => {
    console.log("Selected Job Type:", selectedJobType);
    setSelectedJobType(selectedJobType);
    dispatch(selectedCategoryType(selectedJobType));
  };
  const handleCitySelect = (selectedJobType) => {
    const selectedCity = JSON.parse(selectedJobType);
    setSelectedLocationName(selectedCity.city);
    setSelectedLocationLatLng({
      lat: selectedCity.lat,
      lng: selectedCity.lng,
    });
    dispatch(
      addressSelectedStatus({
        latlng: { lat: selectedCity.lat, lng: selectedCity.lng },
        jobType: selectedJobType,
        locationName: selectedCity.city,
      })
    );
    console.log("Selected City:", selectedCity.city);
    console.log("Selected Job Type:", selectedJobType);
    // setSelectedCity(selectedJobType);
  };
  const navigateToJobDetails = (item) => {
    // dispatch(jobDetailsData(item))
    navigate(`/job-detail/${item.id}`, { state: item });
  };
  const displayJobTime = (startTime, endTime, isMultiDayJob) => {
    const formattedStartTime = moment
      .utc(startTime, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("MM/DD/YYYY");
    const formattedEndTime = moment
      .utc(endTime, "YYYY-MM-DD HH:mm:ss")
      .local()
      .format("MM/DD/YYYY");

    const displayTime = isMultiDayJob
      ? `${formattedStartTime} - ${formattedEndTime}`
      : `${formattedStartTime} ${moment
          .utc(startTime, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("hh:mm A")} - ${moment
          .utc(endTime, "YYYY-MM-DD HH:mm:ss")
          .local()
          .format("hh:mm A")}`;

    return <div>{displayTime}</div>;
  };
  const formatAddress = (address, city, state, zipCode) => {
    const parts = [address, city, state, zipCode].filter(Boolean);
    return parts.join(", ");
  };
  const navigateToJobList = (selectedJobCategory) => {
    // dispatch(jobDetailsData(item))
    console.log("selectedJobCategory", selectedJobCategory)
    dispatch(selectedCategoryType(selectedJobCategory));
    navigate("/job-list", {
      state: {
        jobType: selectedJobCategory,
      },
    });
  };

  return (
    <>
    <div>
        <Helmet>
          {/* <title>{`${jobDetailsInfo?.jobTitle} - Find Opportunities`}</title> */}
          <title>Explore Job Opportunities | Job Platform | {formatCompanyName()}</title>
          <meta name="description" content="A dynamic job platform connecting employers with skilled professionals for flexible and permanent roles." />
          <meta name="keywords" content="upcoming job opportunities, job platform, open staff job agencies, job search platforms, job posting platform" />
        </Helmet>
      </div>
      {/*Banner Start*/}
      <div
        className="twm-home1-banner-section site-bg-gray bg-cover"
        //style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider1/bg1.jpg")})` }}
      >
        <div className="row" style={{ background: "#EFF6EE" }}>
          {/*Left Section*/}
          <div className="col-xl-6 col-lg-6 col-md-12 twm-bnr-right-section">
            <div className="twm-bnr-right-content">
              <div className="twm-img-bg-circle-area">
                <div className="twm-img-bg-worker">
                  <span />
                </div>
                <div className="twm-img-bg-circle1 rotate-center">
                  <span />
                </div>
                <div className="twm-img-bg-circle2 rotate-center-reverse">
                  <span />
                </div>
                <div className="twm-img-bg-circle3">
                  <span />
                </div>
              </div>
              <div className="twm-bnr-right-carousel">
                <div className="owl-carousel twm-h1-bnr-carousal">
                  <div className="item">
                    {/* <div className="slide-img">
                                            <JobZImage src="images/main-slider/slider1/r-img1.png" alt="#" />
                                        </div> */}
                  </div>
                  <div className="item">
                    <div className="slide-img">
                      {/* <div className="slide-img">
                                                <JobZImage src="images/main-slider/slider1/r-img2.png" alt="#" />
                                            </div> */}
                    </div>
                  </div>
                </div>
                <div className="twm-bnr-blocks-position-wrap">
                  {/*icon-block-1*/}
                  <div className="twm-bnr-blocks twm-bnr-blocks-position-1">
                    <div className="twm-icon">
                      <JobZImage
                        src="images/main-slider/slider1/icon-1.png"
                        alt=""
                      />
                    </div>
                    <div className="twm-content">
                      <div className="tw-count-number text-clr-sky">
                        <span className="counter">
                          {liveJobCount.totalJobs}
                        </span>
                      </div>
                      <p className="icon-content-info">Jobs Posted in the Last 30 Days</p>
                    </div>
                  </div>
                  {/*icon-block-2*/}
                  {/* <div className="twm-bnr-blocks twm-bnr-blocks-position-2">
                                        <div className="twm-icon">
                                            <JobZImage src="images/main-slider/slider1/icon-2.png" alt="" />
                                        </div>
                                        <div className="twm-content">
                                            <div className="tw-count-number text-clr-pink">
                                                <span className="counter">
                                                    <CountUp end={98} duration={10} />
                                                </span> +
                                            </div>
                                            <p className="icon-content-info">Job For Countries </p>
                                        </div>
                                    </div> */}
                  {/*icon-block-3*/}
                  {/* <div className="twm-bnr-blocks-3 twm-bnr-blocks-position-3">
                                        <div className="twm-pics">
                                            <span><JobZImage src="images/main-slider/slider1/user/u-1.jpg" alt="" /></span>
                                            <span><JobZImage src="images/main-slider/slider1/user/u-2.jpg" alt="" /></span>
                                            <span><JobZImage src="images/main-slider/slider1/user/u-3.jpg" alt="" /></span>
                                            <span><JobZImage src="images/main-slider/slider1/user/u-4.jpg" alt="" /></span>
                                            <span><JobZImage src="images/main-slider/slider1/user/u-5.jpg" alt="" /></span>
                                            <span><JobZImage src="images/main-slider/slider1/user/u-6.jpg" alt="" /></span>
                                        </div>
                                        <div className="twm-content">
                                            <div className="tw-count-number text-clr-green">
                                                <span className="counter">
                                                    <CountUp end={3} duration={10} />
                                                </span>K+
                                            </div>
                                            <p className="icon-content-info">Jobs Done</p>
                                        </div>
                                    </div> */}
                </div>
              </div>
              {/*Samll Ring Left*/}
              <div className="twm-small-ring-l slide-top-animation" />
              <div className="twm-small-ring-2 slide-top-animation" />
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-12">
            <div className="twm-bnr-left-section">
              <div className="twm-bnr-title-small">
                {" "}
                <i className="fa fa-check"></i> Get upcoming job opportunities
                with <span>{process.env.REACT_APP_COMPANY_NAME}</span>
              </div>
              {!isliveJobCountLoading ? ( 
                <div class="twm-bnr-title-small">We Have <span class="site-text-primary">{liveJobCount.liveJobs}</span> Open Positions</div>
              ) : (
                <TableLoader />
              )}
              <div className="twm-bnr-title-large">
                {process.env.REACT_APP_COMPANY_NAME} Hourly <span>Job</span> Platform
              </div>
              <div className="twm-bnr-discription">
                {process.env.REACT_APP_COMPANY_NAME} is a streamlined communication channel between
                customers, agencies, and employees through an all-in-one
                comprehensive mobile application.
              </div>
              <div className="twm-bnr-search-bar">
                <form>
                  <div className="row">
                    {/*Title*/}
                    {/* <div className="form-group col-xl-3 col-lg-6 col-md-6">
                                            <label>What</label>
                                            <select className="wt-search-bar-select selectpicker" data-live-search="true" title="" id="j-Job_Title" data-bv-field="size">
                                                <option disabled value="" >Select Category</option>
                                                <option>Job Title</option>
                                                <option>Web Designer</option>
                                                <option>Developer</option>
                                                <option>Acountant</option>
                                            </select>
                                        </div> */}
                    {/*All Category*/}
                    <div className="form-group col-xl-4 col-lg-4 col-md-4">
                      {/* <label>Type</label> */}
                      <h4 class="section-head-small">Type</h4>
                      {jobTypes?.length > 0 && (
                        <select
                          className="wt-search-bar-select selectpicker"
                          data-live-search="true"
                          data-bv-field="size"
                          value={selectedJobType?.jobTypes || ""}
                          onChange={(e) => handleJobTypeSelect(e.target.value)}
                        >
                          <option value="All">All</option>
                          {jobTypes?.map((type, index) => (
                            <option key={index} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                    {/*Location*/}
                    <div className="form-group col-xl-4 col-lg-4 col-md-4 city-dropdown-color-home">
                      {/* <label>Location</label> */}
                      <h4 class="section-head-small">Location</h4>
                      {cityList?.length > 0 && (
                        <select
                          className="selectpicker"
                          data-live-search="true"
                          data-bv-field="size"
                          value={selectedCity?.cityList || ""}
                          onChange={(e) => handleCitySelect(e.target.value)}
                        >
                          <option value="All">Select Location</option>
                          {cityList?.map((type, index) => (
                            <option key={index} value={JSON.stringify(type)}>
                              {type.city} ({type.adminName})
                            </option>
                          ))}
                        </select>
                      )}
                      {/* <div className="twm-inputicon-box">
                        <GooglePlacesAutocomplete
                          apiKey={GOOGLE_API}
                          onPlaceSelected={handlePlaceSelect}
                          placeholder="Search..."
                          className="form-control"
                          types={["geocode"]}
                          options={{
                            types: ["address"],
                            componentRestrictions: { country: "us" },
                          }}
                        />
                        <i className="twm-input-icon fas fa-map-marker-alt" />
                      </div> */}
                    </div>
                    {/*Find job btn*/}
                    <div className="form-group col-xl-4 col-lg-4 col-md-4">
                      <button
                        type="button"
                        className="site-button"
                        onClick={findFilteredJob}
                        // disabled={isAddressSelected}
                      >
                        Find Job
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              {popularSearch?.length > 0 && (<div className="twm-bnr-popular-search">
                <span className="twm-title">Trending Professions:</span>
                <span>{popularSearch}</span>
              </div>
              )}
            </div>
          </div>
          {/*right Section*/}
        </div>
        {/* <div className="twm-gradient-text">Jobs</div> */}
      </div>
      {/*Banner End*/}
      {/* HOW IT WORK SECTION START */}
      {/* <div className="section-full p-t120 p-b90 site-bg-white twm-how-it-work-area">
        <div className="container">
          <div className="section-head center wt-small-separator-outer">
            <div className="wt-small-separator site-text-primary">
              <div>Working Process</div>
            </div>
            <h2 className="wt-title">How It Works</h2>
          </div>
          <div className="twm-how-it-work-section">
            <div className="row">
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="twm-w-process-steps">
                  <span className="twm-large-number">01</span>
                  <div className="twm-w-pro-top bg-clr-sky">
                    <div className="twm-media">
                      <span>
                        <JobZImage
                          src="images/work-process/icon1.png"
                          alt="icon1"
                        />
                      </span>
                    </div>
                    <h4 className="twm-title">
                      Register
                      <br />
                      Your Account
                    </h4>
                  </div>
                  <p>
                    You need to create an account to find the best and preferred
                    job.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="twm-w-process-steps">
                  <span className="twm-large-number">02</span>
                  <div className="twm-w-pro-top bg-clr-pink">
                    <div className="twm-media">
                      <span>
                        <JobZImage
                          src="images/work-process/icon2.png"
                          alt="icon1"
                        />
                      </span>
                    </div>
                    <h4 className="twm-title">
                      Apply <br />
                      For Dream Job
                    </h4>
                  </div>
                  <p>
                    You need to create an account to find the best and preferred
                    job.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="twm-w-process-steps">
                  <span className="twm-large-number">03</span>
                  <div className="twm-w-pro-top bg-clr-green">
                    <div className="twm-media">
                      <span>
                        <JobZImage
                          src="images/work-process/icon3.png"
                          alt="icon1"
                        />
                      </span>
                    </div>
                    <h4 className="twm-title">
                      Upload <br />
                      Your Resume
                    </h4>
                  </div>
                  <p>
                    You need to create an account to find the best and preferred
                    job.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* HOW IT WORK SECTION END */}
      {/* JOBS CATEGORIES SECTION START */}
      {console.log("featureJobList--------",featureJobList)}
      <div className="feature_job">
        <h2>Featured Jobs</h2>

        <span>
          <div className="container">
            {isFeatureLoading ? (
              ''
              // <Spinner />
            ) : featureJobList?.length > 0 ? (
              <div className="row">
                {featureJobList?.map((item, index) => {
                  if (index <= 1) {
                    return (
                      <div className="col-lg-4">
                        <div className="feature_job_box">
                          <span>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="pull-left">
                                  {item?.multipleDayJob
                                    ? "Multiday"
                                    : "Singleday"}
                                </div>
                                <div className="pull-right">
                                  {getTimeDifference(item?.createdDate)}
                                </div>
                              </div>
                            </div>
                            <div className="company">
                              {/* <img src="assets/images/company_logo.png" /> */}
                              <GetProfileImage 
                              id={item?.institutionEntityId}
                              />
                              <div className="content">
                                <p>{item?.jobTitle}</p>
                                <div>{item?.institutionName}</div>
                                <h5>
                                  {formatAddress(
                                    item?.address,
                                    item?.city,
                                    item?.state,
                                    item?.zipCode
                                  )}
                                </h5>
                              </div>
                            </div>
                            <hr />
                            <h3>{item?.jobPosition}</h3>
                            <p className="twm-job-address">
                              {displayJobTime(
                                item?.startTime,
                                item?.endTime,
                                item?.multipleDayJob
                              )}
                            </p>
                            <p>
                              <strong>
                                ${(item?.salary / 100)?.toFixed(2)}/
                              </strong>{" "}
                              hr
                              <a
                                className="twm-jobs-browse site-text-primary job-details"
                                style={{ float: "right" }}
                                onClick={() => navigateToJobDetails(item)}
                              >
                                View Details
                              </a>
                            </p>
                          </span>
                          <i className="fa fa-check"></i>
                        </div>
                      </div>
                    );
                  } else if (index == 2) {
                    console.log("index------", index);
                    return <div className="col-lg-4"></div>;
                  }
                })}

                {featureJobList?.map((item, index) => {
                  if (index > 1) {
                    return (
                      <div className="col-lg-4">
                        <div className="feature_job_box">
                          <span>
                            <div className="row">
                              <div className="col-sm-12">
                                <div className="pull-left">
                                  {item?.multipleDayJob
                                    ? "Multiday"
                                    : "Singleday"}
                                </div>
                                <div className="pull-right">
                                  {getTimeDifference(item?.createdDate)}
                                </div>
                              </div>
                            </div>
                            <div className="company">
                              {/* <img src="assets/images/company_logo.png" /> */}
                              <GetProfileImage 
                                id={item?.institutionEntityId}
                                style={{height: '60px', width: '60px', borderRadius: '50px'}} 
                                />
                                <div className="content">
                                <p>{item?.jobTitle}</p>
                                <div>{item?.institutionName}</div>
                                <h5>
                                  {formatAddress(
                                    item?.address,
                                    item?.city,
                                    item?.state,
                                    item?.zipCode
                                  )}
                                </h5>
                              </div>
                            </div>
                            <hr />
                            <h3>{item?.jobPosition}</h3>
                            <p className="twm-job-address">
                              {displayJobTime(
                                item?.startTime,
                                item?.endTime,
                                item?.multipleDayJob
                              )}
                            </p>
                            <p>
                              <strong>
                                ${(item?.salary / 100)?.toFixed(2)}/
                              </strong>{" "}
                              hr
                              <a
                                className="twm-jobs-browse site-text-primary job-details"
                                style={{ float: "right" }}
                                onClick={() => navigateToJobDetails(item)}
                              >
                                View Details
                              </a>
                            </p>
                          </span>
                          <i className="fa fa-check"></i>
                        </div>
                      </div>
                    );
                  }
                })}
                <img
                  src="assets/images/feature_job.png"
                  className="bgDocter_img"
                />
              </div>
            ) : (
              "No Featured Jobs Right Now – Check Back Soon for New Opportunities!"
            )}
            {/* <div className="row">
              <div className="col-lg-4">
                <div className="feature_job_box">
                  <span>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="pull-left">Daily</div>
                        <div className="pull-right">1 Day ago</div>
                      </div>
                    </div>
                    <div className="company">
                      <img src="assets/images/company_logo.png" />
                      <div className="content">
                        <h3>BWE Contractors</h3>
                        <p>Houston, TX </p>
                      </div>
                    </div>
                    <hr />
                    <h3>Construction Worker</h3>
                    <p>
                      <strong>$400/</strong> Day
                    </p>
                  </span>
                  <i className="fa fa-check"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="feature_job_box">
                  <span>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="pull-left">Daily</div>
                        <div className="pull-right">1 Day ago</div>
                      </div>
                    </div>
                    <div className="company">
                      <img src="assets/images/company_logo.png" />
                      <div className="content">
                        <h3>BWE Contractors</h3>
                        <p>Houston, TX </p>
                      </div>
                    </div>
                    <hr />
                    <h3>Construction Worker</h3>
                    <p>
                      <strong>$400/</strong> Day
                    </p>
                  </span>
                  <i className="fa fa-check"></i>
                </div>
              </div>

              <div className="col-lg-4"></div>

              <div className="col-lg-4">
                <div className="feature_job_box">
                  <span>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="pull-left">Daily</div>
                        <div className="pull-right">1 Day ago</div>
                      </div>
                    </div>
                    <div className="company">
                      <img src="assets/images/company_logo.png" />
                      <div className="content">
                        <h3>BWE Contractors</h3>
                        <p>Houston, TX </p>
                      </div>
                    </div>
                    <hr />
                    <h3>Construction Worker</h3>
                    <p>
                      <strong>$400/</strong> Day
                    </p>
                  </span>
                  <i className="fa fa-check"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="feature_job_box">
                  <span>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="pull-left">Daily</div>
                        <div className="pull-right">1 Day ago</div>
                      </div>
                    </div>
                    <div className="company">
                      <img src="assets/images/company_logo.png" />
                      <div className="content">
                        <h3>BWE Contractors</h3>
                        <p>Houston, TX </p>
                      </div>
                    </div>
                    <hr />
                    <h3>Construction Worker</h3>
                    <p>
                      <strong>$400/</strong> Day
                    </p>
                  </span>
                  <i className="fa fa-check"></i>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="feature_job_box">
                  <span>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="pull-left">Daily</div>
                        <div className="pull-right">1 Day ago</div>
                      </div>
                    </div>
                    <div className="company">
                      <img src="assets/images/company_logo.png" />
                      <div className="content">
                        <h3>BWE Contractors</h3>
                        <p>Houston, TX </p>
                      </div>
                    </div>
                    <hr />
                    <h3>Construction Worker</h3>
                    <p>
                      <strong>$400/</strong> Day
                    </p>
                  </span>
                  <i className="fa fa-check"></i>
                </div>
              </div>
              <img
                src="assets/images/feature_job.png"
                className="bgDocter_img"
              />
            </div> */}
          </div>
        </span>
      </div>
      <div className="section-full p-t120 p-b90 site-bg-gray twm-job-categories-area">
        <div className="container">
          <div className="wt-separator-two-part">
            <div className="row wt-separator-two-part-row">
              <div className="col-xl-5 col-lg-5 col-md-12 wt-separator-two-part-left">
                {/* title="" START*/}
                <div className="section-head left wt-small-separator-outer">
                  <div className="wt-small-separator site-text-primary">
                    <div>Jobs by Profession</div>
                  </div>
                  <h2 className="wt-title">Find Your Ideal Role</h2>
                </div>
                {/* title="" END*/}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-right">
                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the  when an printer took.</p> */}
              </div>
            </div>
          </div>
          {!isCategoryLoading ? (
          Object.keys(jobCount)?.length > 0 ? (
            <div className="section-content">
              <div className="twm-blog-post-1-outer-wrap">
                <div className="owl-carousel twm-la-home-blog owl-btn-bottom-center">
                {Object.entries(jobCount)
                  .filter(([jobTitle, count]) => jobTitle !== "") // Filter out items with an empty key
                  .map(([jobTitle, count], index) => (
                    <div key={index} className="item" style={{ cursor: "pointer" }} 
                      onClick={() => navigateToJobList(jobTitle)}>
                      <div className="job-categories-block">
                        <div className="twm-media">
                          <div className="flaticon-dashboard" />
                        </div>
                        <div className="twm-content">
                          {console.log("count:::::", count, jobTitle)}
                          <div className="twm-jobs-available">{count}</div>
                          <p>{jobTitle}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            "Currently, there are no professions available. Please check back later!"
          )
        ) : (
          <TableLoader />
        )}
        </div>
      </div>
      <div className="build_profile_block">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <img
                src="assets/images/build_profile.png"
                className="img-responsive"
              />
            </div>

            <div className="col-lg-7">
              <div className="builder_box">
                <h2>Build your personal account profile</h2>
                {/* <p>
                  Create an account to find the best and preferred job. Lorem
                  ipsum dolor sit amet, consectetur adipiscing elit, sed do
                  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                  enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat.{" "}
                </p> */}
                <NavLink to={"/sign-up"}>
                  <button className="btn site-button">
                    Join the {process.env.REACT_APP_COMPANY_NAME} Network <i class="fa fa-upload"></i>
                  </button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* JOBS CATEGORIES SECTION END */}
      {/* EXPLORE NEW LIFE START */}
      {/* <div
        className="section-full p-t120 p-b120 twm-explore-area bg-cover "
      >
        <div className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="twm-explore-media-wrap">
                  <div className="twm-media">
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="twm-explore-content-outer">
                  <div className="twm-explore-content">
                    <div className="twm-l-line-1" />
                    <div className="twm-l-line-2" />
                    <div className="twm-r-circle-1" />
                    <div className="twm-r-circle-2" />
                    <div className="twm-title-small">Explore New Life</div>
                    <div className="twm-title-large">
                      <h2>
                        Don’t just find. be found put your CV in front of great
                        employers{" "}
                      </h2>
                    </div>
                    <div className="twm-upload-file">
                      <button type="button" className="site-button">
                        Upload Your Resume <i className="feather-upload" />
                      </button>
                    </div>
                  </div>
                  <div className="twm-bold-circle-right" />
                  <div className="twm-bold-circle-left" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* EXPLORE NEW LIFE END */}
      {/* TOP COMPANIES START */}
      {/* <div className="section-full p-t120  site-bg-white twm-companies-wrap">
        <div className="section-head center wt-small-separator-outer">
          <div className="wt-small-separator site-text-primary">
            <div>Top Companies</div>
          </div>
          <h2 className="wt-title">Get hired in top companies</h2>
        </div>
        <div className="container">
          <div className="section-content">
            <div className="owl-carousel home-client-carousel2 owl-btn-vertical-center">
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w1.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w2.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w3.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w4.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w5.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w6.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w1.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w2.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w3.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="ow-client-logo">
                  <div className="client-logo client-logo-media">
                    <NavLink to={publicUser.employer.LIST}>
                      <JobZImage src="images/client-logo/w5.png" alt="" />
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="twm-company-approch-outer">
          <div className="twm-company-approch">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="counter-outer-two">
                  <div className="icon-content">
                    <div className="tw-count-number text-clr-sky">
                      <span className="counter">
                        <CountUp end={5} duration={10} />
                      </span>
                      M+
                    </div>
                    <p className="icon-content-info">
                      Million daily active users
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="counter-outer-two">
                  <div className="icon-content">
                    <div className="tw-count-number text-clr-pink">
                      <span className="counter">
                        <CountUp end={9} duration={10} />
                      </span>
                      K+
                    </div>
                    <p className="icon-content-info">Open job positions</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="counter-outer-two">
                  <div className="icon-content">
                    <div className="tw-count-number text-clr-green">
                      <span className="counter">
                        <CountUp end={2} duration={10} />
                      </span>
                      M+
                    </div>
                    <p className="icon-content-info">Million stories shared</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* TOP COMPANIES END */}
      {/* JOB POST START */}
      {/* <div className="section-full p-t120 p-b90 site-bg-light-purple twm-bg-ring-wrap"> */}
      {/* <div className="twm-bg-ring-right" /> */}
      {/* <div className="twm-bg-ring-left" /> */}
      {/* <div className="container">
                    {/* title="" START*/}
      {/* <div className="section-head center wt-small-separator-outer">
                        <div className="wt-small-separator site-text-primary">
                            <div>All Jobs Post</div>
                        </div>
                        <h2 className="wt-title">Find Your Career You Deserve it</h2>
                    </div>
                    {/* title="" END*/}
      {/* <div className="section-content">
                        <div className="twm-jobs-list-wrap">
                            <ul>
                                <li>
                                    <div className="twm-jobs-list-style1 mb-5">
                                        <div className="twm-media">
                                            <JobZImage src="images/jobs-company/pic1.jpg" alt="#" />
                                        </div>
                                        <div className="twm-mid-content">
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                                <h4>Senior Web Designer , Developer  <span className="twm-job-post-duration">/ 1 days ago</span></h4>
                                            </NavLink>
                                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                        </div>
                                        <div className="twm-right-content">
                                            <div className="twm-jobs-category green"><span className="twm-bg-green">New</span></div>
                                            <div className="twm-jobs-amount">$2500 <span>/ Month</span></div>
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="twm-jobs-list-style1 mb-5">
                                        <div className="twm-media">
                                            <JobZImage src="images/jobs-company/pic2.jpg" alt="#" />
                                        </div>
                                        <div className="twm-mid-content">
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                                <h4>Need Senior Rolling Stock Technician<span className="twm-job-post-duration">/ 15 days ago</span></h4>
                                            </NavLink>
                                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                        </div>
                                        <div className="twm-right-content">
                                            <div className="twm-jobs-category green"><span className="twm-bg-brown">Intership</span></div>
                                            <div className="twm-jobs-amount">$2000<span>/ Month</span></div>
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="twm-jobs-list-style1 mb-5">
                                        <div className="twm-media">
                                            <JobZImage src="images/jobs-company/pic3.jpg" alt="#" />
                                        </div>
                                        <div className="twm-mid-content">
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                                <h4 className="twm-job-title">IT Department Manager &amp; Blogger-Entrepenour  <span className="twm-job-post-duration">/ 6 Month ago</span></h4>
                                            </NavLink>
                                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                        </div>
                                        <div className="twm-right-content">
                                            <div className="twm-jobs-category green"><span className="twm-bg-purple">Fulltime</span></div>
                                            <div className="twm-jobs-amount">$1500 <span>/ Month</span></div>
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="twm-jobs-list-style1 mb-5">
                                        <div className="twm-media">
                                            <JobZImage src="images/jobs-company/pic4.jpg" alt="#" />
                                        </div>
                                        <div className="twm-mid-content">
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                                <h4 className="twm-job-title">Art Production Specialist   <span className="twm-job-post-duration">/ 2 days ago</span></h4>
                                            </NavLink>
                                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                        </div>
                                        <div className="twm-right-content">
                                            <div className="twm-jobs-category green"><span className="twm-bg-sky">Freelancer</span></div>
                                            <div className="twm-jobs-amount">$1200<span>/ Month</span></div>
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="twm-jobs-list-style1 mb-5">
                                        <div className="twm-media">
                                            <JobZImage src="images/jobs-company/pic5.jpg" alt="#" />
                                        </div>
                                        <div className="twm-mid-content">
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-job-title">
                                                <h4 className="twm-job-title">Recreation &amp; Fitness Worker   <span className="twm-job-post-duration">/ 1 days ago</span></h4>
                                            </NavLink>
                                            <p className="twm-job-address">1363-1385 Sunset Blvd Los Angeles, CA 90026, USA</p>
                                            <a href="https://themeforest.net/user/thewebmax/portfolio" className="twm-job-websites site-text-primary">https://thewebmax.com</a>
                                        </div>
                                        <div className="twm-right-content">
                                            <div className="twm-jobs-category green"><span className="twm-bg-golden">Temporary</span></div>
                                            <div className="twm-jobs-amount">$1700 <span>/ Month</span></div>
                                            <NavLink to={publicUser.jobs.DETAIL1} className="twm-jobs-browse site-text-primary">Browse Job</NavLink>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="text-center m-b30">
                                <NavLink to={publicUser.jobs.LIST} className=" site-button">Browse All Jobs</NavLink>
                            </div>
                        </div>
                    </div>
                </div> */}{" "}
      {/* </div> */}
      {/* JOB POST END */}
      {/* TESTIMONIAL SECTION START */}
      {/* <div className="section-full p-t120 p-b90 site-bg-white twm-testimonial-1-area">
        <div className="container">
          <div className="wt-separator-two-part">
            <div className="row wt-separator-two-part-row">
              <div className="col-xl-5 col-lg-6 col-md-12 wt-separator-two-part-left">
                <div className="section-head left wt-small-separator-outer">
                  <div className="wt-small-separator site-text-primary">
                    <div>Clients Testimonials</div>
                  </div>
                  <h2 className="wt-title">What Our Customers Say About Us</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="section-content">
            <div className="owl-carousel twm-testimonial-1-carousel owl-btn-bottom-center ">
              <div className="item ">
                <div className="twm-testimonial-1">
                  <div className="twm-testimonial-1-content">
                    <div className="twm-testi-media">
                      <JobZImage src="images/testimonials/pic-1.png" alt="#" />
                    </div>
                    <div className="twm-testi-content">
                      <div className="twm-quote">
                        <JobZImage src="images/quote-dark.png" alt="" />
                      </div>
                      <div className="twm-testi-info">
                        I just got a job that I applied for via careerfy! I used
                        the site all the time during my job hunt.
                      </div>
                      <div className="twm-testi-detail">
                        <div className="twm-testi-name">Nikola Tesla</div>
                        <div className="twm-testi-position">Accountant</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item ">
                <div className="twm-testimonial-1">
                  <div className="twm-testimonial-1-content">
                    <div className="twm-testi-media">
                      <JobZImage src="images/testimonials/pic-2.png" alt="#" />
                    </div>
                    <div className="twm-testi-content">
                      <div className="twm-quote">
                        <JobZImage src="images/quote-dark.png" alt="" />
                      </div>
                      <div className="twm-testi-info">
                        I just got a job that I applied for via careerfy! I used
                        the site all the time during my job hunt.
                      </div>
                      <div className="twm-testi-detail">
                        <div className="twm-testi-name">Nikola Tesla</div>
                        <div className="twm-testi-position">Accountant</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item ">
                <div className="twm-testimonial-1">
                  <div className="twm-testimonial-1-content">
                    <div className="twm-testi-media">
                      <JobZImage src="images/testimonials/pic-3.png" alt="#" />
                    </div>
                    <div className="twm-testi-content">
                      <div className="twm-quote">
                        <JobZImage src="images/quote-dark.png" alt="" />
                      </div>
                      <div className="twm-testi-info">
                        I just got a job that I applied for via careerfy! I used
                        the site all the time during my job hunt.
                      </div>
                      <div className="twm-testi-detail">
                        <div className="twm-testi-name">Nikola Tesla</div>
                        <div className="twm-testi-position">Accountant</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item ">
                <div className="twm-testimonial-1">
                  <div className="twm-testimonial-1-content">
                    <div className="twm-testi-media">
                      <JobZImage src="images/testimonials/pic-2.png" alt="#" />
                    </div>
                    <div className="twm-testi-content">
                      <div className="twm-quote">
                        <JobZImage src="images/quote-dark.png" alt="" />
                      </div>
                      <div className="twm-testi-info">
                        I just got a job that I applied for via careerfy! I used
                        the site all the time during my job hunt.
                      </div>
                      <div className="twm-testi-detail">
                        <div className="twm-testi-name">Nikola Tesla</div>
                        <div className="twm-testi-position">Accountant</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item ">
                <div className="twm-testimonial-1">
                  <div className="twm-testimonial-1-content">
                    <div className="twm-testi-media">
                      <JobZImage src="images/testimonials/pic-1.png" alt="#" />
                    </div>
                    <div className="twm-testi-content">
                      <div className="twm-quote">
                        <JobZImage src="images/quote-dark.png" alt="" />
                      </div>
                      <div className="twm-testi-info">
                        I just got a job that I applied for via careerfy! I used
                        the site all the time during my job hunt.
                      </div>
                      <div className="twm-testi-detail">
                        <div className="twm-testi-name">Nikola Tesla</div>
                        <div className="twm-testi-position">Accountant</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* TESTIMONIAL SECTION END */}
      {/* OUR BLOG START */}
      {/* <div className="section-full p-t120 p-b90 site-bg-gray">
        <div className="container">
          <div className="section-head center wt-small-separator-outer">
            <div className="wt-small-separator site-text-primary">
              <div>Our Blogs</div>
            </div>
            <h2 className="wt-title">Latest Article</h2>
          </div>
          <div className="section-content">
            <div className="twm-blog-post-1-outer-wrap">
              <div className="owl-carousel twm-la-home-blog owl-btn-bottom-center">
                <div className="item">
                  <div className="blog-post twm-blog-post-1-outer">
                    <div className="wt-post-media">
                      <NavLink to={publicUser.blog.DETAIL}>
                        <JobZImage src="images/defaultUserPic2.png" alt="" />
                      </NavLink>
                    </div>
                    <div className="wt-post-info">
                      <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date">March 05, 2023</li>
                          <li className="post-author">
                            By{" "}
                            <NavLink to={publicUser.candidate.DETAIL1}>
                              Mark Petter
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title ">
                        <h4 className="post-title">
                          <NavLink to={publicUser.blog.DETAIL}>
                            How to convince recruiters and get your dream job
                          </NavLink>
                        </h4>
                      </div>
                      <div className="wt-post-text ">
                        <p>
                          New chip traps clusters of migrating tumor cells
                          asperiortenetur, blanditiis odit.
                        </p>
                      </div>
                      <div className="wt-post-readmore ">
                        <NavLink
                          to={publicUser.blog.DETAIL}
                          className="site-button-link site-text-primary"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post twm-blog-post-1-outer">
                    <div className="wt-post-media">
                      <NavLink to={publicUser.blog.DETAIL}>
                        <JobZImage src="images/defaultUserPic2.png" alt="" />
                      </NavLink>
                    </div>
                    <div className="wt-post-info">
                      <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date">March 05, 2023</li>
                          <li className="post-author">
                            By{" "}
                            <NavLink to={publicUser.candidate.DETAIL1}>
                              David Wish
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title ">
                        <h4 className="post-title">
                          <NavLink to={publicUser.blog.DETAIL}>
                            5 things to know about the March 2023 jobs report
                          </NavLink>
                        </h4>
                      </div>
                      <div className="wt-post-text ">
                        <p>
                          New chip traps clusters of migrating tumor cells
                          asperiortenetur, blanditiis odit.
                        </p>
                      </div>
                      <div className="wt-post-readmore ">
                        <NavLink
                          to={publicUser.blog.DETAIL}
                          className="site-button-link site-text-primary"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post twm-blog-post-1-outer">
                    <div className="wt-post-media">
                      <NavLink to={publicUser.blog.DETAIL}>
                        <JobZImage src="images/defaultUserPic2.png" alt="" />
                      </NavLink>
                    </div>
                    <div className="wt-post-info">
                      <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date">March 05, 2023</li>
                          <li className="post-author">
                            By{" "}
                            <NavLink to={publicUser.candidate.DETAIL1}>
                              Mike Doe
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title ">
                        <h4 className="post-title">
                          <NavLink to={publicUser.blog.DETAIL}>
                            Job Board is the most important sector in the world
                          </NavLink>
                        </h4>
                      </div>
                      <div className="wt-post-text ">
                        <p>
                          New chip traps clusters of migrating tumor cells
                          asperiortenetur, blanditiis odit.
                        </p>
                      </div>
                      <div className="wt-post-readmore ">
                        <NavLink
                          to={publicUser.blog.DETAIL}
                          className="site-button-link site-text-primary"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post twm-blog-post-1-outer">
                    <div className="wt-post-media">
                      <NavLink to={publicUser.blog.DETAIL}>
                        <JobZImage src="images/defaultUserPic2.png" alt="" />
                      </NavLink>
                    </div>
                    <div className="wt-post-info">
                      <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date">March 05, 2023</li>
                          <li className="post-author">
                            By{" "}
                            <NavLink to={publicUser.candidate.DETAIL1}>
                              Mark Petter
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title ">
                        <h4 className="post-title">
                          <NavLink to={publicUser.blog.DETAIL}>
                            How to convince recruiters and get your dream job
                          </NavLink>
                        </h4>
                      </div>
                      <div className="wt-post-text ">
                        <p>
                          New chip traps clusters of migrating tumor cells
                          asperiortenetur, blanditiis odit.
                        </p>
                      </div>
                      <div className="wt-post-readmore ">
                        <NavLink
                          to={publicUser.blog.DETAIL}
                          className="site-button-link site-text-primary"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post twm-blog-post-1-outer">
                    <div className="wt-post-media">
                      <NavLink to={publicUser.blog.DETAIL}>
                        <JobZImage src="images/defaultUserPic2.png" alt="" />
                      </NavLink>
                    </div>
                    <div className="wt-post-info">
                      <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date">March 05, 2023</li>
                          <li className="post-author">
                            By{" "}
                            <NavLink to={publicUser.candidate.DETAIL1}>
                              David Wish
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title ">
                        <h4 className="post-title">
                          <NavLink to={publicUser.blog.DETAIL}>
                            5 things to know about the March 2023 jobs report
                          </NavLink>
                        </h4>
                      </div>
                      <div className="wt-post-text ">
                        <p>
                          New chip traps clusters of migrating tumor cells
                          asperiortenetur, blanditiis odit.
                        </p>
                      </div>
                      <div className="wt-post-readmore ">
                        <NavLink
                          to={publicUser.blog.DETAIL}
                          className="site-button-link site-text-primary"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="blog-post twm-blog-post-1-outer">
                    <div className="wt-post-media">
                      <NavLink to={publicUser.blog.DETAIL}>
                        <JobZImage src="images/defaultUserPic2.png" alt="" />
                      </NavLink>
                    </div>
                    <div className="wt-post-info">
                      <div className="wt-post-meta ">
                        <ul>
                          <li className="post-date">March 05, 2023</li>
                          <li className="post-author">
                            By{" "}
                            <NavLink to={publicUser.candidate.DETAIL1}>
                              Mike Doe
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                      <div className="wt-post-title ">
                        <h4 className="post-title">
                          <NavLink to={publicUser.blog.DETAIL}>
                            Job Board is the most important sector in the world
                          </NavLink>
                        </h4>
                      </div>
                      <div className="wt-post-text ">
                        <p>
                          New chip traps clusters of migrating tumor cells
                          asperiortenetur, blanditiis odit.
                        </p>
                      </div>
                      <div className="wt-post-readmore ">
                        <NavLink
                          to={publicUser.blog.DETAIL}
                          className="site-button-link site-text-primary"
                        >
                          Read More
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* OUR BLOG END */}
      {isLoading && (
  <div style={{ 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    position: 'fixed', 
    top: 0, 
    left: 0, 
    width: '100%', 
    height: '100%', 
    background: '#fff', 
    zIndex: 9999 
  }}>
    <Spinner />
  </div>
)}
    </>
  );
}

export default Home1Page;
