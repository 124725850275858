import { useSelector } from "react-redux";
import { useState } from "react";
import Toast from "../toast";
import JobReviewRate from "../../pannels/public-user/components/jobs/jobRatingReview";
import { useNavigate } from "react-router-dom";
function JobReviewPopUp({
  jobDetailsInfo,
  jobDetailId,
  // posterRating,
  jobRating,
  currentPage,
  setCurrentPage,
  fetchjobList,
  userEntityId,
  hiredCandidatesList,
  getHiredCandidatesList,
  getJobDetailsData
  //jobReview
}) {
  const navigate = useNavigate()
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  console.log("jobDetailId-----",jobDetailId)

  const handleClosePopup = () => {
    setTimeout(()=>{
      const modal = document.getElementById("job-review-popup");
      const modalInstance = window.bootstrap.Modal.getInstance(modal);
      modalInstance.hide();
      //If only one applicant job finished then navigate to history page
      if(hiredCandidatesList?.length === 1){
        navigate("/history-job")
      }
    },2000)
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };
  const displaySuccessMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: true,
    });
  };
  return (
    <>
      <div
        className="modal fade twm-sign-up"
        id="job-review-popup"
        aria-hidden="true"
        aria-labelledby="sign_up_popupLabel"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <form>
              <div className="modal-header">
                <h2 className="modal-title" id="sign_up_popupLabel">
                  Write Review & Rating{" "}
                </h2>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div className="twm-tabs-style-2">
                  <JobReviewRate
                    jobDetailsInfo={jobDetailsInfo}
                    jobDetailId={jobDetailId}
                    jobRating={jobRating}
                    handleClosePopup={handleClosePopup}
                    currentPage={currentPage}
                    fetchjobList={fetchjobList}
                    setCurrentPage={setCurrentPage}
                    displayErrMsg={displayErrMsg}
                    displaySuccessMsg={displaySuccessMsg}
                    userEntityId={userEntityId}
                    hiredCandidatesList={hiredCandidatesList}
                    getHiredCandidatesList={getHiredCandidatesList}
                    getJobDetailsData={getJobDetailsData}
                    //jobReview={jobReview}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message !== "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default JobReviewPopUp;
