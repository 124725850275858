import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Toast from "../../../../common/toast";
import SessionExpired from "../../../../common/sessionExpired";
import apiCall from "../../../../../apicall";
import ClockInClockOutAdmin from "./clockin-clockout-admin";
import moment from "moment";
import Spinner from "../../../../common/spinner";
import ClockinClockoutPopup from "./clockin-clockout-popup";
import TableLoader from "../../../../common/tableLoader";
import ChatJobPopup from "./popup-chat";


const ClockInClockOut = ({ jobDetailsInfo, getJobDetailsData, hiredCandidatesLoader, hiredCandidatesList, approveAndFinishUserEntityId }) => {
  const { TOKEN, role_type } = useSelector((state) => state.menu);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [clockinClockoutLoader, setClockinClockoutLoader] = useState(false);
  const [isClockInLoading, setIsClockInLoading] = useState(false);
  const [isClockOutLoading, setIsClockoutLoading] = useState(false);
  const [clockinClockoutList, setClockInClockOutList] = useState([]);
  const [lastClockinClockoutItem, setLastClockinClockoutItem] = useState({});
  const [chatShow, setChatShow]=useState(false)
  const[selectedClockinClockout, setSelectedClockinClockout] = useState([]);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  // console.log("hhhhh", jobDetailsInfo?.totalHours)
  console.log("hiredCandidatesList", jobDetailsInfo, hiredCandidatesList)
  const [updatedJobDetailInfo, setUpdatedJobDetailInfo] = useState(jobDetailsInfo)
  // let updatedJobDetailInfo=jobDetailsInfo
  // const approveBtnRef = useRef(null);
  
    const handleApproveAndFinish = (item) => {
      console.log("approveAndFinishUserEntityId",item)
       approveAndFinishUserEntityId(item)
      // Check the clockInStatus
      // if (jobEntity.clockInStatus === "Clocked In") {
      //   displayAppliedApplicantErrMsg("Job seeker must clock out before finishing the job.");
      //   return;
      // }
      // if (approveBtnRef.current) {
      //   approveBtnRef.current.setAttribute("data-bs-toggle", "modal");
      //   approveBtnRef.current.click();
      // }
    };

  const calculateTotalWorkingHour = (jobDetails_Data) => {
    const startDate = moment.utc(jobDetails_Data?.startTime); // Assuming start date is April 11, 2024, at 12:00 PM
    const endDate = moment.utc(jobDetails_Data?.endTime); // Assuming end date is April 12, 2024, at 2:30 PM
    const diffDuration = moment.duration(endDate.diff(startDate));
    const hours = diffDuration.hours();
    const minutes = diffDuration.minutes();
    return hours + " hours " + minutes + " minutes";
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };

  const handleEditClick = (record) =>{
      console.log("recordd=======",record)
      setSelectedClockinClockout(record)
  }

  const handleDeleteClick = (record) =>{
    console.log("recordd=======",record)
    setSelectedClockinClockout(record)
}

  const displayClockinoutErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
  };
  const ConvertInto_Hrs = (hrs) => {
    var num = hrs
    console.log("num:::::", num)
    var str = num.toString();
    var numarray = str.split('.');
    var a = new Array();
    a = numarray;
    let minuteVal = Number(a[1]) / 60
    let strFnl = a[0] + (minuteVal.toString()).substring(1)
    return Number(strFnl).toFixed(2);
  }

  const getClockinClockout = async (hiredCandidatesId) => {
    console.log("jobDetailsInfo?.hirePerson", jobDetailsInfo?.hirePerson)
    console.log("jobDetailsInfojobDetailsInfo", jobDetailsInfo)
    try {
      setClockinClockoutLoader(true);
      const url = `${role_type.includes('ROLE_USER') ?
        `jobs/${jobDetailsInfo?.id}/clockinClockouts` :
        (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
          ? `openstaff/jobs/${jobDetailsInfo?.id}/applicantClockinClockouts`
          : role_type.includes("ROLE_INSTITUTION_ADMIN") ?
          (jobDetailsInfo?.hirePerson ? 
            `institutions/jobs/${jobDetailsInfo?.id}/applicantClockinClockouts` :
            `institutions/jobs/${jobDetailsInfo?.id}/applicantClockinClockouts/${hiredCandidatesId}`)
            : ""
      }`;

      // const url = `jobs/${jobDetailsInfo?.id}/clockinClockouts`;
      const clockinClockoutResponse = await apiCall(url, "GET", null, TOKEN);
      console.log("add clockin clockout response", clockinClockoutResponse);
      if (clockinClockoutResponse?.status === 200) {
        const clockinoutres = clockinClockoutResponse.data.content;
        setClockinClockoutLoader(false);
        setClockInClockOutList(clockinoutres);
        if (clockinoutres?.length > 0) {
          setLastClockinClockoutItem(clockinoutres[clockinoutres.length - 1]);
        }
      } else if (clockinClockoutResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
        setSessionExpired(true);
        setClockinClockoutLoader(false);
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(clockinClockoutResponse?.response?.data?.message);
        setClockinClockoutLoader(false);
      }
      // Clear the map and address
    } catch (err) {
      setSessionExpired(false);
      setClockinClockoutLoader(false);

      displayErrMsg("Something went wrong!");
    }
  };

  const clockinClick = async (timeinout) => {
    const url = `openstaff/jobs/${jobDetailsInfo?.id}/clockIn/${jobDetailsInfo?.hirePerson?.id}`;

    const clockinData = {
      // arrivalTime: moment.utc(new Date()).format('yyyy-MM-DD HH:mm:ss'),
      arrivalTime: moment.utc(timeinout).format('yyyy-MM-DD HH:mm:ss'),
      // location: { latitude: 29.749907, longitude: -95.358421 },
      notes: "",
    };

    console.log("clockin param",clockinData)

    try {
      setIsClockInLoading(true);
      const clockinResponse = await apiCall(url, "PUT", clockinData, TOKEN);
      console.log("clockinResponse--", clockinResponse);
      if (clockinResponse?.status === 200) {
        setIsClockInLoading(false);
        setSessionExpired(false);
        setErrSuccessMsg({
          message: "Clocked in Successfully!",
          status: "Success",
        });
        getJobDetailsData();
        return true;
      } else if (clockinResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
        setSessionExpired(true);
        setIsClockInLoading(false);
        return false;
      } else if (clockinResponse?.response?.status === 409) {
        setSessionExpired(false);
        displayErrMsg(clockinResponse?.response?.data?.errorMessage);
        setIsClockInLoading(false);
        return false;
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(clockinResponse?.response?.data?.message);
        setIsClockInLoading(false);
        return false;
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      setIsClockInLoading(false);
      return false;
    }
  };

  const clockoutClick = async (timeinout) => {
    const url = `openstaff/jobs/${jobDetailsInfo?.id}/clockOut/${jobDetailsInfo?.hirePerson?.id}`;
    const clockoutData = {
      // endTime: moment.utc(new Date()).format('yyyy-MM-DD HH:mm:ss'),
      endTime: moment.utc(timeinout).format('yyyy-MM-DD HH:mm:ss'),
      // location: { latitude: 29.749907, longitude: -95.358421 },
    };

    console.log("clockout param",clockoutData)


    try {
      setIsClockoutLoading(true);
      const clockoutResponse = await apiCall(url, "PUT", clockoutData, TOKEN);
      console.log("clockoutResponse====", clockoutResponse)
      if (clockoutResponse?.status === 200) {
        setIsClockoutLoading(false);
        setSessionExpired(false);
        setErrSuccessMsg({
          message: "Clocked out Successfully!",
          status: "Success",
        });
        getJobDetailsData();
        return true;
      } else if (clockoutResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
        setSessionExpired(true);
        setIsClockoutLoading(false);
        return false;
      } else if (clockoutResponse?.response?.status === 409) {
        setSessionExpired(false);
        displayErrMsg(clockoutResponse?.response?.data?.errorMessage);
        setIsClockoutLoading(false);
        return false;
      } else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(clockoutResponse?.response?.data?.message);
        setIsClockoutLoading(false);
        return false;
      }
    } catch (err) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      setIsClockoutLoading(false);
      return false;
    }
  };

  // Function to check if current time is between startTime and endTime
  const isCurrentTimeBetween = (startTimeISO, endTimeISO) => {
    const currentDate = new Date();
    const startTime = new Date(startTimeISO);
    const endTime = new Date(endTimeISO);
    return currentDate >= startTime && currentDate <= endTime;
  };

  // Function to handle clock-out based on job details
  const handleClockInClockOut = (clockinout_type, timeinout) => {
    const startTime = moment.utc(jobDetailsInfo?.startTime).local();
    const endTime = moment.utc(jobDetailsInfo?.endTime).local();
    const singleDay = moment(startTime).isSame(moment(endTime), "day");

    console.log("singleDay=====", singleDay, clockinout_type)
    // if (isCurrentTimeBetween(startTime, endTime) && singleDay) {
    if (singleDay) {
      // If single-day job, check if current time is between start and end time
      if (clockinout_type === "clockin") {
        return clockinClick(timeinout);
      } else if (clockinout_type === "clockout") {
        return clockoutClick(timeinout);
      }
      // } else if (
      //   !singleDay &&
      //   moment().isBetween(
      //     startTime.startOf("day"),
      //     endTime.endOf("day"),
      //     null,
      //     "[]"
      //   )
      // ) {
    } else if (
      !singleDay
      // moment().isBetween(
      //   startTime.startOf("day"),
      //   endTime.endOf("day"),
      //   null,
      //   "[]"
      // )
    ) {
      // If multi-day job, check if current time is between or equal to start time or end time
      if (clockinout_type === "clockin") {
        return clockinClick(timeinout);
      } else if (clockinout_type === "clockout") {
        return clockoutClick(timeinout);
      }
    }
    // else {
    //   displayClockinoutErrMsg(
    //     `${
    //       clockinout_type === "clockin" ? "Clock in" : "Clock out"
    //     } time must be between ${moment
    //       .utc(jobDetailsInfo?.startTime, "YYYY-MM-DD HH:mm:ss")
    //       .local()
    //       .format("hh:mm A")} and ${moment
    //         .utc(jobDetailsInfo?.endTime, "YYYY-MM-DD HH:mm:ss")
    //         .local()
    //         .format("hh:mm A")}!`
    //   );
    // }
  }

  const handleUpdateClockinClockout =async (formValues, initialValues,selectedClockinClockout) =>{
    const requests = []; // Collect all the requests to send
    // Check if timeIn was updated
    if (formValues.timeIn && !moment(formValues.timeIn).isSame(initialValues?.timeIn)) {
      
      const updatedTimeInData = {
        arrivalTime: moment.utc(formValues.timeIn)
          .format("yyyy-MM-DD HH:mm:ss"),
        // location: {
        //   latitude: 29.749907,
        //   longitude: -95.358421,
        // },
        notes: "",
      };

      console.log("clockin params",updatedTimeInData)

      const timeInRequest = apiCall(
        `openstaff/jobs/${selectedClockinClockout?.checkinData?.id}/updateClockIn`,
        "PUT",
        updatedTimeInData,
        TOKEN
      );
      requests.push(timeInRequest);
    }

    // Check if timeOut was updated
    if (formValues.timeOut && !moment(formValues.timeOut).isSame(initialValues?.timeOut)) {
      const updatedTimeOutData = {
        endTime: moment.utc(formValues.timeOut).format("yyyy-MM-DD HH:mm:ss"),
        // location: { latitude: 29.749907, longitude: -95.358421 },
      };

      console.log("clockout params",updatedTimeOutData)


      const timeOutRequest = apiCall(
        `openstaff/jobs/${selectedClockinClockout?.checkoutData?.id}/updateClockIn`,
        "PUT",
        updatedTimeOutData,
        TOKEN
      );
      requests.push(timeOutRequest);
    }

    // Ensure at least one field is updated
    if (requests.length === 0) {
      displayErrMsg("Please update either Time In or Time Out.");
      return;
    }

    try {
      // Perform all API requests
      const responses = await Promise.all(requests);
      console.log("response of time in time out", responses);
      // Check responses for success or failure
      const allSuccessful = responses.every(
        (response) => response.status === 200
      );
      if (allSuccessful) {
        setErrSuccessMsg({
          message: "Clock-in/Clock-out updated successfully.",
          status: true,
        });
      } else {
        displayErrMsg("Failed to update clock-in/clock-out.");
      }
    } catch (error) {
      displayErrMsg("An error occurred while updating the data.");
    }
}

const handleAppliedChatData = (applicant) => {
  setUpdatedJobDetailInfo({...jobDetailsInfo, hirePerson : applicant});
  // setChatShow(true);
};

  useEffect(() => {
    if (jobDetailsInfo?.id) {
      console.log("jobDetailsInfo::", jobDetailsInfo?.id, jobDetailsInfo?.jobStatus)
      if (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && (role_type?.includes("ROLE_USER") || role_type?.includes("ROLE_OPENSTAFF_ADMIN"))) {
           getClockinClockout();
      }
    }
  }, [jobDetailsInfo]);

  // useEffect(() => {
  //   if (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && (role_type?.includes("ROLE_INSTITUTION_ADMIN") && hiredCandidatesList?.length > 0)) {
  //      getClockinClockout(hiredCandidatesList[0]?.id);
  //   }
  // }, [hiredCandidatesList])

  // const ClockInClockOutDetails = () => {
  //   return (
  //    <>
  //     {role_type?.includes("ROLE_USER") && (
  //       jobDetailsInfo?.multipleDayJob ? (
  //         <div className="row m-b10 mb-5">
  //           <div className="col-md-6 col-12">
  //             <b>Work Hours: </b> {jobDetailsInfo?.totalHours}
  //           </div>
  //           <div className="col-md-6 col-12">
  //             <b>Logged Hours: </b> {jobDetailsInfo?.totalWorkedHours ? jobDetailsInfo?.totalWorkedHours : "0"}
  //           </div>
  //         </div>
  //         ) : (
  //         <div className="row m-b10 mb-5">
  //           <div className="col-md-6 col-12">
  //             <b>Total Hours: </b>
  //             {jobDetailsInfo?.totalHours ? ConvertInto_Hrs(jobDetailsInfo?.totalHours) : "0"}
  //           </div>
  //           <div className="col-md-6 col-12">
  //             <b>Logged Hours: </b> {jobDetailsInfo?.totalWorkedHours}
  //           </div>

  //         </div>
  //         )
  //       )}

  //       {(role_type.includes("ROLE_OPENSTAFF_ADMIN")) && (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && jobDetailsInfo?.jobStatus !== "eCompleted") &&
  //       <div className="product-filter-wrap d-flex justify-content-between align-items-center">
  //         <h4 className="twm-s-title">Clock In/ Clock Out:</h4>
  //         <a
  //           className="site-button"
  //           data-bs-toggle="modal"
  //           href={"#clockin_clockout_popup"}
  //           role="button"
  //         >
  //           Add
  //         </a>
  //       </div>
  //       }
  //       {/* <p class="h4">Clock In/ Clock Out</p> */}

  //       {
  //       clockinClockoutLoader ? 
  //         <div className="mt-5"> 
  //           <Spinner size="lg" /> 
  //         </div>
  //       :
  //       clockinClockoutList?.length > 0 ? (
  //       <ClockInClockOutAdmin
  //         clockinClockoutLoader={clockinClockoutLoader}
  //         clockinClockoutList={clockinClockoutList}
  //         jobDetailsInfo={jobDetailsInfo}
  //         getJobDetailsData={getJobDetailsData}
  //         selectedClockinClockout={selectedClockinClockout}
  //         handleDeleteClick={handleDeleteClick}
  //         handleEditClick={handleEditClick}
  //         handleUpdateClockinClockout={handleUpdateClockinClockout}
  //       />
  //       ) : (
  //       <div className="tw-faq-section mt-5">
  //         <div className="accordion tw-faq" id="sf-faq-accordion-2">
  //           <h3 style={{ color: "red" }}>
  //             No Job History Found!
  //           </h3>
  //         </div>
  //       </div>
  //       )}
  //     </>
  //   )
  // }

  return (
    <>
      {
        (role_type.includes("ROLE_OPENSTAFF_ADMIN")) && (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && jobDetailsInfo?.jobStatus !== "eCompleted") &&
        <ClockinClockoutPopup
          jobDetailsInfo={jobDetailsInfo}
          lastClockinClockoutItem={lastClockinClockoutItem}
          isClockInLoading={isClockInLoading}
          isClockOutLoading={isClockOutLoading}
          handleClockInClockOut={handleClockInClockOut}
          errSuccessMesg={errSuccessMesg}
          clockinClockoutLoader={clockinClockoutLoader}
          selectedClockinClockout={selectedClockinClockout}
          handleUpdateClockinClockout={handleUpdateClockinClockout}
        />
      }
      {/* {
      (role_type.includes("ROLE_OPENSTAFF_ADMIN")) && (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && jobDetailsInfo?.jobStatus !== "eCompleted")  &&
      <div class="container mb-5">
      <div class="row justify-content-center align-items-center">
        <div class="col-md-auto">
          <p class="h4">{moment().format("dddd, MMMM D, YYYY")}</p>
        </div>
      </div>
      <div class="row justify-content-center align-items-center mt-2">
        <div class="col-md-auto text-center">
          <span class="text-muted">Clock-in</span>
          <span class="text-muted d-block" style={{ fontSize: 10 }}>
            {lastClockinClockoutItem?.checkinTime
              ? moment.utc(lastClockinClockoutItem?.checkinTime, "YYYY-MM-DD HH:mm:ss").local().format(
                  "MM-DD-YYYY"
                )
              : ""}
          </span>
          <p class="text-muted h4">
            {lastClockinClockoutItem?.checkinTime
              ? moment.utc(lastClockinClockoutItem?.checkinTime, "YYYY-MM-DD HH:mm:ss").local().format("hh:mm A")
              : "-"}
          </p>
        </div>
        <div class="col-md-auto text-center ml-5">
          {lastClockinClockoutItem?.checkinTime ? (
            <i
              class="fa fa-check-square"
              style={{
                color: "green",
                padding: "0px 30px",
                fontSize: "30px",
              }}
              aria-hidden="true"
            ></i>
          ) : (
            <button
              type="button"
              className="site-button"
              onClick={() => handleClockInClockOut("clockin")}
            >
              {isClockInLoading ? <Spinner /> : "Clock In"}
            </button>
          )}
        </div>
      </div>
      <div class="row justify-content-center align-items-center mt-3">
        <div class="col-md-auto text-center">
          <span class="text-muted">Clock-out</span>
          <p class="text-muted h4">-</p>
        </div>
        <div class="col-md-auto text-center ml-5">
          <button
            type="button"
            className="site-button"
            onClick={() => handleClockInClockOut("clockout")}
            disabled={lastClockinClockoutItem?.checkinTime ? false : true}
          >
            {isClockOutLoading ? <Spinner /> : "Clock Out"}
          </button>
        </div>
      </div>
    </div>
    } */}

      {/* {!(hiredCandidatesLoader && role_type.includes("ROLE_USER")) ? (
        hiredCandidatesList?.length > 0 ? (
          hiredCandidatesList?.map((item, index) => (
            <div key={item?.id} className="tw-faq-section" style={{ maxWidth: "100%" }} 
            >
              <div className="accordion tw-faq" id={`sf-faq-accordion-${index}`}>
                <div className="accordion-item" >
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#hireApplicant-${index}`}
                    aria-expanded="false"
                    aria-controls={`hireApplicant-${index}`}
                    onClick={() => getClockinClockout(item?.id)}
                  >
                    {item.firstName} {item.lastName}
                  </button>
                  <div
                    id={`hireApplicant-${index}`}
                    className="accordion-collapse collapse"
                    data-bs-parent={`#sf-faq-accordion-${index}`}
                  >
                    <div className="accordion-body">
                      <ClockInClockOutDetails />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          "No results found."
        )
      ) : (
        <TableLoader />
      )} */}

{!(hiredCandidatesLoader && role_type.includes("ROLE_USER")) ? (
        hiredCandidatesList?.length > 0 ? (
          <div className="tw-faq-section" id="sf-faq-accordion">
            {hiredCandidatesList?.map((item, index) => (
              <div key={item?.id} className="accordion tw-faq" style={{ maxWidth: "100%" }}>
                <div className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#hireApplicant-${index}`}
                    aria-expanded="false"
                    aria-controls={`hireApplicant-${index}`}
                    onClick={() => getClockinClockout(item?.id)}
                  >
                    {item.firstName} {item.lastName}
                  </button>
                  <div
                    id={`hireApplicant-${index}`}
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div className="accordion-body">
                      {
                          hiredCandidatesList?.length > 1 &&
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 row">
                          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 twm-job-self-bottom">
                            {jobDetailsInfo?.multipleDayJob ? (
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <b>Work Hours: </b> {jobDetailsInfo?.totalHours}
                                </div>
                                <div className="col-md-6 col-12">
                                  <b>Logged Hours: </b> {jobDetailsInfo?.totalWorkedHours ? jobDetailsInfo?.totalWorkedHours : "0"}
                                </div>
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col-md-6 col-12">
                                  <b>Total Hours: </b>
                                  {jobDetailsInfo?.totalHours ? ConvertInto_Hrs(jobDetailsInfo?.totalHours) : "0"}
                                </div>
                                <div className="col-md-6 col-12">
                                  <b>Logged Hours: </b> {jobDetailsInfo?.totalWorkedHours}
                                </div>
                                {/* } */}

                              </div>
                            )}
                          </div>
                          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 twm-job-self-bottom">
                            {jobDetailsInfo?.jobStatus == "eInProgress" && (
                              <span style={{ marginRight: "5px" }}><a
                              className="site-button"
                              href={"#job-review-popup"}
                              data-bs-toggle="modal"
                              role="button"
                              onClick={() => handleApproveAndFinish(item)} // Handle the click event
                            >
                              Approve and Finish
                            </a></span>
                            )}
                            {(jobDetailsInfo?.jobStatus == "eAccepted" || jobDetailsInfo?.jobStatus == "eInProgress"|| jobDetailsInfo?.jobStatus=="eApplied") && (<span><a
                              key={item.id}
                              className="site-button"
                              data-bs-toggle="modal"
                              role="button"
                              href={`#chatModal`}
                              onClick={() => handleAppliedChatData(item)}
                            >
                              <i className="far fa-comment fa-lg" />
                            </a></span>)}
                          </div>               
                        </div>
                       } 

                       {role_type?.includes("ROLE_USER") && (
                        jobDetailsInfo?.multipleDayJob ? (
                          <div className="row m-b10 mb-5">
                            <div className="col-md-6 col-12">
                              <b>Work Hours: </b> {jobDetailsInfo?.totalHours}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Logged Hours: </b> {jobDetailsInfo?.totalWorkedHours ? jobDetailsInfo?.totalWorkedHours : "0"}
                            </div>
                          </div>
                          ) : (
                          <div className="row m-b10 mb-5">
                            <div className="col-md-6 col-12">
                              <b>Total Hours: </b>
                              {jobDetailsInfo?.totalHours ? ConvertInto_Hrs(jobDetailsInfo?.totalHours) : "0"}
                            </div>
                            <div className="col-md-6 col-12">
                              <b>Logged Hours: </b> {jobDetailsInfo?.totalWorkedHours}
                            </div>

                          </div>
                          )
                        )}

                        {(role_type.includes("ROLE_OPENSTAFF_ADMIN")) && (jobDetailsInfo?.jobStatus !== "eOpen" && jobDetailsInfo?.jobStatus !== "eCancelled" && jobDetailsInfo?.jobStatus !== "eCompleted") &&
                        <div className="product-filter-wrap d-flex justify-content-between align-items-center">
                          <h4 className="twm-s-title">Clock In/ Clock Out:</h4>
                          <a
                            className="site-button"
                            data-bs-toggle="modal"
                            href={"#clockin_clockout_popup"}
                            role="button"
                          >
                            Add
                          </a>
                        </div>
                        }
                        {/* <p class="h4">Clock In/ Clock Out</p> */}

                        {
                        clockinClockoutLoader ? 
                          <div className="mt-5"> 
                            <Spinner size="lg" /> 
                          </div>
                        :
                        clockinClockoutList?.length > 0 ? (
                        <ClockInClockOutAdmin
                          clockinClockoutLoader={clockinClockoutLoader}
                          clockinClockoutList={clockinClockoutList}
                          jobDetailsInfo={jobDetailsInfo}
                          getJobDetailsData={getJobDetailsData}
                          selectedClockinClockout={selectedClockinClockout}
                          handleDeleteClick={handleDeleteClick}
                          handleEditClick={handleEditClick}
                          handleUpdateClockinClockout={handleUpdateClockinClockout}
                        />
                        ) : (
                        <div className="tw-faq-section mt-5">
                          <div className="accordion tw-faq" id="sf-faq-accordion-2">
                            <h3 style={{ color: "red" }}>
                              No Job History Found!
                            </h3>
                          </div>
                        </div>
                        )}
                      {/* <ClockInClockOutDetails /> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )
      ) : (
        <TableLoader />
      )}


    {/* {
      role_type.includes("ROLE_USER") &&  <ClockInClockOutDetails />
    } */}
     

     <ChatJobPopup jobDetailsInfo={updatedJobDetailInfo}/>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
      {sessionExpired && <SessionExpired />}
    </>
  );
};

export default ClockInClockOut;
