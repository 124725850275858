import JobZImage from "../../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../../globals/constants";
import GetProfileImage from "../../../../../common/getProfileImage";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import apiCall from "../../../../../../apicall";
import Toast from "../../../../../common/toast";

function SectionCandidateShortIntro1({ applicantDetailInfo }) {
  console.log("applicantDetailInfo", applicantDetailInfo, applicantDetailInfo?.accountStatus);
  const { TOKEN, role_type } = useSelector((state)=>state.menu)
  const [userAccountStatus, setUserAccountStatus] = useState(null)
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  useEffect(()=>{
    if (applicantDetailInfo?.accountStatus) {
      const doubleQuoteRegex = /^\\\"(.*)\\\"$/;  
      const singleQuoteRegex = /^\\\'(.*)\\\'$/; 
      let accountStatus = '';

      if (doubleQuoteRegex.test(applicantDetailInfo?.accountStatus)) {
        accountStatus = applicantDetailInfo?.accountStatus.replace(doubleQuoteRegex, '$1');
      } else if (singleQuoteRegex.test(applicantDetailInfo?.accountStatus)) {
        accountStatus = applicantDetailInfo?.accountStatus.replace(singleQuoteRegex, '$1');
      } else {
        accountStatus = applicantDetailInfo?.accountStatus;
      }
      console.log("accoujnt status",accountStatus)
      // console.log("Processed Payment Method:", accountStatus?.replace(/^"(.*)"$/, '$1'));
      setUserAccountStatus(accountStatus?.replace(/^"(.*)"$/, '$1'));
    }
  },[applicantDetailInfo])

  const onUserAccountStatusChange = async (input) => {
    // setFormValues({
    //   ...formValues,
    //   stripeMethodId: e.target.checked,
    // });
    //  let methodType=e.target.checked?'Stripe':'Non-Stripe'

    // let selectedPaymentMethod;

    // if (typeof input === 'object' && input.target) {
    //   selectedPaymentMethod = input.target.value;
    // } else {
    //   selectedPaymentMethod = input;
    // }

    console.log("input tage value",input.target.value)


      try {
          const api = `${(role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_SEEKER_USER_ADMIN"))
              && `openstaff/${applicantDetailInfo?.userEntityId}/updateStatus`
            }`;
          const userAccountStatusResponse = await apiCall(api, "PUT", input.target.value, TOKEN, '', '', true);

          console.log("userAccountStatusResponse",userAccountStatusResponse)
          if (userAccountStatusResponse?.status === 200) {
            setErrSuccessMsg({
              message: "User status changed successfully!",
              status: true,
            });

          } else if (userAccountStatusResponse?.response?.status == 401) {
            setErrSuccessMsg({
              message: "Your session has been expired!!",
              status: false,
            });
          } else {
            setErrSuccessMsg({
              message: userAccountStatusResponse?.response?.data?.message,
              status: false,
            });
          }
      } catch (err) {
        console.log("errrr----",err)
        setErrSuccessMsg({
          message: "Network Error! Try again later.",
          status: false,
        });
      }

  };

  return (
    <>
      <div
        className="twm-candi-self-wrap overlay-wraper"
        style={{
          backgroundImage: `url(
            "https://thewebmax.org/react/jobzilla/assets/images/candidates/candidate-bg.jpg"
          )`,
        }}
      >
        <div className="overlay-main site-bg-primary opacity-01" />
        <div className="twm-candi-self-info">
          <div className="twm-candi-self-top">
            <div className="twm-candi-fee">
              {
                userAccountStatus ? 
                <select
                    className="wt-select-bar-large selectpicker"
                    data-live-search="true"
                    data-bv-field="size"
                    value={userAccountStatus}
                    onChange={onUserAccountStatusChange}
                  >
                    <option value="ACTIVE">Acitve</option>
                    <option value="INACTIVE">Inactive</option>
                  </select>
                  : 
                  applicantDetailInfo?.accountStatus
              }
              {/* {applicantDetailInfo?.accountStatus} */}
              {console.log("userAccountStatus",userAccountStatus,applicantDetailInfo?.accountStatus?.toLowerCase())}
              
            </div>
            <div className="twm-media">
              {/* <img src="images/candidates/pic2.jpg" alt="#" /> */}
              <div style={{backgroundColor: 'white', padding: '5px'}}>
              <GetProfileImage
                id={applicantDetailInfo?.userEntityId}
                type={"userAvatar"}
                alt="#"
              />
              </div>
            </div>
            <div className="twm-mid-content">
              <h4 className="twm-job-title">{applicantDetailInfo?.firstName} {applicantDetailInfo?.lastName}</h4>
              <p>{applicantDetailInfo?.profession}</p>
              <p className="twm-candidate-address">
                <i className="feather-map-pin" />
                {`${applicantDetailInfo?.address}, ${applicantDetailInfo?.city}, ${applicantDetailInfo?.state}, ${applicantDetailInfo?.zipCode}`}
              </p>
            </div>
          </div>
          {/* <div className="twm-candi-self-bottom">
            <a href="#" className="site-button outline-white">
              Hire Me Now
            </a>
            <a href="#" className="site-button secondry">
              Download CV
            </a>
          </div> */}
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}

    </>
  );
}

export default SectionCandidateShortIntro1;
