import { useState, useEffect } from "react";
import SectionJobsSidebar1 from "../../sections/jobs/sidebar/section-jobs-sidebar1";
import SectionJobsGrid from "../../sections/jobs/section-jobs-grid";
import SectionRecordsFilter from "../../sections/common/section-records-filter";
import { loadScript } from "../../../../../globals/constants";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import SessionExpired from "../../../../common/sessionExpired";
import Toast from "../../../../common/toast";
import { useLocation } from "react-router-dom";
import JobViewTabs from "./job-view-tabs";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";
import moment from "moment";
import ExportCSV from "./export-csv";

function JobsGridPage() {
  const { role_type , TOKEN, isAddressSelected, selectedCategoryType } =
    useSelector((state) => state.menu);
  const location = useLocation();
  console.log("isAddressSelecte--------------d", location);

  const [isLoading, setIsLoading] = useState(false);
  const [jobListData, setJobListData] = useState([]);
  const [sliderValue, setSliderValue] = useState(() => {
    const savedValue = localStorage.getItem("sliderValue");
    return savedValue !== null ? JSON.parse(savedValue) : 100;
  });
  const [seekerTotalAmount, setSeekerTotalAmount] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [tableFilter, setTableFilter] = useState({
    status: "All",
    applicant: "",
    customer: "",
    fromDate: "",
    toDate: "",
    sortType: "recentJobs",
    sortDirection: "desc",
    searchKey: "",
    jobType: "",
    applicant: "",
    customer: "",
    locationName: isAddressSelected?.locationName
      ? isAddressSelected?.locationName
      : "",
    lat: isAddressSelected?.latlng ? isAddressSelected?.latlng?.lat : "",
    lng: isAddressSelected?.latlng ? isAddressSelected?.latlng?.lng : "",
    // jobStatus:
    //   role_type === "ROLE_OPENSTAFF_ADMIN"
    //     ? "All"
    //     : role_type === "ROLE_USER" || role_type === "ROLE_INSTITUTION_ADMIN"
    //     ? "eOpen"
    //     : "All",
    jobStatus: location?.state
      ? location.state
      : (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN"))
      ? "All"
      : role_type.includes("ROLE_USER") || role_type.includes("ROLE_INSTITUTION_ADMIN")
      ? "eOpen"
      : "All",
    categoryType: selectedCategoryType ? selectedCategoryType : "All",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [paginationData, setPaginationData] = useState({
    totalElements: 0,
    totalPages: 0,
  });
  const [sessionExpired, setSessionExpired] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });
  const _filterConfig = {
    prefix: "Showing",
    type: "results",
    isSorting: true,
    total: paginationData?.totalElements,
    showRange: false,
    showingUpto: "",
  };

  const handleRecordsPerPageChange = (event) => {
    setCurrentPage(1);
    setRowPerPage(event.target.value);
  };

  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setJobListData([]);
    setPaginationData({
      totalElements: 0,
      totalPages: 0,
    });
  };

  const fetchjobList = async () => {
    let data = null;
    console.log("fetchjobList-----", role_type, tableFilter?.jobStatus);
    // const api = `${
    //   role_type === "ROLE_OPENSTAFF_ADMIN"
    //     ? `openstaff/jobs/AllJobs?status=${tableFilter?.jobStatus}&applicant=${tableFilter?.applicant}&customer=${tableFilter?.customer}&fromDate=${tableFilter?.fromDate}&toDate=${tableFilter?.toDate}`
    //     : role_type === "ROLE_USER"
    //     ? tableFilter?.jobStatus === "eOpen"
    //       ? `jobs/open?jobStatus=${tableFilter?.jobStatus}`
    //       : tableFilter?.jobStatus === "eApplied"
    //       ? `jobs/applied2?jobEntity.jobStatus=eOpen`
    //       : tableFilter?.jobStatus === "eAccepted"
    //       ? `jobs/applied2?jobEntity.jobStatus=eAccepted&hired=true&jobEntity.jobStatus=eInProgress`
    //       : tableFilter?.jobStatus === "eHistory"
    //       ? `jobs/applied?jobStatus=eCompleted&jobStatus=eClosed`
    //       : ""
    //     : role_type === "ROLE_INSTITUTION_ADMIN"
    //     ? tableFilter?.jobStatus === "eOpen"
    //       ? `institutions/jobs?jobStatus=${tableFilter?.jobStatus}`
    //       : tableFilter?.jobStatus === "eScheduled"
    //       ? `institutions/jobs?jobStatus=eInProgress&jobStatus=eAccepted`
    //       : tableFilter?.jobStatus === "eHistory"
    //       ? `institutions/jobs?jobStatus=eCompleted&jobStatus=eClosed`
    //       : ""
    //     : `config/jobs/open?latitude=${searchLocationLatLng?.latitude}&longitude=${searchLocationLatLng?.longitude}&distance=100`
    // }&category=${tableFilter?.categoryType}&sort=${tableFilter?.sortType},${
    //   tableFilter?.sortDirection
    // }&offset=0&page=${currentPage - 1}&size=${rowPerPage}&searchkey=${
    //   tableFilter?.searchKey
    // }`;

    const apiBase = `${
      (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN"))
        ? `openstaff/jobs/AllJobs?status=${tableFilter?.jobStatus}&applicant=${tableFilter?.applicant}&customer=${tableFilter?.customer}&fromDate=${tableFilter?.fromDate}&toDate=${tableFilter?.toDate}`
        : role_type.includes("ROLE_USER")
        ? tableFilter?.jobStatus === "eOpen"
          ? `jobs/open?jobStatus=${tableFilter?.jobStatus}`
          : tableFilter?.jobStatus === "eApplied"
          ? `jobs/applied2?jobEntity.jobStatus=eOpen`
          : tableFilter?.jobStatus === "eAccepted"
          ? `jobs/applied2?jobEntity.jobStatus=eAccepted&hired=true&jobEntity.jobStatus=eInProgress`
          : tableFilter?.jobStatus === "eHistory"
          ? `jobs/applied?jobStatus=eCompleted&jobStatus=eClosed`
          : tableFilter?.jobStatus === "onGoing"
          ? `jobs/applied2?jobEntity.jobStatus=eInProgress&jobEntity.jobStatus=eCancelled&hired=true`
          : tableFilter?.jobStatus === "myJobs"
          ? null // We will handle this case separately
          : ""
        : role_type.includes("ROLE_INSTITUTION_ADMIN")
        ? tableFilter?.jobStatus === "eOpen"
          ? `institutions/jobs?jobStatus=${tableFilter?.jobStatus}`
          : tableFilter?.jobStatus === "eScheduled"
          ? `institutions/jobs/scheduled`
          : tableFilter?.jobStatus === "eHistory"
          ? `institutions/jobs?jobStatus=eCompleted&jobStatus=eClosed`
          : ""
        : `config/jobs/open`
    }`;

    const commonParams =
      (tableFilter?.jobStatus === "myJobs" && role_type.includes("ROLE_USER")) ||
      (tableFilter?.jobStatus === "onGoing" && role_type.includes("ROLE_USER")) ||
      (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN"))
        ? `&latitude=${tableFilter?.lat}&longitude=${
            tableFilter?.lng
          }&category=${tableFilter?.categoryType}&sortBy=${
            tableFilter?.sortType
          }&sortDirection=${tableFilter?.sortDirection}&offset=0&page=${
            currentPage - 1
          }&size=${rowPerPage}&searchkey=${tableFilter?.searchKey}`
        : `${(role_type?.length === 0 || (tableFilter?.jobStatus === "eScheduled" && role_type.includes("ROLE_INSTITUTION_ADMIN"))) ? "?" : "&"}latitude=${
            tableFilter?.lat
          }&longitude=${tableFilter?.lng}&category=${
            tableFilter?.categoryType
          }&sortBy=${tableFilter?.sortType}&sortDirection=${
            tableFilter?.sortDirection
          }&offset=0&page=${currentPage - 1}&size=${rowPerPage}&searchKey=${
            tableFilter?.searchKey
          }&distance=${role_type?.length === 0 ? "10000" : sliderValue}`;

    const fetchJobs = async (api) => {
      return apiCall(api + commonParams, "GET", data, TOKEN);
    };

    try {
      setIsLoading(true);
      let result;

      if (tableFilter?.jobStatus === "myJobs") {
        const api1 = `jobs/applied2?jobEntity.jobStatus=eOpen`;
        const api2 = `jobs/applied2?jobEntity.jobStatus=eAccepted&hired=true`;
        const [result1, result2] = await Promise.all([
          fetchJobs(api1),
          fetchJobs(api2),
        ]);

        result = {
          data: {
            content: [...result1.data.content, ...result2.data.content],
            totalElements:
              result1.data.totalElements + result2.data.totalElements,
            totalPages: Math.max(
              result1.data.totalPages,
              result2.data.totalPages
            ),
          },
        };
      } else {
        result = await fetchJobs(apiBase);
      }
      // const result = await apiCall(api, "GET", data, TOKEN);
      if (result?.data?.content?.length > 0) {
        setIsLoading(false);
        setSessionExpired(false);
        setJobListData(result.data.content);
        setPaginationData({
          totalElements: result.data.totalElements,
          totalPages: result.data.totalPages,
        });
      } else if (result?.data?.content?.length === 0) {
        setSessionExpired(false);
        // displayErrMsg("No results found.!");
        setIsLoading(false);
        setJobListData([]);
        setPaginationData({
          totalElements: 0,
          totalPages: 0,
        });
      } else if (result?.response?.status == 401) {
        setSessionExpired(true);
        displayErrMsg("Your session has been expired!");
      }else if(result?.response?.data?.message?.includes("fromIndex")){
        setSessionExpired(false);
        setIsLoading(false);
      }  else {
        setSessionExpired(false);
        // displayErrMsg("Something went wrong!");
        displayErrMsg(result?.response?.data?.message)
      }
      console.log("user list result", result);
    } catch (error) {
      setSessionExpired(false);
      displayErrMsg("Something went wrong!");
      console.error("Error fetching user data:", error);
    }
  };

  const getApplicantList = async () => {
    const applicantResponse = await apiCall(
      "users?offset=&pageNumber=&size=500",
      "GET",
      null,
      TOKEN
    );
    console.log("getApplicantList---", applicantResponse);
    if (applicantResponse?.data?.content?.length > 0) {
      setApplicantList(applicantResponse.data.content);
    }
  };
  const getTotalAmount = async () => {
    let totalAmount = 0
    if(role_type.includes('ROLE_USER') || role_type.includes("ROLE_OPENSTAFF_ADMIN")  || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN")){
      totalAmount = await apiCall("totalAmount", "GET", null, TOKEN);
    }else if(role_type.includes("ROLE_INSTITUTION_ADMIN")){
      totalAmount = await apiCall("posterTotalAmount", "GET", null, TOKEN);
    }
    console.log("getTotalAmount---", totalAmount);
    if (totalAmount?.data > 0) {
      setSeekerTotalAmount(totalAmount.data);
    }else{
      setSeekerTotalAmount(0);
    }
  };
  const getCustomersList = async () => {
    const customerResponse = await apiCall(
      "openstaff/institutions?offset=&pageNumber=&size=500",
      "GET",
      null,
      TOKEN
    );
    if (customerResponse?.data?.content?.length > 0) {
      setCustomerList(customerResponse.data.content);
    }
  };

  useEffect(() => {
    loadScript("js/custom.js");
  });

  useEffect(() => {
    if (role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_INSTITUTION_POSTER_ADMIN")) {
      getApplicantList();
      getCustomersList();
    }
  }, []);
  useEffect(() => {
    if ((role_type.includes('ROLE_INSTITUTION_ADMIN')  || role_type.includes("ROLE_OPENSTAFF_ADMIN") || role_type.includes("ROLE_OPENSTAFF_FINANCIAL_ADMIN") || role_type.includes("ROLE_USER")) && (location?.pathname?.includes("/job-grid") || location?.pathname?.includes("/job-grid-with-map"))) {
      getTotalAmount();
    }
  }, []);

  useEffect(() => {
    fetchjobList();
  }, [currentPage, tableFilter, rowPerPage]);

  useEffect(() => {
    const handleStorageChange = () => {
      const savedValue = localStorage.getItem("sliderValue");
      if (savedValue !== null) {
        setSliderValue(JSON.parse(savedValue));
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    logEvent_Analytics('job_grid_web');
}, [location?.pathname]);

  return (
    <>
      <div className="section-full p-t120  p-b90 site-bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 rightSidebar">
              <SectionJobsSidebar1
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
                applicantList={applicantList}
                customerList={customerList}
              />
            </div>
            <div className="col-lg-8 col-md-12">
              {/*Filter Short By*/}
              <div class="col-lg-12 col-md-12 col-sm-12 panel-heading wt-panel-heading p-a20 d-flex">
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <h3 class="wt-title mt-4">Jobs</h3>
                </div> 
                <ExportCSV jobListData={jobListData} />
              </div>
              {/* {
                !role_type.includes('ROLE_OPENSTAFF_ADMIN') && 
                !role_type.includes('ROLE_OPENSTAFF_FINANCIAL_ADMIN') &&
                !role_type.includes('ROLE_INSTITUTION_POSTER_ADMIN') && */}
                <JobViewTabs />
              {/* } */}
              <SectionRecordsFilter
                _config={_filterConfig}
                rowPerPage={rowPerPage}
                handleRecordsPerPageChange={handleRecordsPerPageChange}
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
                currentPage={currentPage}
              />
              <SectionJobsGrid
                isLoading={isLoading}
                jobListData={jobListData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                fetchjobList={fetchjobList}
                seekerTotalAmount={seekerTotalAmount}
                totalPages={paginationData.totalPages}
              />
            </div>
          </div>
        </div>
      </div>
      {sessionExpired && <SessionExpired />}
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </>
  );
}

export default JobsGridPage;
