import React, { useRef, useState, useEffect } from "react";
import Toast from "../../../../common/toast";
import Spinner from "../../../../common/spinner";
import apiCall from "../../../../../apicall";
import { useSelector } from "react-redux";
import { logEvent_Analytics } from "../../../../common/firebase/firebase-analytics-service";

const DeleteClockinOutConfirmation = ({ selectedClockinClockout, getJobDetailsData }) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [isClockinoutDeleted, setIsClockinoutDeleted] = useState(false);
  const [errSuccessMesg, setErrSuccessMsg] = useState({
    message: "",
    status: "error",
  });

  console.log("selectedClockinClockout",selectedClockinClockout)
  const modalRef = useRef(null);
  const displayErrMsg = (errMsg) => {
    setErrSuccessMsg({
      message: errMsg,
      status: false,
    });
    setIsLoading(false);
    setIsClockinoutDeleted(false);
  };

  const handleClockinoutDelete = async () => {
    try {
      setIsLoading(true);
      const payload = {
        clockInId : selectedClockinClockout?.checkinData?.id,
        clockOutId: selectedClockinClockout?.checkoutData?.id
      }

      console.log("payload=======",payload)
      const deleteClockinoutResponse = await apiCall(
        `openstaff/jobs/deleteClockInClockout`,
        "PUT",
        payload,
        TOKEN
      );
      console.log("deleteClockinoutResponse----", deleteClockinoutResponse);
      if (deleteClockinoutResponse?.status === 200) {
        setIsLoading(false);
        setIsClockinoutDeleted(true);
        setErrSuccessMsg({
          message: "Clockin clockout deleted successfully.",
          status: true,
        });
        getJobDetailsData();
        setTimeout(() => {
          if (modalRef.current) {
            modalRef.current.setAttribute("data-bs-toggle", "modal");
            modalRef.current.click();
          }
        }, 1200);
      } else if (deleteClockinoutResponse?.response?.status == 401) {
        displayErrMsg("Your session has been expired!");
      } else {
        // displayErrMsg("Something went wrong!");
        displayErrMsg(deleteClockinoutResponse?.response?.data?.message);
      }
    } catch (err) {
      console.log("setSessionExpired--", err);
      displayErrMsg("Network Error!");
    }
  };

  useEffect(() =>{
    logEvent_Analytics('delete_clockin_clockout_screen_web');
  },[])

  return (
    <div
      className="modal fade twm-sign-up"
      id="delete_clockinout_popup"
      aria-hidden="true"
      aria-labelledby="sign_up_popupLabel2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h2 className="modal-title" id="sign_up_popupLabel2">
              Are you sure you want to delete clockin-clockout?
            </h2>
            <br />
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={modalRef}
              onClick={() => setIsClockinoutDeleted(false)}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              id="deleteConfirmationModal"
              className="btn btn-primary"
              onClick={handleClockinoutDelete}
            >
              {isLoading ? <Spinner /> : "Delete"}
            </button>
          </div>
        </div>
      </div>
      {errSuccessMesg?.message != "" ? <Toast message={errSuccessMesg} /> : ""}
    </div>
  );
};

export default DeleteClockinOutConfirmation;
