import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SectionApplicantsList from "../applicant-list";
import apiCall from "../../../../../../apicall";
import SectionRecordsFilter from "../../../../public-user/sections/common/section-records-filter";
import { useLocation } from "react-router-dom";

const TrustedWorkersList = ({
    fetchTrustedWorkersList,
    fetchTrustedCompanyList,
    trustedWorker
}) => {
    const { role_type, TOKEN } = useSelector((state) => state.menu);
    const [tableFilter, setTableFilter] = useState({
        sortType: "createdDate",
        sortDirection: "desc",
        searchKey: "",
    });
    const location = useLocation();
    const currentUrl = location.pathname;
    const [sessionExpired, setSessionExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errSuccessMesg, setErrSuccessMsg] = useState({
        message: "",
        status: "error",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(10);
    const [searchKey, setSearchKey] = useState("");
    const [paginationData, setPaginationData] = useState({
        totalElements: 0,
        totalPages: 0,
    });
    const [hiredJobSeekerList, setHiredJobSeekerList] = useState([]);

    const handleRecordsPerPageChange = (event) => {
        setCurrentPage(1);
        setRowPerPage(event.target.value);
    };
    const _filterConfig = {
        prefix: "Showing",
        type: "results",
        isSorting: true,
        total: paginationData?.totalElements,
        showRange: false,
        showingUpto: "",
        showEmployeeNameFilter: true,
    };
    const displayErrMsg = (errMsg) => {
        setErrSuccessMsg({
            message: errMsg,
            status: false,
        });
        setIsLoading(false);
        setHiredJobSeekerList([]);
        setPaginationData({
            totalElements: 0,
            totalPages: 0,
        });
    };

    const handleSearchClick = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            setTableFilter({
                ...tableFilter,
                searchKey: searchKey,
            });
            setCurrentPage(1);
        }
    };

    const clearSearch = () => {
        setSearchKey("");
        setCurrentPage(1);
        setTableFilter({
            ...tableFilter,
            searchKey: "",
        });
    };

    const handleSearchKeyChange = (e) => {
        if (e?.target?.value.trim() === "") {
            clearSearch();
        } else {
            setSearchKey(e.target.value);
        }
    };

    const fetchHiredJobSeeker = async () => {
        try {
            let data = null;
            console.log("fetchempList-----", role_type, tableFilter?.searchKey);

            const apiEndpoint = trustedWorker === "pastHires"
                ? "institutions/trustedSeekers" : trustedWorker === "allWorkers"
                    ? "institutions/allSeekers" : currentUrl === "/manage-team-trusted-companies" ? "institutions/allCompanies" : "";

            const api = `${apiEndpoint}?sortBy=${tableFilter?.sortType
                }&sortDirection=${tableFilter?.sortDirection}&offset=0&page=${currentPage - 1
                }&size=${rowPerPage}&searchKey=${tableFilter?.searchKey}`;

            setIsLoading(true);
            const result = await apiCall(api, "GET", data, TOKEN);

            console.log("result hire person-----------", result);
            if (result?.data?.content?.length > 0) {
                setSessionExpired(false);
                setHiredJobSeekerList(result.data.content);
                setPaginationData({
                    totalElements: result.data.totalElements,
                    totalPages: result.data.totalPages,
                });
            } else if (result?.data?.content?.length === 0 && result?.status == 200) {
                setSessionExpired(false);
                // displayErrMsg("No results found.!");
                setHiredJobSeekerList([]);
                setPaginationData({
                    totalElements: 0,
                    totalPages: 0,
                });
            } else if (result?.response?.status == 401) {
                setSessionExpired(true);
                displayErrMsg("Your session has been expired!");
            } else {
                setSessionExpired(false);
                // displayErrMsg("Something went wrong!");
                displayErrMsg(result?.response?.data?.message);
            }
            console.log("user list result", result);
        } catch (error) {
            setSessionExpired(false);
            displayErrMsg("Something went wrong!");
            console.error("Error fetching user data:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (trustedWorker == "pastHires" || trustedWorker == "allWorkers" || currentUrl === "/manage-team-trusted-companies") {
            fetchHiredJobSeeker();
        }
    }, [trustedWorker, currentPage, tableFilter, rowPerPage]);

    return (
        <>
            <div className="input-group mb-3">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search By Name"
                    id="paymemploent-searchkey"
                    value={searchKey}
                    onChange={(e) => handleSearchKeyChange(e)}
                    onKeyDown={handleSearchClick}
                />
                {searchKey && (
                    <button
                        type="button"
                        className="btn"
                        onClick={clearSearch}
                        style={{
                            position: "absolute",
                            right: "40px",
                            top: 8,
                            zIndex: 2,
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                        }}
                    >
                        <i className="fas fa-times" />
                    </button>
                )}
                <button
                    type="button"
                    className="btn"
                    style={{
                        position: "absolute",
                        right: "10px",
                        top: 8,
                        zIndex: 2,
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                    }}
                >
                    <i className="feather-search" onClick={handleSearchClick} />
                </button>
            </div>
            <SectionRecordsFilter
                _config={_filterConfig}
                rowPerPage={rowPerPage}
                handleRecordsPerPageChange={handleRecordsPerPageChange}
                setTableFilter={setTableFilter}
                tableFilter={tableFilter}
                setCurrentPage={setCurrentPage}
            />
            <SectionApplicantsList
                isLoading={isLoading}
                applicantsListData={hiredJobSeekerList}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={paginationData.totalPages}
                isHiredSeekerList={currentUrl != "/manage-team-trusted-companies"}
                fetchTrustedWorkersList={fetchTrustedWorkersList}
                fetchHiredJobSeeker={fetchHiredJobSeeker}
                trustedWorker={trustedWorker}
                fetchTrustedCompanyList={fetchTrustedCompanyList}
            />
        </>
    );
};

export default TrustedWorkersList;
